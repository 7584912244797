import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import { DATE_FORMAT } from '../../../utils/constants';

import { INotificationsTableRowComponent } from './types';

const NotificationsTableRowComponent = ({
  item,
}: INotificationsTableRowComponent) => {
  const formattedCreatedAt = moment(new Date(String(item?.sendAt))).format(
    DATE_FORMAT,
  );

  return (
    <TableRow>
      <TableCell align='center'>{formattedCreatedAt}</TableCell>
      <TableCell align='center'>{item.topic}</TableCell>
      <TableCell align='center'>{item.text}</TableCell>
    </TableRow>
  );
};

export default NotificationsTableRowComponent;
