import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  filtersItemSelect: {
    width: 'auto',
    '& .MuiInput-formControl': {
      border: 'none',

      [theme.breakpoints.down('xs')]: {
        '& .MuiInput-input': {
          paddingLeft: 0,
        },
      },
    },
  },
  filtersSelectLimitWrapper: { marginLeft: '24px' },
  carrierCodeIcon: {
    width: 20,
    maxHeight: 16,
    marginRight: 10,
  },
  carrierCodeSelectedItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));
