import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

const styles = () =>
  makeStyles((theme) => ({
    userSettingsSelect: {
      border: 'none',
      marginRight: '14px',

      '& .MuiSelect-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
        boxSizing: 'border-box',
        color: '#606060',
        fontSize: '18px',
        lineHeight: '100%',
        fontWeight: '300',
      },
      '& .MuiSvgIcon-root': {
        width: '24px',
        height: '24px',
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
    },
    person: {
      display: 'flex',
      alignItems: 'center',
      color: '#606060',
      fontWeight: 300,
      fontSize: '18px',
      lineHeight: '100%',
      textDecoration: 'none',
      marginRight: '5px',
      '&:hover': {
        textDecoration: 'underline',
        '& $personIcon': {
          color: '#606060',
        },
      },
    },
    personIcon: {
      color: COLORS.BLACK,
      marginRight: '5px',
      padding: '0px',
      height: '30px',
    },
  }))();

export default styles;
