import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { IFilters } from '../../../utils/types';
import { filterSelectDepartureDateOptions } from './options';

interface IFilterSelectDepartureDate {
  value?: string;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectDepartureDate = ({
  value,
  setFilter,
}: IFilterSelectDepartureDate) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => {
          const dateValue = e.target.value || null;
          setFilter((state) => ({
            ...state,
            dateFilter: dateValue as string,
          }));
        }}
      >
        {filterSelectDepartureDateOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={!option.value}
          >
            {option.nDays
              ? t(option.title, { value: option.nDays })
              : t(option.title)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelectDepartureDate;
