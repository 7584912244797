import { gql } from '@apollo/client';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';
import { USER_PASPORT } from '../fragments/userPasportFragment';

export default gql`
  ${DELIVERY_COUNTRY}
  ${USER_PASPORT}
  query getCurrentUser {
    currentUser {
      id
      email
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      status
      createdAt
      updatedAt
      passportData {
        ...FragmentUserPasport
      }
      entityLegalData {
        id
        company
        inn
        kpp
        MSRN
        generalManager
        legalAddress
        nameOfTheBank
        BIK
        paymentAccount
        correspondentAccount
        fileName
        userId
        isDataVerified
      }
      role
      warehouse {
        id
        code
      }
      whFeePersonalDiscount
    }
  }
`;
