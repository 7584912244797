import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import {
  FastField,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  useFormikContext,
} from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  B2BOrderCargoPlace,
  B2BWrapTypeEnum,
} from '../../../../generated/graphql';
import AddIcon from '@material-ui/icons/Add';
import { IB2bShipmentsServiceCreateModalValues } from '../types';
import { COLORS } from '../../../../utils/constants';

const B2bShipmentCreateCargoPlacesModalStep2 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue, errors, setFieldError } =
    useFormikContext<IB2bShipmentsServiceCreateModalValues>();

  const { products, cargoPlaces, dimensions } = values;

  const totalPickedProducts =
    cargoPlaces?.reduce((sum, cargo) => {
      if (cargo?.productsItems && Array.isArray(cargo.productsItems)) {
        const productSum = cargo.productsItems.reduce((productSum, product) => {
          return productSum + (product?.quantity || 0);
        }, 0);
        return sum + productSum;
      }
      return sum;
    }, 0) || 0;

  const transformCargoPlacesToMatrix = useCallback(() => {
    const productIds = products?.map((p) => p?.productId);
    const matrix = productIds?.map(() =>
      new Array(cargoPlaces?.length).fill(0),
    ) as number[][];

    cargoPlaces?.forEach((place, colIndex) => {
      place?.productsItems?.forEach((item) => {
        const rowIndex = productIds?.indexOf(item?.productId) as number;
        if (rowIndex! >= 0) {
          matrix[rowIndex][colIndex] = Number(item?.quantity);
        }
      });
    });

    return matrix as number[][];
    //eslint-disable-next-line
  }, [totalPickedProducts, cargoPlaces?.length]);

  const matrix = useMemo(
    () => transformCargoPlacesToMatrix(),
    //eslint-disable-next-line
    [totalPickedProducts, cargoPlaces?.length],
  );

  const handleInputChange = (index: number, field: string, value: number) => {
    setFieldValue(`dimensions.${index}.${field}`, value);
  };

  const addDimensions = (arrayHelpers: FieldArrayRenderProps) => () => {
    arrayHelpers.push({ width: 0, height: 0, length: 0 });
  };

  const cargoPlacesTitleArray = Array.from(
    { length: Number(cargoPlaces?.length) },
    (_, index) => {
      return `${values.wrapType === B2BWrapTypeEnum.Boxes ? 'B' : 'P'}${
        index + 1
      }`;
    },
  );

  const getIsChecked = (
    cargoPlace: B2BOrderCargoPlace,
    w: number,
    l: number,
    h: number,
  ) => {
    if (!cargoPlace) return false;
    const { width, length, height } = cargoPlace;

    return width === w && height === h && length === l;
  };

  const getTotalQuantityByProductId = (productId: number): number => {
    return (
      cargoPlaces?.reduce((sum, cargo) => {
        if (cargo?.productsItems && Array.isArray(cargo.productsItems)) {
          const productSum = cargo.productsItems.reduce(
            (productSum, product) => {
              if (product?.productId === productId) {
                return productSum + (product?.quantity || 0);
              }
              return productSum;
            },
            0,
          );
          return sum + productSum;
        }
        return sum;
      }, 0) || 0
    );
  };

  const totalWeght = cargoPlaces?.reduce((acc, place) => {
    return acc + Number(place?.weight);
  }, 0);

  const totalAcceptedProducts = products?.reduce((acc, product) => {
    return acc + Number(product?.expectedQuantity);
  }, 0);

  const HEAD_CELLS = [
    t('app.product'),
    t('app.unitsPacked'),
    ...cargoPlacesTitleArray,
  ];

  return (
    <div>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {HEAD_CELLS.map((text) => (
                <TableCell key={text} align='center' rowSpan={2}>
                  {text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {products?.length &&
              matrix.map((row, productIndex) => (
                <TableRow key={productIndex}>
                  <TableCell align='center'>
                    <Typography>
                      {products[productIndex]?.relatedProduct?.name}
                    </Typography>
                    <Typography>
                      {`ID: ${
                        products[
                          productIndex
                        ]?.relatedProduct?.barcodeId?.split('.')[0]
                      }`}
                    </Typography>
                    <Typography>
                      {`SKU ${products[productIndex]?.relatedProduct?.sku}`}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Box
                      style={{
                        color: `${
                          products[productIndex]?.expectedQuantity !==
                          getTotalQuantityByProductId(
                            Number(products[productIndex]?.productId),
                          )
                            ? COLORS.RED
                            : COLORS.BLACK
                        }`,
                      }}
                    >{`${
                      products[productIndex]?.expectedQuantity
                    } / ${getTotalQuantityByProductId(
                      Number(products[productIndex]?.productId),
                    )}`}</Box>
                  </TableCell>
                  {row.map((quantity, placeIndex) => (
                    <TableCell key={placeIndex} align='center'>
                      <FastField
                        name={`cargoPlaces.${placeIndex}.productsItems.${productIndex}.quantity`}
                      >
                        {({ field: { value, ...field }, meta }: FieldProps) => (
                          <FormControl
                            style={{ width: '100px' }}
                            error={meta.touched && !!meta.error && value !== 0}
                          >
                            <Input
                              disableUnderline
                              id={`input-cargo-places.productQty`}
                              {...field}
                              inputProps={{
                                type: 'number',
                                min: 0,
                                step: 1,
                              }}
                              defaultValue={quantity}
                              onChange={(e) => {
                                setFieldValue(
                                  `cargoPlaces.${placeIndex}.productsItems.${productIndex}.productId`,
                                  products[productIndex]?.productId,
                                );

                                setFieldValue(
                                  `cargoPlaces.${placeIndex}.productsItems.${productIndex}.quantity`,
                                  Number(e.target.value),
                                );
                                setFieldValue(
                                  `cargoPlaces.${placeIndex}.productsItems.${productIndex}.cargoId`,
                                  cargoPlaces?.[placeIndex]?.id,
                                );
                                setFieldError('isIsMonoQuantityError', '');
                                setFieldError(
                                  'isTotalCargoPlaceQuantityError',
                                  '',
                                );
                              }}
                              value={quantity}
                            />
                            {meta.touched && !!meta.error && value !== 0 && (
                              <FormHelperText>{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </FastField>
                      {/* @ts-ignore */}
                      {errors?.cargoPlaces?.[placeIndex]?.productsItems && (
                        <FormHelperText style={{ color: COLORS.RED }}>
                          {/* @ts-ignore */}
                          {t(errors?.cargoPlaces?.[placeIndex]?.productsItems)}
                        </FormHelperText>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            <TableRow>
              <TableCell align='center'>
                <Typography variant='h6'>{`${t('app.numberOfSku')}: ${
                  products?.length
                }`}</Typography>
              </TableCell>
              <TableCell align='center'>
                <Box
                  style={{
                    color: `${
                      totalAcceptedProducts !== totalPickedProducts
                        ? COLORS.RED
                        : COLORS.BLACK
                    }`,
                  }}
                >{`${totalAcceptedProducts} / ${totalPickedProducts}`}</Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={HEAD_CELLS.length}>
                <Divider />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align='center'></TableCell>
              <TableCell align='center'>{t('app.weightKg')}</TableCell>
              {cargoPlacesTitleArray.map((_, index) => (
                <TableCell key={index} align='center'>
                  <FastField name={`cargoPlaces.${index}.weight`}>
                    {({ field: { value, ...field }, meta }: FieldProps) => (
                      <FormControl
                        style={{ width: '100px' }}
                        error={meta.touched && !!meta.error}
                      >
                        <Input
                          disableUnderline
                          id={`input-cargo-places-weight`}
                          {...field}
                          inputProps={{
                            type: 'number',
                            min: 1,
                            step: 1,
                          }}
                          defaultValue={value}
                          onChange={(e) => {
                            setFieldValue(
                              `cargoPlaces.${index}.weight`,
                              Number(e.target.value),
                            );
                          }}
                          value={value}
                        />
                        {meta.touched && !!meta.error && (
                          <FormHelperText>{t(meta.error)}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </FastField>
                </TableCell>
              ))}

              <TableCell align='center'>{`${totalWeght} ${t(
                'app.kg',
              )}`}</TableCell>
            </TableRow>
            <FieldArray name={'dimensions'}>
              {(arrayHelpers) => (
                <>
                  {dimensions.map((dimensions, index) => (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Box className={classes.inputsWrapper}>
                          <Typography>{t('app.dimensionsСm')}</Typography>
                          {Object.keys(dimensions).map((key) => (
                            <>
                              <FastField name={`dimensions.${index}.${key}`}>
                                {({
                                  field: { value, ...field },
                                  meta,
                                }: FieldProps) => (
                                  <FormControl
                                    style={{ width: '100px' }}
                                    error={meta.touched && !!meta.error}
                                  >
                                    <Input
                                      id={`input-dimensions-${key}-${index}`}
                                      {...field}
                                      defaultValue={0}
                                      value={value}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          key,
                                          Number(e.target.value),
                                        )
                                      }
                                      inputProps={{
                                        type: 'number',
                                        min: 1,
                                        step: 1,
                                      }}
                                    />

                                    {meta.touched && !!meta.error && (
                                      <FormHelperText>
                                        {t(meta.error)}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                )}
                              </FastField>
                              <span>X</span>
                            </>
                          ))}
                        </Box>
                      </TableCell>
                      {cargoPlacesTitleArray.map((title, index) => (
                        <TableCell key={title} align='center'>
                          <Checkbox
                            className={classes.radio}
                            checked={getIsChecked(
                              cargoPlaces?.[index] as B2BOrderCargoPlace,
                              Number(dimensions.width),
                              Number(dimensions.length),
                              Number(dimensions.height),
                            )}
                            onChange={() => {
                              setFieldValue(
                                `cargoPlaces.${index}.width`,
                                dimensions.width,
                              );
                              setFieldValue(
                                `cargoPlaces.${index}.length`,
                                dimensions.length,
                              );
                              setFieldValue(
                                `cargoPlaces.${index}.height`,
                                dimensions.height,
                              );
                              setFieldError('isDimensionsError', '');
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <Button
                    style={{ marginTop: '24px' }}
                    onClick={addDimensions(arrayHelpers)}
                    startIcon={<AddIcon />}
                  >
                    {t('app.addDimensions')}
                  </Button>
                </>
              )}
            </FieldArray>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default B2bShipmentCreateCargoPlacesModalStep2;
