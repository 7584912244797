import React from 'react';
import useStyles from './styles';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import PlaceIcon from '@material-ui/icons/Place';
import iconWarehouse from '../../../assets/img/icon_warehouse.png';
import { useTranslation } from 'react-i18next';
import { FastField, FieldProps, useFormikContext } from 'formik';
import {
  B2BOrder,
  B2BShipmentMethodTypeEnum,
} from '../../../generated/graphql';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { Link } from 'react-router-dom';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const B2bShipmentPackagingStep4 = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<B2BOrder>();

  const { shipmentInfo } = values;

  return (
    <div className={classes.stepContent}>
      <Box
        className={classes.itemInfo}
        display={'flex'}
        style={{ marginBottom: '24px' }}
      >
        <BoxCentered mr={2}>
          <img
            src={iconWarehouse}
            alt='barcode'
            style={{ width: 35, height: 35 }}
          />
        </BoxCentered>
        <Box>
          <Typography variant='h3'>{t('app.warehouse')}</Typography>
          <Box>{values?.warehouseName}</Box>
        </Box>{' '}
      </Box>
      <Box
        className={classes.itemInfo}
        display={'flex'}
        style={{ marginBottom: '24px' }}
      >
        <BoxCentered mr={2}>
          <PlaceIcon style={{ width: 35, height: 35 }} />
        </BoxCentered>
        <Box>
          <Typography variant='h3'>{t('app.b2bShipmentsPoint')}</Typography>
          <Box>{values?.shipmentPointTitle}</Box>
        </Box>
      </Box>
      <Box display='flex' className={classes.pickerWrapper}>
        <Box>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.date')}
          </Typography>
          <DatePicker
            className={classes.datePicker}
            id='start_date'
            value={values.shipmentInfo?.scheduledDate}
            onChange={(date) => {
              if (date) {
                setFieldValue('shipmentInfo.scheduledDate', date);
              }
            }}
            format='DD/MM/yyyy'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' className={classes.adornment}>
                  <CalendarTodayIcon className={classes.calendarIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.time')}
          </Typography>
          <TimePicker
            className={classes.datePicker}
            id='start_time'
            value={values.shipmentInfo?.scheduledTime}
            onChange={(time) => {
              if (time) {
                setFieldValue('shipmentInfo.scheduledTime', time);
              }
            }}
            format='HH:mm'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' className={classes.adornment}>
                  <CalendarTodayIcon className={classes.calendarIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Typography style={{ marginBottom: '24px' }}>{`${t(
        'app.shipmentMethod',
      )}: ${shipmentInfo?.typeMethod}`}</Typography>
      {shipmentInfo?.typeMethod === B2BShipmentMethodTypeEnum.PicknPack ? (
        <>
          <Box className={classes.pickerWrapper}>
            <FastField name={`shipmentInfo.driverInfo.fullName`}>
              {({ field: { value, ...field }, meta }: FieldProps) => (
                <FormControl
                  style={{ width: '196px' }}
                  error={meta.touched && !!meta.error}
                >
                  <Input
                    disableUnderline
                    id={`input-driver-info-full-name`}
                    {...field}
                    defaultValue={value}
                    value={value}
                    placeholder={t('app.driverFullName')}
                  />
                  {meta.touched && !!meta.error && (
                    <FormHelperText>{t(meta.error)}</FormHelperText>
                  )}
                </FormControl>
              )}
            </FastField>
            <FastField name={`shipmentInfo.driverInfo.mobileNumber`}>
              {({ field: { value, ...field }, meta }: FieldProps) => (
                <FormControl
                  style={{ width: '196px' }}
                  error={meta.touched && !!meta.error}
                >
                  <Input
                    disableUnderline
                    id={`input-driver-info-mobile-number`}
                    {...field}
                    defaultValue={value}
                    value={value}
                    placeholder={t('app.driverMobileNumber')}
                  />
                  {meta.touched && !!meta.error && (
                    <FormHelperText>{t(meta.error)}</FormHelperText>
                  )}
                </FormControl>
              )}
            </FastField>
          </Box>
          <Box className={classes.pickerWrapper}>
            <FastField name={`shipmentInfo.driverInfo.carModel`}>
              {({ field: { value, ...field }, meta }: FieldProps) => (
                <FormControl
                  style={{ width: '196px' }}
                  error={meta.touched && !!meta.error}
                >
                  <Input
                    disableUnderline
                    id={`input-driver-info-car-model`}
                    {...field}
                    defaultValue={value}
                    value={value}
                    placeholder={t('app.driverCarModel')}
                  />
                  {meta.touched && !!meta.error && (
                    <FormHelperText>{t(meta.error)}</FormHelperText>
                  )}
                </FormControl>
              )}
            </FastField>
            <FastField name={`shipmentInfo.driverInfo.carNumber`}>
              {({ field: { value, ...field }, meta }: FieldProps) => (
                <FormControl
                  style={{ width: '196px' }}
                  error={meta.touched && !!meta.error}
                >
                  <Input
                    disableUnderline
                    id={`input-driver-info-car-number`}
                    {...field}
                    defaultValue={value}
                    value={value}
                    placeholder={t('app.driverCarNumber')}
                  />
                  {meta.touched && !!meta.error && (
                    <FormHelperText>{t(meta.error)}</FormHelperText>
                  )}
                </FormControl>
              )}
            </FastField>
          </Box>
        </>
      ) : (
        <Link
          className={classes.actionLink}
          to={`/api/getAttachment/${shipmentInfo?.shipmentLabelFileUrl}`}
          target='_blank'
        >
          <IconButton component='span' style={{ padding: 0 }}>
            <AttachFileIcon />
          </IconButton>
          {t('app.downloadLabel')}
        </Link>
      )}
    </div>
  );
};

export default B2bShipmentPackagingStep4;
