import React, { useEffect, useState } from 'react';
import { Box, Divider, List, ListItem, Typography } from '@material-ui/core';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  LOCAL_STORAGE_KEYS,
  warehouseStatuses,
} from '../../../utils/constants';
import styles from './styles';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import TrackTrackNumberComponent from '../../../components/TrackTrackNumberComponent/TrackTrackNumberComponent';
import TableList from '../../../components/TableList/TableList';
import { headCells } from './options';
import {
  UndeclaredShipment,
  UndeclaredShipmentItem,
  useGetUndeclaredShipmentInfoQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TRoutes } from '../../../utils/helpers';

const createData = (tableRowData: UndeclaredShipmentItem | null) => {
  const product = tableRowData?.product;
  const titleId = product?.barcodeId?.split('.')[0];

  return {
    id: tableRowData?.productId?.toString(),
    titleId,
    productSku: product?.sku,
    productName: product?.name,
    quantity: tableRowData?.quantity,
  };
};

const UndeclaredArrivalDetails: React.FC = () => {
  const classes = styles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );

  const [undeclaredArrival, setUndeclaredArrival] =
    useState<UndeclaredShipment | null>(null);

  const { data, loading } = useGetUndeclaredShipmentInfoQuery({
    variables: {
      getUndeclaredShipmentInfoId: +id,
    },
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  useEffect(() => {
    if (data) {
      setUndeclaredArrival(
        data?.getUndeclaredShipmentInfo as UndeclaredShipment,
      );
    }
  }, [data]);

  const getQuantityItems = (
    products?: Maybe<Maybe<UndeclaredShipmentItem>[]>,
  ) => {
    const totalQuantity = products?.reduce(
      (acc, item) => acc + item?.quantity!,
      0,
    );
    return totalQuantity;
  };

  const rows =
    undeclaredArrival?.items?.map((tableRowData) => createData(tableRowData)) ||
    [];

  const formattedDate = moment(Number(undeclaredArrival?.createdAt)).format(
    DATE_FORMAT,
  );
  const formattedArrivalDate = moment(
    Number(undeclaredArrival?.updatedAt),
  ).format(DATE_TIME_FORMAT);

  return (
    <RequestHandler loading={loading}>
      <>
        <Box mb={4}>
          <Typography className={classes.title} variant='h2' align='center'>
            {`${t('app.view')} UND-${undeclaredArrival?.id}`}
          </Typography>
        </Box>
        <List>
          <ListItem>
            <Typography variant='h6'>{`${t('app.shipmentNumber2')}: UND-${
              undeclaredArrival?.id
            }`}</Typography>
          </ListItem>
          <ListItem className={classes.itemInfo}>
            {`${t('app.shippingDate')}: ${formattedDate}`}
          </ListItem>
          <ListItem className={classes.itemInfo}>
            {`${t('app.orderClient')}: `}
            <Link
              to={`${
                TRoutes.PAYMENT_STATISTICS_PER_USER_WITHOUT_ID
              }${undeclaredArrival?.user?.id.toString()}`}
            >
              {undeclaredArrival?.user?.name}
            </Link>
          </ListItem>
          <ListItem className={classes.itemInfo}>
            {`${t('app.warehouseStatus')}: ${t(
              warehouseStatuses[undeclaredArrival?.warehouseStatus as string],
            )} ${formattedArrivalDate} ${t('app.warehouse')} ${
              undeclaredArrival?.warehouse
            }`}
          </ListItem>
          {undeclaredArrival?.trackNumber && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.trackingNumber')}: `}
              <TrackTrackNumberComponent
                carrierCode={undeclaredArrival?.carrierCode as string}
                trackNumber={undeclaredArrival?.trackNumber}
              />
            </ListItem>
          )}
          <Divider />
          <ListItem className={classes.itemInfo}>
            {`${t('app.warehouse')}: ${undeclaredArrival?.warehouse}`}
          </ListItem>
          <Divider />
          <ListItem className={classes.itemInfo}>
            {`${t('app.acceptedQuantity')}: ${getQuantityItems(
              undeclaredArrival?.items,
            )}`}
          </ListItem>
          {undeclaredArrival?.cost && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.processingCost')}: ${
                undeclaredArrival?.cost
              } ${CURRENT_APP_CURRENCY}`}
            </ListItem>
          )}
          <Divider />
          {undeclaredArrival?.note && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.comment')}: ${undeclaredArrival?.note}`}
            </ListItem>
          )}
        </List>

        <Box pb={1} pt={1} pl={2}>
          <Typography variant='h6'>{t('app.listOfItems')}</Typography>
        </Box>

        {rows.length ? <TableList headCells={headCells} rows={rows} /> : null}

        {undeclaredArrival?.attachments &&
          undeclaredArrival?.attachments?.map((item) => (
            <Link to={`/api/getAttachment/${item?.filePath}`} target='_blank'>
              <img
                className={classes.image}
                src={`${process.env.REACT_APP_API_DOMAIN}/api/getAttachment/${item?.filePath}`}
                alt={item?.filePath ? item?.filePath : ''}
                loading='lazy'
              />
            </Link>
          ))}
      </>
    </RequestHandler>
  );
};

export default UndeclaredArrivalDetails;
