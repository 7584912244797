import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Else, If, Then, When } from 'react-if';
import { useLazyQuery } from '@apollo/client';
import { Pagination } from '@material-ui/lab';
import useStyles from './styles';
import PaymentStatisticsRowItem from '../../components/PaymentStatisticsRowItem/PaymentStatisticsRowItem';
import {
  EXTENDED_DATE_FILTERS,
  handleSelectDate,
  LIMIT_ITEM_PAGE,
} from '../../utils/helpers';
import { getBalance } from '../../GraphQL/queries/getBalance';
import { ShowLoadingText } from '../../utils/helperComponents';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { useDebouncedCallback } from 'use-debounce';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

export default function PaymentStatistics() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [filter, setFilter] = useState({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    dateSelectValue: EXTENDED_DATE_FILTERS.TODAY,
    dateFrom: moment(),
    dateTo: moment(),
  });

  const [getBalanceLazyQuery, { loading, error, data }] = useLazyQuery(
    getBalance,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  if (error) enqueueSnackbar(error.message, { variant: 'error' });

  const refetchBalance = useDebouncedCallback(() => {
    getBalanceLazyQuery({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit,
        limit: filter.itemsLimit,
        dateFrom: filter.dateFrom ? filter.dateFrom.format('YYYY.MM.DD') : null,
        dateTo: filter.dateTo ? filter.dateTo.format('YYYY.MM.DD') : null,
      },
    });
  });

  useEffect(() => {
    refetchBalance();
    // eslint-disable-next-line
  }, [filter]);

  const payments =
    data && data.getBalance && data.getBalance.rows ? data.getBalance.rows : [];

  return (
    <Grid container spacing={2} justify='center'>
      <Grid item xs={12} sm={12}>
        <Typography variant='h2' className={classes.mainTitle}>
          {t('app.paymentStatistics')}
        </Typography>
        <Divider />

        <div className={classes.filters}>
          <FormControl className={classes.filtersItemSelect}>
            <Select
              displayEmpty
              disableUnderline
              value={filter.dateSelectValue}
              onChange={(e) => handleSelectDate(e, filter, setFilter)}
            >
              <MenuItem value='' disabled>
                {t('app.period')}
              </MenuItem>
              <MenuItem value={EXTENDED_DATE_FILTERS.TODAY}>
                {t('app.today')}
              </MenuItem>
              <MenuItem value={EXTENDED_DATE_FILTERS.YESTERDAY}>
                {t('app.yesterday')}
              </MenuItem>
              <MenuItem value={EXTENDED_DATE_FILTERS.LAST_7_DAYS}>
                {t('app.lastDays', { value: 7 })}
              </MenuItem>
              <MenuItem value={EXTENDED_DATE_FILTERS.LAST_30_DAYS}>
                {t('app.lastDays', { value: 30 })}
              </MenuItem>
              <MenuItem value={EXTENDED_DATE_FILTERS.ALL_TIME}>
                {t('app.allPeriod')}
              </MenuItem>
              <MenuItem value={EXTENDED_DATE_FILTERS.RANGE}>
                {t('app.dateRange')}
              </MenuItem>
            </Select>
          </FormControl>

          {filter.dateSelectValue === EXTENDED_DATE_FILTERS.RANGE && (
            <>
              <FormControl className={classes.filtersItemSelect}>
                <BoxCentered>
                  <InputLabel
                    className={classes.dateLabel}
                    shrink={false}
                    htmlFor='input-date-from'
                  >
                    {t('app.from')}:
                  </InputLabel>
                  <DatePicker
                    id='input-date-from'
                    value={filter.dateFrom}
                    format='YYYY.MM.DD'
                    onChange={(date) => {
                      setFilter((state) => {
                        return {
                          ...state,
                          page: 1,
                          dateFrom: date,
                        } as typeof filter;
                      });
                    }}
                    maxDate={filter.dateTo}
                  />
                </BoxCentered>
              </FormControl>
              <FormControl className={classes.filtersItemSelect}>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <InputLabel
                    className={classes.dateLabel}
                    margin={0 as any}
                    shrink={false}
                    htmlFor='input-date-to'
                  >
                    {t('app.before')}
                  </InputLabel>
                  <DatePicker
                    id='input-date-to'
                    value={filter.dateTo}
                    format='YYYY.MM.DD'
                    onChange={(date) => {
                      setFilter((state) => {
                        return {
                          ...state,
                          page: 1,
                          dateTo: date,
                        } as typeof filter;
                      });
                    }}
                    minDate={filter.dateFrom}
                    maxDate={moment()}
                  />
                </Box>
              </FormControl>
            </>
          )}
          <FormControl className={classes.filtersItemSelect}>
            <BoxCentered pl={2}>
              <span>{t('app.show')}</span>
              <Select
                displayEmpty
                disableUnderline
                value={filter.itemsLimit}
                onChange={(e) => {
                  localStorage.setItem(
                    LOCAL_STORAGE_KEYS.ITEMS_LIMIT,
                    e.target.value as string,
                  );
                  setFilter(
                    (state) =>
                      ({
                        ...state,
                        itemsLimit: e.target.value,
                        page: 1,
                      } as typeof filter),
                  );
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </BoxCentered>
          </FormControl>
        </div>

        <If condition={loading}>
          <Then>
            <div>
              <ShowLoadingText />
            </div>
          </Then>
          <Else>
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>№</TableCell>
                    <TableCell align='center'>{t('app.orderClient')}</TableCell>
                    <TableCell align='center'>{t('app.company')}</TableCell>
                    <TableCell align='center'>Email</TableCell>
                    <TableCell align='center'>{t('app.arrival')}</TableCell>
                    <TableCell align='center'>{t('app.writeOff')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <When condition={!payments || !payments.length}>
                    <TableRow>
                      <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                        {t('app.noDataToDisplay')}
                      </TableCell>
                    </TableRow>
                  </When>
                  {payments.map((payment: any) => (
                    <PaymentStatisticsRowItem
                      key={payment.id}
                      payment={payment}
                    />
                  ))}
                  <TableRow className={classes.totalRow}>
                    <TableCell colSpan={3} />
                    <TableCell align='center'>
                      <strong>{t('app.total')}:</strong>
                    </TableCell>
                    <TableCell align='center'>
                      {data?.getBalance?.totalIncome}
                    </TableCell>
                    <TableCell align='center'>
                      {data?.getBalance?.totalPaymentParcel}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Else>
        </If>
        <Pagination
          className={classes.pagination}
          count={
            data?.getBalance?.count
              ? Math.ceil(data.getBalance.count / filter.itemsLimit)
              : 1
          }
          page={filter.page}
          onChange={(_, page) => {
            setFilter((state) => ({
              ...state,
              page: page,
            }));
          }}
          color='primary'
        />
      </Grid>
    </Grid>
  );
}
