import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import iconPackage from '../../../assets/img/icon_package.png';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { TSetStateBoolean } from '../../../utils/types';
import {
  B2BOrder,
  B2BOrderCargoPlace,
  B2BOrderProduct,
  B2BWrapTypeEnum,
} from '../../../generated/graphql';
import TableList from '../../../components/TableList/TableList';
import { cargoPlacesHeadCells } from './options';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import B2bShipmentViewCargoPlaceModal from '../../../components/Modals/B2bShipmentsModals/B2bShipmentViewCargoPlaceModal';
import { useFormikContext } from 'formik';

export type B2BOrderProductWithCargoQuantity = B2BOrderProduct & {
  b2bCargoProductsQuantity: number;
};

const createData = (
  tableRowData: B2BOrderCargoPlace,
  t: TFunction<'translation', undefined>,
  setIsOpenModal: TSetStateBoolean,
  setCargoPlace: React.Dispatch<
    React.SetStateAction<B2BOrderCargoPlace | null>
  >,
  index: number,
  setCargoPlaceNumber: React.Dispatch<React.SetStateAction<number | null>>,
  type: B2BWrapTypeEnum,
) => {
  return {
    type,
    uniqSkuCount: tableRowData.uniqSkuCount,
    productQty: tableRowData?.productQty,
    dimensions: `${tableRowData?.width}x${tableRowData?.length}x${
      tableRowData?.height
    } ${t('app.cm')}`,
    weight: `${tableRowData?.weight} ${t('app.kg')}`,
    barcode: tableRowData.externalBarcodeUrl
      ? t('app.downloaded')
      : t('app.dontDownloaded'),
    viewCargoPlace: (
      <Link
        to='#'
        onClick={() => {
          setIsOpenModal(true);
          setCargoPlace(tableRowData);
          setCargoPlaceNumber(index);
        }}
      >
        {t('app.view')}
      </Link>
    ),
  };
};

const createCargoProductData = (
  tableRowData: B2BOrderProductWithCargoQuantity,
) => {
  const relatedProduct = tableRowData.relatedProduct;
  const titleId = relatedProduct?.barcodeId?.split('.')[0];
  return {
    id: tableRowData?.productId?.toString(),
    titleId,
    productSku: relatedProduct?.sku,
    productName: relatedProduct?.name,
    b2bCargoProductsQuantity: tableRowData.b2bCargoProductsQuantity,
  };
};

const B2bShipmentPackagingStep2 = ({
  setIsOpenModal,
}: {
  setIsOpenModal: TSetStateBoolean;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const { values } = useFormikContext<B2BOrder>();
  const { cargoPlaces, products, wrapType } = values;
  const [isOpenViewCargoPlaceModal, setisOpenViewCargoPlaceModal] =
    useState(false);
  const [cargoPlace, setCargoPlace] = useState<B2BOrderCargoPlace | null>(null);
  const [cargoPlaceProducts, setCargoPlaceProducts] = useState<
    B2BOrderProductWithCargoQuantity[] | null
  >(null);
  const [cargoPlaceNumber, setCargoPlaceNumber] = useState<number | null>(null);

  useEffect(() => {
    if (cargoPlace) {
      setCargoPlaceProducts(
        cargoPlace?.productsItems?.map((product) => {
          const selectedProduct = products?.find(
            (item) => item?.productId === product?.productId,
          );
          return {
            ...selectedProduct,
            b2bCargoProductsQuantity: product?.quantity,
          };
        }) as B2BOrderProductWithCargoQuantity[],
      );
    }
    //eslint-disable-next-line
  }, [cargoPlace]);

  const isCargoPlaces = !!cargoPlaces?.length;

  const rows =
    cargoPlaces?.map((tableRowData, index) =>
      createData(
        tableRowData as B2BOrderCargoPlace,
        t,
        setisOpenViewCargoPlaceModal,
        setCargoPlace,
        index,
        setCargoPlaceNumber,
        wrapType as B2BWrapTypeEnum,
      ),
    ) || [];

  const rowsProducts =
    cargoPlaceProducts?.map((tableRowData) =>
      createCargoProductData(tableRowData as B2BOrderProductWithCargoQuantity),
    ) || [];

  return (
    <div className={classes.stepContent}>
      <Button
        variant='outlined'
        onClick={handleOpenModal}
        style={{ marginBottom: '24px' }}
      >
        <BoxCentered mr={2}>
          <img
            src={iconPackage}
            alt='barcode'
            style={{ width: 25, height: 25 }}
          />
        </BoxCentered>
        {isCargoPlaces ? t('app.edit') : t('app.toPackage')}
      </Button>
      {rows?.length ? (
        <TableList headCells={cargoPlacesHeadCells} rows={rows} />
      ) : null}
      <B2bShipmentViewCargoPlaceModal
        isOpenModal={isOpenViewCargoPlaceModal}
        setIsOpenModal={setisOpenViewCargoPlaceModal}
        cargoPlace={cargoPlace}
        cargoPlaceNumber={cargoPlaceNumber}
        rowsProducts={rowsProducts}
      />
    </div>
  );
};

export default B2bShipmentPackagingStep2;
