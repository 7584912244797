import React, { useEffect, useState } from 'react';

import useStyles from './styles';
import { Box, Grid } from '@material-ui/core';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

export const AllInfoUrData = ({ entityLegalData }: any) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { file } = useGetImg(entityLegalData?.fileName);

  return (
    <Grid container spacing={3} className={classes.wrapperOuter}>
      <Grid item md={6} style={{ display: 'flex' }}>
        <Box className={classes.wrapperInner}>
          <Box className={classes.line}>
            {t('app.company')}: {entityLegalData?.company}
          </Box>
          <Box className={classes.line}>
            {t('app.tin')}: {entityLegalData?.inn}
          </Box>
          <Box className={classes.line}>
            {t('app.kpp')}: {entityLegalData?.kpp}
          </Box>
          <Box className={classes.line}>
            {t('app.ogrn')}: {entityLegalData?.MSRN}
          </Box>
          <Box className={classes.line}>
            {t('app.ceo')}: {entityLegalData?.generalManager}
          </Box>
          <Box className={classes.line}>
            {t('app.legalAddress')}: {entityLegalData?.legalAddress}
          </Box>
          <Box className={classes.line}>
            {t('app.bankFullName')}: {entityLegalData?.nameOfTheBank}
          </Box>
          <Box className={classes.line}>
            {t('app.rc')}: {entityLegalData?.paymentAccount}
          </Box>
          <Box className={classes.line}>
            {t('app.kc')}: {entityLegalData?.correspondentAccount}
          </Box>
          {file ? (
            <Box className={classes.line}>
              <a href={file as any} target='_blank' rel='noreferrer'>
                {t('app.passportScan')}
              </a>
            </Box>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

const useGetImg = (pathImg: string) => {
  const [file, setFile] = useState(null);
  let path =
    process.env.REACT_APP_API_DOMAIN +
    '/api/get-file/legalEntityData/' +
    pathImg;

  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  useEffect(() => {
    fetch(path, {
      method: 'GET',
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        setFile(window.URL.createObjectURL(blob) as any);
      })
      .catch((error) => {
        console.info(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { file };
};
