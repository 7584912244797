import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  ReturnShipmentListItem,
  ReturnShipmentType,
  useGetReturnShipmentsListLazyQuery,
} from '../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import ReturnsTableRowComponent from '../../../components/ReturnShipmentsComponents/ReturnShipmentsTableRowComponent/ReturnsTableRowComponent';

import FilterSelectDepartureDate from '../../../components/FilterComponents/FilterSelectDepartureDate/FilterSelectDepartureDate';
import FilterSelectDeliveryStatus from '../../../components/FilterComponents/FilterSelectDeliveryStatus/FilterSelectDeliveryStatus';
import FilterSelectLimitItems from '../../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';
import FilterInputSearch from '../../../components/FilterComponents/FilterInputSerch/FilterInputSerch';
import TableBodyRequestHandler from '../../../components/TableBodyRequestHandler/TableRequestHandler';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';
import {
  LOCAL_STORAGE_KEYS,
  RETURNS_SHIPMENT_TABLE_CELLS_ARRAY,
} from '../../../utils/constants';
import { DEPARTURE_DATE_FILTER, LIMIT_ITEM_PAGE } from '../../../utils/helpers';
import useStyles from './styles';
import { IFilters } from '../../../utils/types';

const ReturnShipmentsList: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const itemsLimit = localStorage.getItem(
    LOCAL_STORAGE_KEYS.ITEMS_LIMIT_RETURNS,
  );

  const [filter, setFilter] = useState<IFilters>({
    deliveryStatusId: 0,
    search: '',
    page: 1,
    itemsLimit: itemsLimit ? Number(itemsLimit) : LIMIT_ITEM_PAGE,
  });

  const [getReturnShipmentsListLazyQuery, { data, loading }] =
    useGetReturnShipmentsListLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    getReturnShipmentsListLazyQuery({
      variables: {
        filter: {
          offset: (filter.page - 1) * filter.itemsLimit!,
          limit: filter.itemsLimit,
          ...(filter.dateFilter !== DEPARTURE_DATE_FILTER.ALL_TIME && {
            dateFilter: Number(filter.dateFilter),
          }),
          deliveryStatusId:
            typeof filter.deliveryStatusId === 'string'
              ? 0
              : filter.deliveryStatusId,
          type: ReturnShipmentType.Rts,
        },
        search: filter.search,
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <Typography variant='h2' align='center'>
        {t('app.returns')}
      </Typography>

      <div className={classes.filters}>
        <FilterSelectDepartureDate
          value={filter.dateFilter}
          setFilter={setFilter}
        />
        <FilterSelectDeliveryStatus
          value={filter.deliveryStatusId}
          setFilter={setFilter}
        />
        <FilterSelectLimitItems
          value={filter.itemsLimit}
          setFilter={setFilter}
          localStorageItemsLimitKey={LOCAL_STORAGE_KEYS.ITEMS_LIMIT_RETURNS}
        />
        <FilterInputSearch setFilter={setFilter} />
      </div>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {RETURNS_SHIPMENT_TABLE_CELLS_ARRAY.map((text) => (
                <TableCell key={text} align='center' rowSpan={2}>
                  {t(text)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={data?.getReturnShipmentsList?.rows?.length as number}
              colSpan={RETURNS_SHIPMENT_TABLE_CELLS_ARRAY.length}
              loading={loading}
            >
              {data?.getReturnShipmentsList?.rows?.length
                ? data?.getReturnShipmentsList?.rows?.map((item) => (
                    <ReturnsTableRowComponent
                      key={item?.id}
                      item={item as ReturnShipmentListItem}
                    />
                  ))
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={data?.getReturnShipmentsList?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />
    </>
  );
};

export default ReturnShipmentsList;
