import { gql } from '@apollo/client';

export const GET_WAREHOUSE_ZONE_CELLS = gql`
  query GetWarehouseZoneCells($search: String, $limit: Int, $offset: Int) {
    getWarehouseZoneCells(search: $search, limit: $limit, offset: $offset) {
      count
      rows {
        barcode
        id
        isDefault
        name
        orderNumber
        totalProductsCount
        warehouseZoneId
      }
    }
  }
`;
