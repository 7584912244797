import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { TRoutes } from '../../../utils/helpers';
import { Edit as EditIcon } from '@material-ui/icons';
import { COLORS, TEXT } from '../../../utils/constants';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useHistory } from 'react-router-dom';
import {
  GetCarrierCodeImage,
  ShowLoadingText,
} from '../../../utils/helperComponents';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import ModalBox from '../../../components/ModalBox/ModalBox';
import dhl from '../../../assets/img/carriers/dhl.svg';
import fedex from '../../../assets/img/carriers/fedex.svg';
import tnt from '../../../assets/img/carriers/TNT.png';
import ups from '../../../assets/img/carriers/ups.svg';
import usps from '../../../assets/img/carriers/usps.png';
import easypost from '../../../assets/img/carriers/easypost.png';
import {
  SenderAccount,
  useGetSenderAccountListLazyQuery,
  useRemoveSenderAccountMutation,
  useUpdateSenderAccountMutation,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import styles from './styles';
import { useTranslation } from 'react-i18next';

export const SenderAccountsList: React.FC = () => {
  const classes = styles();
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [
    getAccountsLazy,
    { data: dataAccounts, loading: isLoadingGetAccounts },
  ] = useGetSenderAccountListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [update, { loading: isLoadingUpdate }] =
    useUpdateSenderAccountMutation();
  const [remove, { loading: isLoadingRemove }] =
    useRemoveSenderAccountMutation();

  useEffect(() => {
    getAccountsLazy();
    // eslint-disable-next-line
  }, []);

  const handleUpdate = (sender: SenderAccount, isChecked: boolean) => {
    if (sender) {
      update({
        variables: {
          //@ts-ignore
          input: {
            id: sender.id,
            status: isChecked,
          },
        },
      })
        .then((value) => {
          if (value.data?.updateSenderAccount) {
            enqueueSnackbar(t('app.statusChanged'), { variant: 'success' });
          }
        })
        .catch((reason) => {
          console.error(reason);
          reason?.message &&
            enqueueSnackbar(reason?.message, { variant: 'error' });
        });
    }
  };

  const handleRemove = (id: number) => {
    remove({ variables: { id } })
      .then((value) => {
        if (value?.data?.removeSenderAccount) {
          enqueueSnackbar(t('app.accountDeleted', { value: id }), {
            variant: 'success',
          });
        }
      })
      .catch((reason) => {
        console.error(reason);
        reason?.message &&
          enqueueSnackbar(reason.message, { variant: 'error' });
      })
      .finally(() => getAccountsLazy());
  };

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {t('app.carriers').toUpperCase()}
      </Typography>

      <Divider />
      <br />
      <BoxCentered justifyContent='flex-end' mb={2}>
        <Tooltip title={t('app.openSenderAccountForm')}>
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => setIsOpenModal(true)}
          >
            {t('app.connectAccount')}
          </Button>
        </Tooltip>
      </BoxCentered>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>{t('app.carrier')}</TableCell>
              <TableCell align='center'>{t('app.accountName')}</TableCell>
              <TableCell align='center'>{t('app.active')}</TableCell>
              <TableCell align='center'>{t('app.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoadingGetAccounts &&
            dataAccounts?.getSenderAccountList?.rows?.length ? (
              dataAccounts?.getSenderAccountList?.rows?.map((sender) => {
                return (
                  !!sender && (
                    <TableRow>
                      <TableCell align='center'>
                        <BoxCentered>
                          {sender.serviceName && (
                            <>
                              <GetCarrierCodeImage
                                carrierCode={sender.serviceName.toLowerCase()}
                              />
                              {sender.serviceName.toUpperCase()}
                            </>
                          )}
                        </BoxCentered>
                      </TableCell>
                      <TableCell align='center'>{sender.accountName}</TableCell>
                      <TableCell align='center'>
                        {isLoadingUpdate ? (
                          <CircularProgress size={16} />
                        ) : (
                          <Tooltip
                            title={
                              !!sender.status
                                ? t('app.switchOff')
                                : t('app.turnOn')
                            }
                          >
                            <Switch
                              checked={!!sender.status}
                              onChange={(event) => {
                                handleUpdate(
                                  sender as SenderAccount,
                                  event.target.checked,
                                );
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton
                          aria-label='edit'
                          onClick={() => {
                            if (sender.id) {
                              history.push(
                                TRoutes.SETTINGS_SENDER_ACCOUNTS_UPDATE.replace(
                                  ':id',
                                  sender.id.toString(),
                                ),
                              );
                            }
                          }}
                        >
                          <Tooltip title={t('app.edit')}>
                            <EditIcon style={{ color: COLORS.ORANGE }} />
                          </Tooltip>
                        </IconButton>

                        <IconButton
                          aria-label='remove'
                          onClick={() => {
                            if (sender.id) {
                              handleRemove(sender.id);
                            }
                          }}
                        >
                          <Tooltip title={t('app.delete')}>
                            {isLoadingRemove ? (
                              <CircularProgress size={20} />
                            ) : (
                              <DeleteForeverIcon
                                style={{ color: COLORS.RED }}
                              />
                            )}
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                );
              })
            ) : isLoadingGetAccounts ? (
              <TableRow>
                <TableCell align='center' colSpan={4}>
                  <ShowLoadingText name={t('app.accounts')} />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={4}>
                  {t('app.noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalBox isOpen={isOpenModal} setOpen={setIsOpenModal}>
        <BoxCentered mb={2}>{t('app.connectCarrierAccount')}</BoxCentered>

        <Grid container spacing={2}>
          <Grid item xs={4} className={classes.modalItem}>
            <Tooltip title='DHL Express'>
              <Button
                onClick={() => {
                  history.push(
                    TRoutes.SETTINGS_SENDER_ACCOUNTS_CREATE.replace(
                      ':type',
                      'dhl',
                    ),
                  );
                }}
              >
                <img src={dhl} alt='DHL' className={classes.carrierLogo} />
              </Button>
            </Tooltip>
          </Grid>

          <Grid item xs={4} className={classes.modalItem}>
            <Button
              onClick={() => {
                history.push(
                  TRoutes.SETTINGS_SENDER_ACCOUNTS_CREATE.replace(
                    ':type',
                    'ups',
                  ),
                );
              }}
            >
              <img src={ups} alt='UPS' className={classes.carrierLogo} />
            </Button>
          </Grid>

          <Grid item xs={4} className={classes.modalItem}>
            <Button
              onClick={() => {
                history.push(
                  TRoutes.SETTINGS_SENDER_ACCOUNTS_CREATE.replace(
                    ':type',
                    'fedex',
                  ),
                );
              }}
            >
              <img src={fedex} alt='Fedex' className={classes.carrierLogo} />
            </Button>
          </Grid>

          <Grid item xs={4} className={classes.modalItem}>
            <Tooltip title='EasyPost'>
              <Button
                onClick={() => {
                  history.push(
                    TRoutes.SETTINGS_SENDER_ACCOUNTS_CREATE.replace(
                      ':type',
                      'easypost',
                    ),
                  );
                }}
              >
                <img
                  src={easypost}
                  alt='EASYPOST'
                  className={classes.carrierLogo}
                />
              </Button>
            </Tooltip>
          </Grid>

          <Grid item xs={4} className={classes.modalItem}>
            <Button
              onClick={() => {
                enqueueSnackbar(t(TEXT.SERVICE_IS_NOT_AVAILABLE_YET), {
                  variant: 'warning',
                });
              }}
            >
              <img
                src={usps}
                alt='USPS'
                style={{ maxWidth: 250, maxHeight: 250 }}
              />
            </Button>
          </Grid>

          <Grid item xs={4} className={classes.modalItem}>
            <Button
              onClick={() => {
                enqueueSnackbar(t(TEXT.SERVICE_IS_NOT_AVAILABLE_YET), {
                  variant: 'warning',
                });
              }}
            >
              <img src={tnt} alt='TNT' className={classes.carrierLogo} />
            </Button>
          </Grid>
        </Grid>
      </ModalBox>
    </Container>
  );
};
