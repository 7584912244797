import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../utils/constants';
import { ISwapHistoryTableRowComponent } from './types';
import { getDetails, getMovementOperationText } from '../../../utils/helpers';

const SwapHistoryTableRowComponent = ({
  history,
}: ISwapHistoryTableRowComponent) => {
  const formattedDate = moment(Number(history?.createdAt)).format(DATE_FORMAT);
  const productIdTitle = history.productBarcodeId?.split('.')[0];

  return (
    <TableRow>
      <TableCell align='center'>{history?.id}</TableCell>
      <TableCell align='center'>{formattedDate}</TableCell>
      <TableCell align='center'>{getMovementOperationText(history)}</TableCell>
      <TableCell align='center'>{getDetails(history)}</TableCell>
      <TableCell align='center'>{productIdTitle}</TableCell>
      <TableCell align='center'>{history.quantity}</TableCell>
      <TableCell align='center'>{history.fromCellName}</TableCell>
      <TableCell align='center'>{history.toCellName}</TableCell>
    </TableRow>
  );
};

export default SwapHistoryTableRowComponent;
