import React from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { Link } from 'react-router-dom';

import useStyles from './styles';

import { TRoutes } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <span>Page not found!</span>
      <span>404 :)</span>
      <MuiLink component={Link} variant='body2' to={TRoutes.MAIN}>
        {t('app.goToMainPage')}
      </MuiLink>
    </div>
  );
}
