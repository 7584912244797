import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import {
  ManagerRoles,
  useCreateManagerMutation,
  useGetManagersQuery,
  useGetWarehousesListQuery,
  useRemoveManagerMutation,
  useUpdateManagerMutation,
} from '../../../generated/graphql';
import { DEBOUNCE } from '../../../utils/constants';
import { useSnackbar } from 'notistack';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { Field, FieldProps, Form, Formik } from 'formik';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { debounce } from 'debounce';
import { initialManagerValues } from '../../../utils/helpers';
import { formManagerSchema } from '../../../utils/validationSchemes';
import AddManagerTable from '../../../components/AddManagerTable/AddManagerTable';
import { useTranslation } from 'react-i18next';

const Managers: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [warehouseManagersList, setWarehouseManagersList] = useState<any[]>([]);

  const [role, setRole] = useState<ManagerRoles | null>(null);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [isOpenRemoveWarehouseManager, setIsOpenRemoveWarehouse] =
    useState(false);
  const [isOpenAddWarehouseManager, setIsOpenAddWarehouseManager] =
    useState(false);
  const [warehouseManagerToEdit, setWarehouseManagerToEdit] = useState<any>({});
  const [warehouseManagerToRemove, setWarehouseManagerToRemove] = useState<any>(
    {},
  );

  const {
    data,
    loading,
    error,
    refetch: warehouseRefetch,
  } = useGetManagersQuery();

  const { t } = useTranslation();

  const [createWarehouseManagerMutation] = useCreateManagerMutation();
  const [removeWarehouseManagerMutation] = useRemoveManagerMutation();
  const [updateWarehouseManagerMutation] = useUpdateManagerMutation();
  const {
    data: warehouseListQuery,
    error: errorWarehousesList,
    loading: loadingWarehousesList,
  } = useGetWarehousesListQuery();

  const warehouses = warehouseListQuery?.getWarehousesList?.rows?.map(
    (warehouse) => {
      return {
        id: warehouse?.id,
        name: warehouse?.code,
      };
    },
  );

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });
  errorWarehousesList?.message &&
    enqueueSnackbar(errorWarehousesList.message, { variant: 'error' });

  useEffect(() => {
    if (!data?.getManagers?.rows) return;
    setWarehouseManagersList(data?.getManagers?.rows);
  }, [data]);

  const handleCreateManager = (input: any) => {
    setIsLoadingAction(true);
    createWarehouseManagerMutation({
      variables: { input: { ...input, role } },
    })
      .then((response) => {
        enqueueSnackbar(
          `${
            role === 'ADMIN'
              ? t('app.administrator')
              : t('app.warehouseEmployee')
          } ${response.data?.createManager?.name} ${t(
            'app.successfullyAdded',
          )}`,
          {
            variant: 'success',
          },
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        warehouseRefetch();
        setIsOpenAddWarehouseManager(false);
        setIsLoadingAction(false);
      });
  };

  const handleUpdateManager = (input: any, id: any) => {
    setIsLoadingAction(true);
    updateWarehouseManagerMutation({
      variables: { id, input: { ...input, role } },
    })
      .then((response) => {
        enqueueSnackbar(
          `${
            role === 'ADMIN'
              ? t('app.administrator')
              : t('app.warehouseEmployee')
          } ${response.data?.updateManager?.name} успешно изменен`,
          {
            variant: 'success',
          },
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        warehouseRefetch();
        setIsOpenAddWarehouseManager(false);
        setIsEditing(false);
        setIsLoadingAction(false);
      });
  };

  const handleRemoveManager = (id: any) => {
    setIsLoadingAction(true);
    removeWarehouseManagerMutation({
      variables: { id },
    })
      .then((response) => {
        enqueueSnackbar(
          `${
            role === 'ADMIN'
              ? t('app.administrator')
              : t('app.warehouseEmployee')
          } ${response.data?.removeManager?.name} успешно добавлен`,
          {
            variant: 'success',
          },
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        warehouseRefetch();
        setIsOpenRemoveWarehouse(false);
        setIsLoadingAction(false);
      });
  };

  const renderField = (
    name: string,
    label: string,
    setFieldValue: TFormikSetFieldValueDebounce,
    disable?: boolean,
  ) => (
    <Field name={name}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <InputLabel
            className={classes.inputLabel}
            shrink={false}
            htmlFor={`${name}-input`}
          >
            {label}
          </InputLabel>
          <Input
            disabled={disable}
            disableUnderline
            id={`${name}-input`}
            {...field}
            defaultValue={value}
            onChange={(e) => {
              const name = e.target.name;
              const value = e.target.value;
              setFieldValue(name, value);
            }}
          />
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );

  return (
    <>
      <Typography variant='h2' align='center'>
        {(t('app.users') || '').toUpperCase()}
      </Typography>

      <AddManagerTable
        setIsOpenAddWarehouseManager={setIsOpenAddWarehouseManager}
        setIsEditing={setIsEditing}
        setRole={setRole}
        setWarehouseManagerToEdit={setWarehouseManagerToEdit}
        setWarehouseManagerToRemove={setWarehouseManagerToRemove}
        setIsOpenRemoveWarehouse={setIsOpenRemoveWarehouse}
        loading={loading}
        isLoadingAction={isLoadingAction}
        role={'ADMIN' as ManagerRoles}
        warehouseManagersList={warehouseManagersList}
      />
      <AddManagerTable
        setIsOpenAddWarehouseManager={setIsOpenAddWarehouseManager}
        setIsEditing={setIsEditing}
        setRole={setRole}
        setWarehouseManagerToEdit={setWarehouseManagerToEdit}
        setWarehouseManagerToRemove={setWarehouseManagerToRemove}
        setIsOpenRemoveWarehouse={setIsOpenRemoveWarehouse}
        loading={loading}
        isLoadingAction={isLoadingAction}
        role={'MANAGER' as ManagerRoles}
        warehouseManagersList={warehouseManagersList}
      />

      <ModalBox
        isOpen={isOpenRemoveWarehouseManager}
        setOpen={setIsOpenRemoveWarehouse}
      >
        <Typography variant='h6'>
          {role === 'ADMIN'
            ? t('app.confirmDeleteAdmin')
            : t('app.confirmDeleteWarehouseEmployee')}
          ?
        </Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setIsOpenRemoveWarehouse(false)}
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => handleRemoveManager(warehouseManagerToRemove.id)}
          >
            {t('app.delete')}
          </Button>
        </div>
      </ModalBox>

      <ModalBox
        isOpen={isOpenAddWarehouseManager}
        setOpen={setIsOpenAddWarehouseManager}
      >
        <Typography variant='h6'>
          {`${!isEditing ? t('app.adding') : t('app.editing')} ${
            role === 'ADMIN'
              ? (t('app.adm') || '').toLowerCase()
              : (t('app.warehouseEmployee2') || '').toLowerCase()
          }`}
        </Typography>
        <Formik
          initialValues={initialManagerValues(warehouseManagerToEdit)}
          enableReinitialize
          validationSchema={formManagerSchema()}
          onSubmit={(values) => {
            !isEditing
              ? handleCreateManager(values)
              : handleUpdateManager(values, warehouseManagerToEdit.id);
          }}
        >
          {({ values, setFieldValue, resetForm }) => {
            const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);
            return (
              <Form>
                {renderField('name', t('app.name'), setFieldValueDebounce)}
                {renderField('email', 'E-mail', setFieldValueDebounce)}
                {renderField(
                  'password',
                  `${isEditing ? t('app.changePassword') : t('app.password')}`,
                  setFieldValueDebounce,
                )}
                <FormControl variant='standard'>
                  <InputLabel
                    className={classes.inputLabel}
                    htmlFor='warehouse'
                  >
                    {t('app.chooseWarehouse2')}
                  </InputLabel>
                  <Select
                    id='warehouse'
                    disabled={loadingWarehousesList}
                    value={values.warehouseId}
                    onChange={(e) => {
                      setFieldValue('warehouseId', e.target.value);
                    }}
                  >
                    <MenuItem
                      value={t('app.chooseWarehouse2')}
                      disabled
                      style={{ display: 'none' }}
                    >
                      {t('app.chooseWarehouse2')}
                    </MenuItem>
                    {warehouses?.map((item: any) => (
                      <MenuItem key={item.name} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className={classes.boxModalButtons}>
                  <Button
                    variant='contained'
                    color='secondary'
                    type='reset'
                    onClick={() => {
                      setIsOpenAddWarehouseManager(false);
                      setWarehouseManagerToEdit({});
                      resetForm();
                    }}
                  >
                    {t('app.close')}
                  </Button>
                  <Button variant='contained' type='submit'>
                    {!isEditing ? t('app.add') : t('app.change')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBox>
    </>
  );
};

export default Managers;
