import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff, VpnKey } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';

import client from '../../GraphQL/client';
import signInMutation from '../../GraphQL/mutations/signIn';
import getCurrentUserQuery from '../../GraphQL/queries/getCurrentUser';

import useStyles from './styles';

import { TRoutes } from '../../utils/helpers';
import { signIn as signInSchema } from '../../utils/validationSchemes';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { useTranslation } from 'react-i18next';

export default function SingIn(props) {
  const [isShowPassword, toggleShowPassword] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const handlerSubmitThen = (token) => {
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, token);

      client
        .query({
          query: getCurrentUserQuery,
          fetchPolicy: 'network-only',
        })
        .then(() => props.history.push(TRoutes.MAIN));
    }
  };

  const handlerSubmit = (values, { setSubmitting }) => {
    client
      .mutate({
        mutation: signInMutation,
        variables: {
          email: values.email,
          password: values.password,
          rememberMe: values.rememberMe,
        },
      })
      .then((response) => handlerSubmitThen(response.data.signInAdmin.token))
      .catch((error) => {
        console.error(error);
        error?.message && enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <Typography className={classes.authTitle} variant='h2' align='center'>
        {t('app.authorization')}
      </Typography>
      <Formik
        initialValues={{ email: '', password: '', remember: false }}
        validationSchema={signInSchema}
        onSubmit={handlerSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Field name='email'>
              {({ field, meta }) => (
                <FormControl
                  error={meta.touched && !!meta.error}
                  className={classes.formControl}
                >
                  <InputLabel shrink={false} htmlFor='input-email'>
                    {t('app.email')}
                  </InputLabel>
                  <Input
                    id='input-email'
                    disableUnderline
                    fullWidth
                    required
                    {...field}
                    startAdornment={
                      <InputAdornment position='end'>
                        <AlternateEmailIcon />
                      </InputAdornment>
                    }
                  />
                  {meta.touched && !!meta.error && (
                    <FormHelperText>{meta.error}</FormHelperText>
                  )}
                </FormControl>
              )}
            </Field>
            <Field name='password'>
              {({ field, meta }) => (
                <FormControl
                  error={meta.touched && !!meta.error}
                  className={classes.formControl}
                >
                  <InputLabel shrink={false} htmlFor='input-password'>
                    {t('app.password')}
                  </InputLabel>
                  <Input
                    disableUnderline
                    fullWidth
                    id='input-password'
                    type={isShowPassword ? 'text' : 'password'}
                    {...field}
                    endAdornment={
                      <InputAdornment
                        position='end'
                        onClick={() => toggleShowPassword(!isShowPassword)}
                      >
                        <IconButton aria-label='toggle password visibility'>
                          {isShowPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {meta.touched && !!meta.error && (
                    <FormHelperText>{meta.error}</FormHelperText>
                  )}
                </FormControl>
              )}
            </Field>
            <Field name='remember'>
              {({ field }) => (
                <div className={classes.rememberMe}>
                  <FormControlLabel
                    control={<Checkbox color={'default'} {...field} />}
                    label={t('app.rememberMe')}
                  />
                </div>
              )}
            </Field>

            <Button
              className={classes.signButton}
              disabled={isSubmitting}
              type='submit'
              variant='contained'
              startIcon={<VpnKey />}
            >
              {t('app.login')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
