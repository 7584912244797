import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    fontSize: '40px',
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}));
