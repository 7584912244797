import { Product } from '../../../generated/graphql';
import { TRoutes } from '../../../utils/helpers';
import SearchIcon from '@material-ui/icons/Search';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import { colorsEnum } from '../../../utils/constants';

export const getActionOptions = (product: Product) => {
  return [
    {
      link: `${
        TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS_WITHOUT_ID
      }${product?.id?.toString()}`,
      Icon: SearchIcon,
      title: 'app.view',
      color: colorsEnum.GREEN,
      tooltipText: 'app.clickToOpenViewShipments',
    },
    {
      link: `/api/getBarcode/${product?.barcodeId}`,
      imgSrc: iconBarcode,
      title: 'app.barcode',
      target: '_blank',
      tooltipText: 'app.clickToOpenBarcode',
    },
  ];
};
