import { Box, IconButton, ListItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DISCOUNTS_PROPERTY_ARRAY } from '../../utils/constants';
import EditIcon from '@material-ui/icons/Edit';
import { Discount } from '../../generated/graphql';
import { TSetStateBoolean, TSetStateString } from '../../utils/types';

const Discounts = ({
  discountData,
  isAdmin,
  setIsDiscountModalOpen,
  setDiscountUserId,
  setDiscountProperty,
  userId,
  setDiscountValue,
}: {
  discountData?: Discount;
  isAdmin: boolean;
  setIsDiscountModalOpen: TSetStateBoolean;
  setDiscountUserId: React.Dispatch<React.SetStateAction<string | number>>;
  setDiscountProperty: TSetStateString;
  userId: string;
  setDiscountValue: React.Dispatch<React.SetStateAction<string | number>>;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {DISCOUNTS_PROPERTY_ARRAY.map((discount) => (
        <ListItem style={{ justifyContent: 'space-between' }}>
          {t(`app.${discount}`)}
          <Box>
            {discount === 'creditLimit'
              ? discountData?.[discount]
              : // @ts-ignore
                Number(discountData?.[discount]) * 100}
            {discount === 'creditLimit' ? '' : '%'}
            {isAdmin ? (
              <IconButton
                size='small'
                edge='end'
                onClick={() => {
                  setIsDiscountModalOpen(true);
                  setDiscountUserId(Number(userId));
                  setDiscountProperty(discount);
                  //  @ts-ignore
                  setDiscountValue(discountData[discount] || 0);
                }}
              >
                <EditIcon color='primary' />
              </IconButton>
            ) : null}
          </Box>
        </ListItem>
      ))}
    </>
  );
};

export default Discounts;
