import React, { useEffect, useState } from 'react';

import { Button, FormHelperText } from '@material-ui/core';
import useStyles from '../styles';
import { useTranslation } from 'react-i18next';
import ModalBox from '../../../ModalBox/ModalBox';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  B2BCargoPlaceProduct,
  B2BOrderCargoPlace,
  B2BWrapWayEnum,
  useEditB2BOrderCargoPlacesMutation,
  useSetB2BOrderCargoPlacesMutation,
} from '../../../../generated/graphql';

import {
  IB2bShipmentCreateCargoPlacesModal,
  IB2bShipmentsServiceCreateModalValues,
  IDimensions,
} from '../types';
import { useSnackbar } from 'notistack';
import { GET_B2B_ORDER_BY_ID_QUERY } from '../../../../GraphQL/queries/getB2BOrderById';
import B2bShipmentCreateCargoPlacesModalStep1 from './B2bShipmentCreateCargoPlacesModalStep1';
import B2bShipmentCreateCargoPlacesModalStep2 from './B2bShipmentCreateCargoPlacesModalStep2';
import { TFormikSetFieldValue } from '../../../../interfaces';
import { B2bShipmentCreateCargoPlaceModalValidationSchemas } from '../../../../utils/validationSchemes';
import { COLORS, ERRORS } from '../../../../utils/constants';

const B2bShipmentCreateCargoPlacesModal = ({
  isOpenModal,
  setIsOpenModal,
  id,
  cargoPlaces,
  products,
  wrapWay,
  wrapType,
}: IB2bShipmentCreateCargoPlacesModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [cargoPlacesQuantity, setCargoPlacesQuantity] = useState(1);

  const [activeStep, setActiveStep] = useState(0);

  const [editB2BOrderCargoPlacesMutation, { loading }] =
    useEditB2BOrderCargoPlacesMutation();

  const [
    setB2BOrderCargoPlacesMutation,
    { loading: setB2BOrderCargoPlacesMutationLoading },
  ] = useSetB2BOrderCargoPlacesMutation();

  useEffect(() => {
    if (!!cargoPlaces?.length)
      setCargoPlacesQuantity(Number(cargoPlaces?.length));
  }, [cargoPlaces?.length]);

  const handleSubmit = (
    values: IB2bShipmentsServiceCreateModalValues,
    { setFieldError }: FormikHelpers<IB2bShipmentsServiceCreateModalValues>,
  ) => {
    if (activeStep) {
      const totalAcceptedProducts = values.products?.reduce((acc, product) => {
        return acc + Number(product?.expectedQuantity);
      }, 0);

      const totalPickedProducts =
        values.cargoPlaces?.reduce((sum, cargo) => {
          if (cargo?.productsItems && Array.isArray(cargo.productsItems)) {
            const productSum = cargo.productsItems.reduce(
              (productSum, product) => {
                return productSum + (product?.quantity || 0);
              },
              0,
            );
            return sum + productSum;
          }
          return sum;
        }, 0) || 0;

      const isTotalCargoPlaceQuantityError =
        activeStep === 1 && totalAcceptedProducts !== totalPickedProducts;

      const isIsMonoQuantityError =
        activeStep === 1 &&
        values.wrapWay === B2BWrapWayEnum.MonoboxOrMonopallet &&
        values?.cargoPlaces?.some(
          (place) =>
            place?.productsItems?.filter((item) => !!item && !!item.quantity)
              .length !== 1,
        );

      const isDimensionsError =
        activeStep === 1 &&
        values?.cargoPlaces?.some(
          (place) => !place?.length && !place?.width && !place?.height,
        );

      if (
        isTotalCargoPlaceQuantityError ||
        isIsMonoQuantityError ||
        isDimensionsError
      ) {
        isTotalCargoPlaceQuantityError &&
          setFieldError(
            'isTotalCargoPlaceQuantityError',
            ERRORS.EQUAL_TOTAL_QUANTITY,
          );
        isIsMonoQuantityError &&
          setFieldError(
            'isIsMonoQuantityError',
            ERRORS.B2B_MONO_QUANTITY_ERROR,
          );
        isDimensionsError &&
          setFieldError('isDimensionsError', ERRORS.DIMENSIONS);
        return;
      }
      const cargoItems = (
        values.cargoPlaces?.map(
          (place) => place?.productsItems,
        ) as B2BCargoPlaceProduct[]
      )
        .flat()
        .filter((product) => !!product && !!product.quantity)
        .map(({ cargoId, productId, quantity }) => ({
          cargoId,
          productId,
          quantity,
        }));

      const cargoPlaces = values?.cargoPlaces?.map((place) => {
        return {
          ...place,
          type: values.wrapWay,
          uniqSkuCount: place?.productsItems?.filter(
            (item) => !!item && !!item.quantity,
          ).length,
        };
      });
      cargoPlaces?.forEach((place) => {
        delete place?.__typename;
        delete place?.externalBarcodeUrl;
        delete place?.productsItems;
      });

      editB2BOrderCargoPlacesMutation({
        variables: {
          orderId: Number(id),
          cargoData: {
            cargoPlaces: cargoPlaces,
            wrapType: values.wrapType,
            wrapWay: values.wrapWay,
          },
          cargoItems,
        },
        onCompleted: (data) => {
          if (data) {
            enqueueSnackbar(t('app.b2bShipmentCargoPlaceSuccessfullyPacked'), {
              variant: 'success',
            });
            setIsOpenModal(false);
            setActiveStep(0);
          }
        },
        onError: (error) => {
          if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
          }
        },
        refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
      });
    } else if (cargoPlacesQuantity === Number(cargoPlaces?.length)) {
      setActiveStep((prev) => prev + 1);
    } else {
      if (Number(cargoPlaces?.length) !== cargoPlacesQuantity) {
        setB2BOrderCargoPlacesMutation({
          variables: { orderId: Number(id), cargosCount: cargoPlacesQuantity },
          onCompleted: (data) => {
            if (data) {
              setActiveStep((prev) => prev + 1);
            }
          },
          onError: (error) => {
            if (error) {
              enqueueSnackbar(error.message, { variant: 'error' });
            }
          },
          refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
          awaitRefetchQueries: true,
        });
      }
    }
  };

  const handleTypePackageChange =
    (value: string, setFieldValue: TFormikSetFieldValue) => () => {
      setFieldValue('wrapType', value);
    };

  const handleInstructionChange =
    (setFieldValue: TFormikSetFieldValue) =>
    (
      e: React.ChangeEvent<{
        label?: string;
        value: unknown;
      }>,
    ) => {
      setFieldValue('wrapWay', e.target.value);
    };

  const handleCloseModal = () => {
    if (activeStep === 0) {
      setIsOpenModal(false);
      setActiveStep(0);
      return;
    }
    setActiveStep((prevState) => prevState - 1);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <B2bShipmentCreateCargoPlacesModalStep1
            handleTypePackageChange={handleTypePackageChange}
            handleInstructionChange={handleInstructionChange}
            cargoPlacesQuantity={cargoPlacesQuantity}
            setCargoPlacesQuantity={setCargoPlacesQuantity}
          />
        );
      case 1:
        return <B2bShipmentCreateCargoPlacesModalStep2 />;
      default:
        return 'Unknown step';
    }
  };

  const uniqueCargoPlaces = cargoPlaces?.reduce((unique, current) => {
    if (
      !unique.some(
        (place) =>
          place.width === current?.width &&
          place.height === current?.height &&
          place.length === current?.length,
      )
    ) {
      current && unique.push(current);
    }
    return unique;
  }, [] as B2BOrderCargoPlace[]);

  return (
    <ModalBox isOpen={isOpenModal} setOpen={setIsOpenModal}>
      <Formik
        initialValues={{
          products: products,
          wrapType: wrapType,
          wrapWay: wrapWay,
          cargoPlaces: cargoPlaces?.map((place) => ({
            externalBarcodeUrl: place?.externalBarcodeUrl,
            height: place?.height,
            id: place?.id,
            length: place?.length,
            productsItems: place?.productsItems,
            type: place?.type,
            uniqSkuCount: place?.uniqSkuCount,
            weight: place?.weight,
            width: place?.width,
          })) as B2BOrderCargoPlace[],
          dimensions: (!!cargoPlaces?.length
            ? uniqueCargoPlaces?.map(({ width, height, length }) => ({
                width,
                height,
                length,
              }))
            : [{ width: 0, height: 0, length: 0 }]) as IDimensions[],
        }}
        // @ts-ignore
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={
          B2bShipmentCreateCargoPlaceModalValidationSchemas[activeStep]
        }
      >
        {({ errors }: FormikProps<IB2bShipmentsServiceCreateModalValues>) => {
          return (
            <Form noValidate autoComplete='off'>
              {getStepContent(activeStep)}
              {/* @ts-ignore */}
              {!!errors.isTotalCargoPlaceQuantityError && (
                <FormHelperText style={{ color: COLORS.RED }}>
                  {/* @ts-ignore */}
                  {t(errors.isTotalCargoPlaceQuantityError)}
                </FormHelperText>
              )}
              {/* @ts-ignore */}
              {!!errors.isIsMonoQuantityError && (
                <FormHelperText style={{ color: COLORS.RED }}>
                  {/* @ts-ignore */}
                  {t(errors.isIsMonoQuantityError)}
                </FormHelperText>
              )}
              {/* @ts-ignore */}
              {!!errors.isDimensionsError && (
                <FormHelperText style={{ color: COLORS.RED }}>
                  {/* @ts-ignore */}
                  {t(errors.isDimensionsError)}
                </FormHelperText>
              )}
              <div className={classes.boxModalButtons}>
                <Button
                  variant='contained'
                  onClick={handleCloseModal}
                  color='secondary'
                >
                  {t('app.back')}
                </Button>
                <Button
                  disabled={loading || setB2BOrderCargoPlacesMutationLoading}
                  type='submit'
                  variant='contained'
                >
                  {!!activeStep
                    ? t('app.save')
                    : `${t('app.distibuteGoods')} >`}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ModalBox>
  );
};

export default B2bShipmentCreateCargoPlacesModal;
