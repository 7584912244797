import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  FormControl,
  Input,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import {
  DEPARTURE_DATE_FILTER,
  LIMIT_ITEM_PAGE,
  TRoutes,
} from '../../../utils/helpers';
import useStyles from './styles';

import {
  LOCAL_STORAGE_KEYS,
  DATE_FORMAT,
  COLORS,
  DELIVERY_STATUS_BY_ID,
} from '../../../utils/constants';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  useCancelWarehouseShipmentMutation,
  useGetWarehouseShipmentsListLazyQuery,
  Warehouse_Shipment_Type,
  WarehouseShipment,
  WarehouseShipmentStatus,
} from '../../../generated/graphql';

import {
  ConvertWarehouseStatus,
  GetCarrierCodeImage,
  ShowLoadingText,
} from '../../../utils/helperComponents';
import { StoreContext } from '../../../utils/store';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { useTranslation } from 'react-i18next';
import FilterSelectDepartureDate from '../../../components/FilterComponents/FilterSelectDepartureDate/FilterSelectDepartureDate';
import { IFilters } from '../../../utils/types';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';
import FilterSelectWarehouseShipmentStatus from '../../../components/FilterComponents/FilterSelectWarehouseShipmentStatus/FilterSelectWarehouseShipmentStatus';

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

const WarehouseShipmentList: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const forwardingRoute = useRouteMatch(
    TRoutes.WAREHOUSE_SHIPMENT_FORWARDING_LIST,
  );
  const isForwarding = forwardingRoute?.isExact;
  const [warehouseShipmentList, setWarehouseShipmentList] = useState<
    WarehouseShipment[]
  >([]);

  const { t } = useTranslation();

  //@ts-ignore
  const { statuses } = React.useContext(StoreContext);
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState(false);
  const [shipmentToCancel, setShipmentToCancel] =
    useState<WarehouseShipment | null>(null);

  const [filter, setFilter] = useState<IFilters>({
    warehouseStatus: '',
    deliveryStatusId: 0,
    page: 1,
    search: '',
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const [
    getWarehouseShipmentsListLazyQuery,
    { loading: isLoadingGetWarehouseShipmentsList, error, data },
  ] = useGetWarehouseShipmentsListLazyQuery();

  const [cancelWarehouseShipmentMutation] =
    useCancelWarehouseShipmentMutation();

  useEffect(() => {
    data?.getWarehouseShipmentsList?.rows &&
      setWarehouseShipmentList(
        data?.getWarehouseShipmentsList?.rows as WarehouseShipment[],
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isForwarding]);

  useEffect(() => {
    refetchWarehouseShipmentsList();
    // eslint-disable-next-line
  }, [filter, isForwarding]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  const refetchWarehouseShipmentsList = useDebouncedCallback(() => {
    setLoadingAction(true);
    getWarehouseShipmentsListLazyQuery({
      variables: {
        filter: {
          offset: (filter.page - 1) * filter.itemsLimit!,
          limit: filter.itemsLimit,
          ...(filter.dateFilter !== DEPARTURE_DATE_FILTER.ALL_TIME && {
            dateFilter: Number(filter.dateFilter),
          }),
          deliveryStatusId:
            typeof filter.deliveryStatusId === 'string'
              ? 0
              : filter.deliveryStatusId,
          type: isForwarding
            ? Warehouse_Shipment_Type.Forwarding
            : Warehouse_Shipment_Type.Inbound,
          ...(filter.warehouseStatus && {
            warehouseStatus: filter.warehouseStatus as WarehouseShipmentStatus,
          }),
        },
        search: filter.search,
      },
    });
    setLoadingAction(false);
  }, 400);

  const handlerCancelWarehouseShipment = (id: number) => {
    cancelWarehouseShipmentMutation({
      variables: { cancelWarehouseShipmentId: id },
    })
      .then((response: any) => {
        enqueueSnackbar(t('app.orderCanceledT'), { variant: 'success' });
        refetchWarehouseShipmentsList();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setIsOpenConfirmCancel(false);
        refetchWarehouseShipmentsList();
      });
  };

  return (
    <>
      <Typography variant='h2' align='center'>
        {!isForwarding
          ? (t('app.productDelivery') || '').toUpperCase()
          : t('app.forwardingShipments')}
      </Typography>

      <div className={classes.filters}>
        <FilterSelectDepartureDate
          value={filter.dateFilter}
          setFilter={setFilter}
        />
        <FormControl className={classes.filtersItemSelect}>
          <FilterSelectWarehouseShipmentStatus
            value={filter.warehouseStatus}
            setFilter={setFilter}
          />
        </FormControl>
        <FormControl className={classes.filtersItemSelect}>
          <Select
            displayEmpty
            disableUnderline
            value={filter.deliveryStatusId}
            onChange={(e) => {
              setFilter((state) => ({
                ...state,
                deliveryStatusId: Number(e.target.value),
                page: 1,
              }));
            }}
          >
            <MenuItem value='' disabled>
              {t('app.deliveryStatus')}
            </MenuItem>
            <MenuItem value={0}>{t('app.allDeliveryStatuses')}</MenuItem>
            {statuses?.delivery?.map((status: any) => (
              <MenuItem key={status.id} value={status.id}>
                {status.explain}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.filtersItemSelect}>
          <BoxCentered pl={2}>
            <span>{t('app.show')}</span>
            <Select
              displayEmpty
              disableUnderline
              value={filter.itemsLimit}
              onChange={(e) => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.ITEMS_LIMIT,
                  //@ts-ignore
                  e.target.value,
                );
                //@ts-ignore
                setFilter((state) => ({
                  ...state,
                  itemsLimit: e.target.value,
                  page: 1,
                }));
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </BoxCentered>
        </FormControl>
        <form
          className={classes.filtersSearch}
          onSubmit={(e) => {
            e.preventDefault();
            refetchWarehouseShipmentsList();
          }}
        >
          <Input
            placeholder={t('app.enterText')}
            className={classes.filtersSearchField}
            onChange={(e) => {
              setFilter((state) => ({
                ...state,
                search: e.target.value,
                page: 1,
              }));
            }}
            value={filter.search}
            disableUnderline
          />
          <Button
            className={classes.filtersSearchSubmit}
            variant='contained'
            type='submit'
            aria-label='search'
          >
            <SearchIcon />
          </Button>
        </form>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' rowSpan={2}>
                {t('app.shipmentNumber2')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.orderClient')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.to')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.trackingNumberLabel')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.deliveryStatus')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.warehouseStatus2')}
              </TableCell>
              {!isForwarding ? (
                <TableCell align='center' rowSpan={2}>
                  {t('app.expectedDateArrival')}
                </TableCell>
              ) : null}
              <TableCell align='center' rowSpan={2}>
                {t('app.places')}
              </TableCell>
              <TableCell align='center' colSpan={2}>
                {t('app.expectedQuantity')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.action')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' colSpan={2}>
                {t('app.acceptedQuantity')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!data ||
              isLoadingGetWarehouseShipmentsList ||
              isLoadingAction) && (
              <TableRow>
                <TableCell colSpan={15}>
                  <ShowLoadingText name={''} />
                </TableCell>
              </TableRow>
            )}
            {!isLoadingGetWarehouseShipmentsList &&
            !isLoadingAction &&
            !!warehouseShipmentList.length
              ? warehouseShipmentList.map(
                  (item: any) =>
                    item && (
                      <>
                        <TableRow>
                          <TableCell align='center'>
                            <Box display='flex' flexDirection={'column'}>
                              {' '}
                              <Link
                                to={TRoutes.WAREHOUSE_SHIPMENT_DETAILS.replace(
                                  ':id',
                                  item?.id.toString(),
                                )}
                              >
                                {item?.barcode?.split('.')[0]}
                              </Link>
                              {moment(Number(item?.createdAt)).format(
                                DATE_FORMAT,
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align='center'>
                            <Link
                              to={TRoutes.PAYMENT_STATISTICS_PER_USER.replace(
                                ':userId',
                                item?.user?.id.toString(),
                              )}
                            >
                              {item?.user?.name}
                            </Link>
                          </TableCell>
                          <TableCell align='center'>
                            {item?.warehouse?.code}
                          </TableCell>
                          <TableCell align='center'>
                            <BoxCentered>
                              {item.trackNumber && item.carrierCode && (
                                <>
                                  <GetCarrierCodeImage
                                    carrierCode={item.carrierCode}
                                  />
                                  <Tooltip
                                    title={`${t(
                                      'app.trackShipment',
                                    )} ${item.carrierCode.toUpperCase()} ${
                                      item.trackNumber
                                    }`}
                                  >
                                    <NavLink
                                      className={classes.btnIcon}
                                      to={`${TRoutes.TRACK}/${item.carrierCode}/${item.trackNumber}`}
                                    >
                                      <span>{item.trackNumber}</span>
                                    </NavLink>
                                  </Tooltip>
                                </>
                              )}
                            </BoxCentered>
                          </TableCell>
                          <TableCell align='center'>
                            {t(
                              DELIVERY_STATUS_BY_ID[
                                Number(item?.deliveryStatus.id)
                              ],
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            <ConvertWarehouseStatus
                              status={item.warehouseStatus as string}
                            />
                          </TableCell>
                          {!isForwarding ? (
                            <TableCell align='center'>
                              {moment(Number(item?.expectedArrivalDate)).format(
                                DATE_FORMAT,
                              )}
                            </TableCell>
                          ) : null}
                          <TableCell align='center'>{item.places}</TableCell>
                          <TableCell align='center' colSpan={2}>
                            {item.expectedQuantity} / {item.acceptedQuantity}
                          </TableCell>

                          <TableCell align='center'>
                            <Select value={t('app.action')}>
                              <MenuItem
                                value={t('app.action')}
                                disabled
                                style={{ display: 'none' }}
                              >
                                {t('app.action')}
                              </MenuItem>
                              <MenuItem
                                disableGutters
                                divider
                                className={classes.actionMenuItem}
                              >
                                <Tooltip title={t('app.clickToOpenBarcode')}>
                                  <a
                                    href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${item?.barcode}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={classes.actionLink}
                                  >
                                    <img
                                      src={iconBarcode}
                                      alt='barcode'
                                      className={`${classes.actionIcon}`}
                                      style={{ width: 20, height: 20 }}
                                    />
                                    {t('app.barcode')}
                                  </a>
                                </Tooltip>
                              </MenuItem>
                              <MenuItem
                                disableGutters
                                divider
                                className={classes.actionMenuItem}
                              >
                                <Link
                                  className={classes.actionLink}
                                  to={TRoutes.WAREHOUSE_SHIPMENT_DETAILS.replace(
                                    ':id',
                                    item?.id.toString(),
                                  )}
                                >
                                  <SearchIcon
                                    className={`${classes.actionIcon} ${classes.actionIconWatch}`}
                                  />
                                  {t('app.view')}
                                </Link>
                              </MenuItem>
                              {!isForwarding ? (
                                <MenuItem
                                  disableGutters
                                  divider
                                  disabled={
                                    item?.warehouseStatus !== 'PROCESSING'
                                  }
                                  className={classes.actionMenuItem}
                                >
                                  <Tooltip title={t('app.clickToCancelOrder')}>
                                    <span
                                      onClick={() => {
                                        setShipmentToCancel(item);
                                        setIsOpenConfirmCancel(true);
                                      }}
                                      className={classes.actionLink}
                                    >
                                      <CancelIcon
                                        className={`${classes.actionIcon}`}
                                        style={{ color: COLORS.RED }}
                                      />
                                      {t('app.cancel')}
                                    </span>
                                  </Tooltip>
                                </MenuItem>
                              ) : null}
                            </Select>
                          </TableCell>
                        </TableRow>
                      </>
                    ),
                )
              : !!data &&
                !isLoadingGetWarehouseShipmentsList &&
                !isLoadingAction && (
                  <TableRow>
                    <TableCell colSpan={12} align='center'>
                      {t('app.noData')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        count={data?.getWarehouseShipmentsList?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />

      <ModalBox isOpen={isOpenConfirmCancel} setOpen={setIsOpenConfirmCancel}>
        <Typography variant='h6'>{t('app.confirmCancelOrder')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setIsOpenConfirmCancel(false);
              setShipmentToCancel(null);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() =>
              handlerCancelWarehouseShipment(shipmentToCancel?.id as number)
            }
          >
            {t('app.cancelTheOrder')}
          </Button>
        </div>
      </ModalBox>
    </>
  );
};

export default WarehouseShipmentList;
