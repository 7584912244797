import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    inputLabel: {
      marginTop: '10px',
    },
    modalWrapper: {
      gap: '24px',
    },
    boxModalButtons: {
      margin: '30px 0 0',
      display: 'flex',
      justifyContent: 'space-around',
      gap: '24px',
    },
  });

export default makeStyles(styles);
