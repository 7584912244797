import { FastField, FieldProps, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { IDualInputField } from './types';
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
} from '@material-ui/core';

export interface InitialValues {
  [key: string]: string[];
}

const DualInputField = ({
  label,
  name,
  inputProps,
  classes,
}: IDualInputField) => {
  const { setFieldValue, values } = useFormikContext<InitialValues>();

  useEffect(() => {
    const value = values[name];
    setFieldValue(`${name}[0]`, removeItemFromFormula(value[0], value[1]));
    //eslint-disable-next-line
  }, []);

  const removeItemFromFormula = (formula: string, item: string) => {
    const itemIndex = formula?.indexOf(item);
    if (itemIndex === -1) {
      return formula;
    }
    return (
      formula?.slice(0, itemIndex) + formula?.slice(itemIndex + item?.length)
    );
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {label}
      </Grid>
      <Grid item xs={12} sm={4}>
        <FastField name={name}>
          {({ field: { value, ...field }, meta }: FieldProps) => {
            return (
              <FormControl error={meta.touched && !!meta.error}>
                <Input
                  disableUnderline
                  id={`${name}[0]`}
                  defaultValue={removeItemFromFormula(value[0], value[1])}
                  {...field}
                  inputProps={inputProps}
                  className={classes?.inputNumber}
                  onChange={(event) => {
                    setFieldValue(`${name}[0]`, event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position='start'>{value[1]}</InputAdornment>
                  }
                />
                {meta.touched && !!meta.error && (
                  <FormHelperText>{meta.error}</FormHelperText>
                )}
              </FormControl>
            );
          }}
        </FastField>
      </Grid>
    </Grid>
  );
};

export default DualInputField;
