import React from 'react';
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { instructionOptions, typePackageOptions } from '../options';
import { COLORS, b2bCargoplacesRadioEnum } from '../../../../utils/constants';
import { useFormikContext } from 'formik';
import {
  IB2bShipmentCreateCargoPlacesModalStep1,
  IB2bShipmentsServiceCreateModalValues,
} from '../types';

const B2bShipmentCreateCargoPlacesModalStep1 = ({
  handleTypePackageChange,
  handleInstructionChange,
  cargoPlacesQuantity,
  setCargoPlacesQuantity,
}: IB2bShipmentCreateCargoPlacesModalStep1) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { values, setFieldValue } =
    useFormikContext<IB2bShipmentsServiceCreateModalValues>();
  return (
    <div>
      <InputLabel shrink={false} htmlFor='instruction'>
        {t('app.typeOfPackaging')}
      </InputLabel>
      <Box className={classes.packageIconsWrapper}>
        {typePackageOptions.map((option) => (
          <Box
            key={option.value}
            className={classes.packageIconWrapper}
            onClick={handleTypePackageChange(option.value, setFieldValue)}
            style={
              values.wrapType === option.value
                ? {
                    backgroundColor: COLORS.MEDIUM_GREY,
                  }
                : {}
            }
          >
            <img
              className={classes.packageIcon}
              src={option.imgSrc}
              alt={option.label}
            />
            <Typography>{t(option.label)}</Typography>
          </Box>
        ))}
      </Box>
      <FormControl variant='standard'>
        <InputLabel shrink={false} htmlFor='instruction'>
          {t('app.instructionForPackaging')}
        </InputLabel>
        <Select
          className={classes.selectContacts}
          id='instruction'
          value={values.wrapWay}
          onChange={handleInstructionChange(setFieldValue)}
        >
          {instructionOptions?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box display='flex' alignItems='center' style={{ marginTop: '12px' }}>
        <Radio
          className={classes.checkBox}
          value={b2bCargoplacesRadioEnum.ONE}
          checked={cargoPlacesQuantity <= 1}
          color='primary'
          onChange={() => {
            setCargoPlacesQuantity(1);
          }}
        />
        <Typography>{t('app.oneGargoPlace')}</Typography>
      </Box>
      <Box display='flex' alignItems='center'>
        <Radio
          className={classes.checkBox}
          value={b2bCargoplacesRadioEnum.SOME}
          checked={cargoPlacesQuantity > 1}
          color='primary'
          onChange={() => {
            setCargoPlacesQuantity(2);
          }}
        />
        <Typography style={{ marginRight: '9px' }}>{t('app.some')}</Typography>
        {cargoPlacesQuantity > 1 ? (
          <Input
            disabled={cargoPlacesQuantity <= 1}
            disableUnderline
            id={`cargo-places-quantity-input`}
            defaultValue={cargoPlacesQuantity}
            onChange={(e) => {
              const value = Number(e.target.value);
              setCargoPlacesQuantity(value);
            }}
            inputProps={{
              type: 'number',
              min: 2,
              step: 1,
            }}
          />
        ) : null}
      </Box>
    </div>
  );
};

export default B2bShipmentCreateCargoPlacesModalStep1;
