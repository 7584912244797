import React from 'react';
import { useTranslation } from 'react-i18next';
import { INotificationsIcon } from './types';
import { COLORS } from '../../utils/constants';
import { Box, IconButton } from '@material-ui/core';
import useStyles from './styles';
import NotificationsIcon from '@material-ui/icons/Notifications';

const NotificationsIconComponent = ({ onClick }: INotificationsIcon) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.wrapper} onClick={onClick}>
      <Box className={classes.iconWrapper}>
        <IconButton className={classes.personIcon}>
          <NotificationsIcon style={{ color: COLORS.BLACK, width: '24px' }} />
        </IconButton>
        <Box className={classes.dot} />
      </Box>

      {t('app.notifications')}
    </Box>
  );
};

export default NotificationsIconComponent;
