import B2bSendIcon from '../../../assets/img/shipment/shipment_b2b_create.png';
import B2bPackageIcon from '../../../assets/img/shipment/shipment_b2b_create_package.png';
import { B2BWrapTypeEnum, B2BWrapWayEnum } from '../../../generated/graphql';

export const instructionOptions = [
  {
    value: B2BWrapWayEnum.MonoboxOrMonopallet,
    label: 'app.B2BWrapWayMonoboxOrMonopallet',
  },
  {
    value: B2BWrapWayEnum.MixBoxOrMixPallet,
    label: 'app.B2BWrapWayMixBoxOrMixPallet',
  },
];

export const typePackageOptions = [
  {
    imgSrc: B2bPackageIcon,
    label: 'app.B2BWrapTypeBoxes',
    value: B2BWrapTypeEnum.Boxes,
  },
  {
    imgSrc: B2bSendIcon,
    label: 'app.B2BWrapTypePallet',
    value: B2BWrapTypeEnum.Pallet,
  },
];

export const CARGO_PLACES_DIMENSIONS_OPTIONS = ['width', 'length', 'height'];
export const getCargoPlacesHeadCells = (cargoPlacesTitleArray: string[]) => [
  'width',
  'length',
  'height',
];
