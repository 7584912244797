import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { Form, Formik, FormikValues } from 'formik';
import styles from './styles';
import SaveIcon from '@material-ui/icons/Save';
import {
  useGetConfigurationListLazyQuery,
  useUpdateConfigurationMutation,
} from '../../../generated/graphql';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import SettingsCalculatorModal from '../../../components/Modals/SettingsCalculatorModal/SettingsCalculatorModal';
import DualInputField from '../../../components/SettingsCalculatorComponents/DualInputField';
import InputCalculator from '../../../components/SettingsCalculatorComponents/InputCalculator';
import { LOCAL_STORAGE_KEYS, TariffOptions } from '../../../utils/constants';
import { QUERY_CONFIGURATION_LIST } from '../../../GraphQL/queries/getConfigurationList';
import { configurationValidationSchema } from '../../../utils/validationSchemes';

const SettingsCalculator = () => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [inputFieldName, setInputFieldName] = useState('');

  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );

  const [
    queryLazyGetConfigurationList,
    {
      data: dataQueryLazyGetConfigurationList,
      loading: isLoadingQueryLazyGetConfigurationList,
    },
  ] = useGetConfigurationListLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    },
  });

  const [mutationUpdateConfiguration, { loading: isLoadingUpdate }] =
    useUpdateConfigurationMutation({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error?.message, { variant: 'error' });
        }
      },
    });

  const configurationListData =
    dataQueryLazyGetConfigurationList?.getConfigurationList;

  useEffect(() => {
    queryLazyGetConfigurationList();
    // eslint-disable-next-line
  }, []);

  if (isLoadingQueryLazyGetConfigurationList)
    return <ShowLoadingText name={t('app.previousSettings')} />;
  if (!dataQueryLazyGetConfigurationList || !configurationListData) {
    return <BoxCentered>Нет данных</BoxCentered>;
  }

  const initialValues = {
    rateUSD: configurationListData?.rateUSD,
    wh_order_processing_cost_cases:
      configurationListData?.wh_order_processing_cost_cases,
    inbound_processing_cost_cases:
      configurationListData?.inbound_processing_cost_cases,
    undeclared_inbound_processing_cost_expression_pair:
      configurationListData?.undeclared_inbound_processing_cost_expression_pair,
    return_acceptance_cost: configurationListData?.return_acceptance_cost,
    wh_storage_cost_cases: configurationListData?.wh_storage_cost_cases,
    wh_order_processing_cost_insurance_pair:
      configurationListData?.wh_order_processing_cost_insurance_pair,
    wraper_cost_cases: configurationListData?.wraper_cost_cases,
    gift_message_cost: configurationListData?.gift_message_cost,
    gift_wraper_cost: configurationListData?.gift_wraper_cost,
    forwarding_acceptance_cost:
      configurationListData?.forwarding_acceptance_cost,
    current_app_currency: configurationListData?.current_app_currency,
  };

  const handleSubmit = (values: FormikValues) => {
    const input = Object.entries(values).map(([key, value]) => {
      let newValue = '';
      if (
        key === TariffOptions.UNDECLARED_INBOUND_PROCCESING_COST_EXPRESSION_PAIR
      ) {
        newValue = JSON.stringify([`${value[0]}${value[1]}`, value[1]]);
      } else if (key === TariffOptions.CURRENT_APP_CURRENCY) {
        newValue = value;
      } else {
        newValue = JSON.stringify(value);
      }
      return {
        key,
        value: newValue,
      };
    });

    mutationUpdateConfiguration({
      variables: {
        input,
      },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.settingsSaved'), { variant: 'success' });
          queryLazyGetConfigurationList();
        }
      },
      refetchQueries: [QUERY_CONFIGURATION_LIST],
    });
  };

  return (
    <Container>
      <Typography variant='h2' align='center' className={classes.title}>
        {t('app.tariffs')}
      </Typography>

      <Divider className={classes.titleDivider} />
      <br />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={configurationValidationSchema}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form noValidate autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.dollarExchangeRate')}
                    name='rateUSD'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>RUB</InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.wh_order_processing_cost_cases')}
                    name='wh_order_processing_cost_cases'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.forwarding_acceptance_cost')}
                    name='forwarding_acceptance_cost'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>
                        {CURRENT_APP_CURRENCY}
                      </InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.inbound_processing_cost_cases')}
                    name='inbound_processing_cost_cases'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DualInputField
                    classes={classes}
                    label={t(
                      'app.undeclared_inbound_processing_cost_expression_pair',
                    )}
                    name='undeclared_inbound_processing_cost_expression_pair'
                    inputProps={{
                      type: 'text',
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.return_acceptance_cost')}
                    name='return_acceptance_cost'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>
                        {CURRENT_APP_CURRENCY}
                      </InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.wh_storage_cost_cases')}
                    name='wh_storage_cost_cases'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.wh_order_processing_cost_insurance_pair')}
                    name='wh_order_processing_cost_insurance_pair'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.wraper_cost_cases')}
                    name='wraper_cost_cases'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    isButtonForOpenModal
                    setIsOpenModal={setIsOpenModal}
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.gift_message_cost')}
                    name='gift_message_cost'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>
                        {CURRENT_APP_CURRENCY}
                      </InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.gift_wraper_cost')}
                    name='gift_wraper_cost'
                    inputProps={{
                      type: 'number',
                      min: 0,
                      step: 0.1,
                    }}
                    startAdornment={
                      <InputAdornment position='end'>
                        {CURRENT_APP_CURRENCY}
                      </InputAdornment>
                    }
                    setModalName={setInputFieldName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputCalculator
                    classes={classes}
                    setFieldValue={setFieldValue}
                    label={t('app.сurrency')}
                    name='current_app_currency'
                    inputProps={{
                      type: 'text',
                      min: 3,
                    }}
                    setModalName={setInputFieldName}
                    isString
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    startIcon={
                      isLoadingUpdate ? (
                        <CircularProgress size={22} />
                      ) : (
                        <SaveIcon />
                      )
                    }
                  >
                    {t('app.save')}
                  </Button>
                </Grid>
              </Grid>
              <SettingsCalculatorModal
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                inputFieldName={inputFieldName}
              />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default SettingsCalculator;
