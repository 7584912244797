import React from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import useStyles from './styles';

interface ModalBoxProps {
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  children: React.ReactNode;
}

export default function ModalBox({ isOpen, setOpen, children }: ModalBoxProps) {
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      onClose={() => setOpen(false)}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.modalPaper}>{children}</div>
      </Fade>
    </Modal>
  );
}
