import React from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';

import { B2BShipmentPoint } from '../../generated/graphql';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Edit as EditIcon } from '@material-ui/icons';
import { COLORS } from '../../utils/constants';

const B2bShipmentPointsTableRowComponent = ({
  item,
  setIsB2bShipmentsPointCreateModal,
  setItemToEdit,
  setIsB2bShipmentsPointDeleteModal,
  setItemIdToDelete,
}: {
  item: B2BShipmentPoint;
  setItemToEdit: React.Dispatch<React.SetStateAction<B2BShipmentPoint>>;
  setItemIdToDelete: React.Dispatch<React.SetStateAction<number | null>>;
  setIsB2bShipmentsPointCreateModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsB2bShipmentsPointDeleteModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOpenEditModal = () => {
    setIsB2bShipmentsPointCreateModal(true);
    setItemToEdit(item);
  };

  const handleOpenDeleteModal = () => {
    setIsB2bShipmentsPointDeleteModal(true);
    setItemIdToDelete(Number(item?.id));
  };

  return (
    <TableRow>
      <TableCell align='center'>{item.name}</TableCell>
      <TableCell align='center'>{item.description}</TableCell>

      <TableCell align='center'>
        <BoxCentered>
          <Tooltip title={`${t('app.edit')} ${item.name}`}>
            <IconButton
              size='small'
              className={classes.btnIcon}
              onClick={handleOpenEditModal}
            >
              <EditIcon className={classes.linkIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('app.delete')}>
            <IconButton size='small' onClick={handleOpenDeleteModal}>
              <DeleteForeverIcon style={{ color: COLORS.RED }} />
            </IconButton>
          </Tooltip>
        </BoxCentered>
      </TableCell>
    </TableRow>
  );
};

export default B2bShipmentPointsTableRowComponent;
