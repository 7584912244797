import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { CONFIGURATION_LABELS } from '../../../utils/constants';
import { FastField, FieldProps, useFormikContext } from 'formik';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { ISettingsCalculatorModalOptionComponent } from './types';

const SettingsCalculatorModalOptionComponent = ({
  name,
  option,
  getInputProps,
}: ISettingsCalculatorModalOptionComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  return (
    <Box display='flex' flexDirection='row' style={{ gap: '20px' }}>
      <Box
        key={name}
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        <Typography>{t(CONFIGURATION_LABELS[option])}</Typography>

        <FastField name={name}>
          {({ field: { value, ...field }, meta }: FieldProps) => (
            <FormControl
              error={meta.touched && !!meta.error}
              className={classes.inputLabel}
            >
              <Input
                disableUnderline
                id={name}
                defaultValue={value}
                {...field}
                inputProps={getInputProps(option)}
                onChange={(event) => {
                  setFieldValue(name, +event.target.value);
                }}
              />
              {meta.touched && !!meta.error && (
                <FormHelperText>{meta.error}</FormHelperText>
              )}
            </FormControl>
          )}
        </FastField>
      </Box>
    </Box>
  );
};

export default SettingsCalculatorModalOptionComponent;
