import React, { useState } from 'react';
import { Button, FormHelperText, Input, InputLabel } from '@material-ui/core';
import ModalBox from '../../components/ModalBox/ModalBox';
// @ts-ignore
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {
  User,
  useUserDiscountManagementMutation,
} from '../../generated/graphql';
import { OptionsObject } from 'notistack';
import { useTranslation } from 'react-i18next';
import { GET_USER_DISCOUNT_QUERY } from '../../GraphQL/queries/getUserDiscount';
import { discountsEnums } from '../../utils/constants';

interface ModalDiscountProps {
  isDiscountModalOpen: boolean;
  setIsDiscountModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  discountUserId: React.ReactText;
  users?: User[];
  discountValue: string | number;
  setDiscountValueError: React.Dispatch<
    React.SetStateAction<number | null | string>
  >;
  setDiscountValue: React.Dispatch<React.SetStateAction<React.ReactText>>;
  discountValueError: number | string | null;
  classes: Record<string, string>;
  setDiscountUserId: React.Dispatch<React.SetStateAction<React.ReactText>>;
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined,
  ) => React.ReactText;
  setUsers?: React.Dispatch<React.SetStateAction<[] | User[]>>;
  isUser?: boolean;
  userName?: string;
  userRefetch?: () => void;
  isDiscount: boolean;
  discountProperty: string;
  refetch?: () => void;
}
export const ModalDiscount: React.FC<ModalDiscountProps> = ({
  isDiscountModalOpen,
  setIsDiscountModalOpen,
  discountUserId,
  users = [],
  discountValue,
  setDiscountValueError,
  setDiscountValue,
  discountValueError,
  classes,
  setDiscountUserId,
  enqueueSnackbar,
  setUsers = () => {},
  isUser = false,
  userName,
  userRefetch = () => {},
  isDiscount,
  discountProperty,
  refetch,
}) => {
  const { t } = useTranslation();

  const [setUserDiscountMutation] = useUserDiscountManagementMutation();

  const setDiscountHandler = () => {
    setUserDiscountMutation({
      variables: {
        userId: +discountUserId,
        discount: {
          [discountProperty]:
            discountProperty === 'creditLimit'
              ? discountValue
              : Number(discountValue) * 0.01,
        },
      },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(
            t('app.discountSetForClient', {
              client: `${discountUserId} ${
                !isUser
                  ? users.find((user) => +user.id === +discountUserId)?.name
                  : userName
              }`,
              discountValue,
            }),
            { variant: 'success' },
          );
          refetch && refetch();
          setIsDiscountModalOpen(false);
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [GET_USER_DISCOUNT_QUERY],
    });
  };

  return (
    <ModalBox isOpen={isDiscountModalOpen} setOpen={setIsDiscountModalOpen}>
      <InputLabel shrink={false} htmlFor={'input-actual-weight'}>
        {discountProperty === discountsEnums.CREDIT_LIMIT
          ? t('app.enterCreditLimit')
          : t('app.setDiscount')}
        <br />
        {t('app.forClientNumber')}
        {discountUserId} <br />
        {!isUser
          ? users.find((user) => +user.id === +discountUserId)?.name
          : userName}
      </InputLabel>
      <Input
        disableUnderline
        id={'input-actual-weight'}
        defaultValue={discountValue}
        inputProps={{
          type: 'number',
          min: 0,
          ...(discountProperty !== discountsEnums.CREDIT_LIMIT && { max: 100 }),
          step: 0.1,
        }}
        onChange={(e) => {
          if (discountProperty === discountsEnums.CREDIT_LIMIT) {
            setDiscountValue(Number(e.target.value));
            setDiscountValueError(null);
            return;
          }
          if (e.target.value.length) {
            Yup.object()
              .shape({
                discount: Yup.number().test({
                  test: (value: number) => {
                    return value >= 0 && value <= 100;
                  },
                }),
              })
              .validate({ discount: e.target.value })
              //@ts-ignore
              .then((valid: { discount: string }) => {
                setDiscountValueError(null);
                setDiscountValue(valid.discount);
              })
              .catch(() =>
                setDiscountValueError(
                  t('app.mustbeAnumberfromperiod', { from: 0, to: 100 }),
                ),
              );
          } else {
            setDiscountValue(e.target.value);
            setDiscountValueError(t('app.requiredField'));
          }
        }}
      />
      {!!discountValueError && (
        <FormHelperText className={classes.errorMsg}>
          {discountValueError}
        </FormHelperText>
      )}

      <div className={classes.boxModalButtons}>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<CloseIcon />}
          onClick={() => {
            setIsDiscountModalOpen(false);
            setDiscountUserId(0);
          }}
        >
          {t('app.close')}
        </Button>
        <Button
          variant='contained'
          startIcon={<SaveIcon />}
          onClick={setDiscountHandler}
        >
          {t('app.save')}
        </Button>
      </div>
    </ModalBox>
  );
};

export const useDiscount = () => {
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isDiscount, setIsDiscount] = useState(false);
  const [discountUserId, setDiscountUserId] = useState<string | number>(0);
  const [discountValue, setDiscountValue] = useState<string | number>(0);
  const [discountValueError, setDiscountValueError] = useState<
    number | string | null
  >(0);

  return {
    isDiscountModalOpen,
    setIsDiscountModalOpen,
    discountUserId,
    setDiscountUserId,
    discountValue,
    setDiscountValue,
    discountValueError,
    setDiscountValueError,
    isDiscount,
    setIsDiscount,
  };
};
