import { WarehouseShipmentStatus } from './../../../generated/graphql';

export const filterSelectItems = [
  { value: '', label: 'app.status' },
  { value: WarehouseShipmentStatus.Created, label: 'app.created' },
  {
    value: WarehouseShipmentStatus.Processing,
    label: 'app.receiving',
  },
  { value: WarehouseShipmentStatus.Accepted, label: 'app.accepted2' },
  { value: WarehouseShipmentStatus.Canceled, label: 'app.cancelled' },
  { value: WarehouseShipmentStatus.Expired, label: 'app.expired' },
  {
    value: WarehouseShipmentStatus.AcceptedWithDiscrepancy,
    label: 'app.acceptedWithDiscrepancy',
  },
];
