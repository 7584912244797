import { gql } from '@apollo/client';

export const QUERY_UNDECLARED_SHIPMENT_LIST = gql`
  query GetUndeclaredShipmentsList(
    $filter: UndeclaredShipmentFilter
    $search: String
  ) {
    getUndeclaredShipmentsList(filter: $filter, search: $search) {
      count
      rows {
        carrierCode
        cost
        createdAt
        id
        note
        quantityItems
        trackNumber
        warehouse
        warehouseStatus
        user {
          id
          name
        }
      }
    }
  }
`;
