import { gql } from '@apollo/client';

export const GET_B2B_SHIPMENT_SERVICES_QUERY = gql`
  query GetB2BShipmentServices($limit: Int, $offset: Int, $search: String) {
    getB2BShipmentServices(limit: $limit, offset: $offset, search: $search) {
      rows {
        id
        title
        description
        costInUSD
        code
        qty
      }
      count
    }
  }
`;
