import { MenuItem, SvgIconTypeMap, Tooltip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Link } from 'react-router-dom';

interface IMenuItemSelect {
  title: string;
  link: string;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  imgSrc?: string;
  target?: string;
  tooltipText?: string;
  onClick?: () => void;
}

const MenuItemSelect = ({
  link,
  title,
  Icon,
  tooltipText,
  imgSrc,
  onClick,
}: IMenuItemSelect) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MenuItem onClick={onClick}>
      <Tooltip title={t(tooltipText as string)}>
        <Link className={classes.person} to={link}>
          {!Icon ? (
            <img
              src={imgSrc}
              alt='icon_img '
              className={classes.personIcon}
              style={{ width: 24, height: 24 }}
            />
          ) : (
            Icon && <Icon className={classes.personIcon} />
          )}
          {t(title)}
        </Link>
      </Tooltip>
    </MenuItem>
  );
};

export default MenuItemSelect;
