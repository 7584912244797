import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import styles from './styles';
import { Link, NavLink, useParams } from 'react-router-dom';

import moment from 'moment';
import {
  useGetShipmentBarcodeListQuery,
  useGetWarehouseShipmentInfoQuery,
  Warehouse,
  WarehouseShipment,
} from '../../../generated/graphql';
import iconBarcode from '../../../assets/img/icon_barcode_button.svg';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  LOCAL_STORAGE_KEYS,
  warehouseStatuses,
} from '../../../utils/constants';
import ParcelDetailsContact from '../../../components/ParcelDetails/ParcelDetailsContact/ParcelDetailsContact';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import {
  GetCarrierCodeImage,
  ShowLoadingText,
} from '../../../utils/helperComponents';
import { TRoutes } from '../../../utils/helpers';
import { useSnackbar } from 'notistack';
import ParcelDetailsWarehouse from '../../../components/ParcelDetails/ParcelDetailsWarehouse/ParcelDetailsWarehouse';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';

const WarehouseShipmentDetail: React.FC = () => {
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMediaQueryXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );

  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetWarehouseShipmentInfoQuery({
    variables: {
      id: Number(id),
    },
  });
  const { data: barcodeListData } = useGetShipmentBarcodeListQuery({
    variables: { id },
  });

  const [warehouseShipment, setWarehouseShipment] =
    useState<WarehouseShipment | null>(null);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  useEffect(() => {
    data?.getWarehouseShipmentInfo &&
      //@ts-ignore
      setWarehouseShipment(data?.getWarehouseShipmentInfo);
  }, [data]);

  const expectedArrivalDate = moment(
    Number(warehouseShipment?.expectedArrivalDate),
  ).format(DATE_FORMAT);

  return (
    <>
      {!loading ? (
        <>
          <Box mb={4}>
            <Typography variant='h2' align='center'>
              {t('app.viewPP')}
              {warehouseShipment?.id}
            </Typography>
          </Box>
          <Grid container spacing={0} alignItems='stretch'>
            <Grid item sm={6} xs={12}>
              <List>
                <ListItem>
                  <Typography variant='h6'>{`${t('app.shipmentNumber')}${
                    warehouseShipment?.id
                  }`}</Typography>
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {t('app.shippingDate')}:{' '}
                  {moment(Number(warehouseShipment?.createdAt)).format(
                    DATE_FORMAT,
                  )}
                </ListItem>
                {expectedArrivalDate && (
                  <ListItem className={classes.itemInfo}>
                    {`${t('app.expectedDateArrival')}: ${expectedArrivalDate}`}
                  </ListItem>
                )}
                <ListItem className={classes.itemInfo}>
                  {t('app.orderClient')}:{'  '}
                  <Link
                    to={TRoutes.PAYMENT_STATISTICS_PER_USER.replace(
                      ':userId',
                      //@ts-ignore
                      warehouseShipment?.user?.id.toString(),
                    )}
                  >
                    {warehouseShipment?.user?.name}
                  </Link>
                </ListItem>
                {warehouseShipment?.carrierCode && (
                  <ListItem className={classes.itemInfo}>
                    {t('app.option')}:{' '}
                    {warehouseShipment?.carrierCode.toUpperCase()}
                  </ListItem>
                )}
                {warehouseShipment?.trackNumber &&
                  warehouseShipment?.carrierCode && (
                    <ListItem className={classes.itemInfo}>
                      {t('app.trackingNumber')}:{' '}
                      <BoxCentered>
                        <>
                          <GetCarrierCodeImage
                            carrierCode={warehouseShipment?.carrierCode}
                          />
                          <Tooltip
                            title={`Отследить отправление ${warehouseShipment?.carrierCode.toUpperCase()} ${
                              warehouseShipment?.trackNumber
                            }`}
                          >
                            <NavLink
                              className={classes.btnIcon}
                              to={`${TRoutes.TRACK}/${warehouseShipment?.carrierCode}/${warehouseShipment?.trackNumber}`}
                            >
                              <span>{warehouseShipment?.trackNumber}</span>
                            </NavLink>
                          </Tooltip>
                        </>
                      </BoxCentered>
                    </ListItem>
                  )}
                <ListItem className={classes.itemInfo}>
                  {t('app.deliveryStatus')}:{' '}
                  {warehouseShipment?.deliveryStatus?.explain}
                </ListItem>
              </List>
            </Grid>
            <Grid item sm={6} xs={12}>
              <List>
                <ListItem>
                  <Typography variant='h6'>
                    {t('app.warehouseStatus')}:{' '}
                    {t(
                      warehouseStatuses[
                        warehouseShipment?.warehouseStatus as string
                      ],
                    )}
                  </Typography>
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  <Box display={'flex'} flexDirection={'column'}>
                    {warehouseShipment?.events?.length
                      ? warehouseShipment?.events.map((e: any) => (
                          <Typography className={classes.itemInfo}>
                            {`${moment(Number(e?.date)).format(
                              DATE_TIME_FORMAT,
                            )} 
                            ${
                              e?.operation === 'ACCEPTANCE_END'
                                ? t('app.acceptanceEnd')
                                : t('app.accepted2')
                            } 
                            ${e.warehouse?.code}`}
                          </Typography>
                        ))
                      : ''}
                  </Box>
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <Grid container spacing={0} alignItems='stretch'>
            <Grid item sm={6} xs={12}>
              <ParcelDetailsContact
                type='sender'
                //@ts-ignore
                contact={warehouseShipment?.from}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <ParcelDetailsWarehouse
                type='receiver'
                contact={warehouseShipment?.warehouse as Warehouse}
              />
            </Grid>
          </Grid>
          <ListItem className={classes.itemInfo}>
            {t('app.numberOfPlaces')}: {warehouseShipment?.packages?.length}
          </ListItem>
          <ListItem className={classes.itemInfo}>
            {t('app.expectedAccepted')}:{' '}
            {warehouseShipment?.products?.length ? (
              <>
                {warehouseShipment?.products?.reduce(
                  //@ts-ignore
                  (acc, item) => acc + item?.quantity,
                  0,
                )}{' '}
                /{' '}
                {warehouseShipment?.products?.reduce((acc, item) => {
                  //@ts-ignore
                  return acc + item?.arrivalQuantity;
                }, 0)}
              </>
            ) : (
              <>
                {warehouseShipment?.expectedQuantity} /{' '}
                {warehouseShipment?.acceptedQuantity}
              </>
            )}
          </ListItem>
          {!!warehouseShipment?.cost && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.cost')}: ${
                warehouseShipment?.cost
              } ${CURRENT_APP_CURRENCY}`}
            </ListItem>
          )}
          {warehouseShipment?.packages?.map((pack, index) => (
            <ListItem className={classes.itemInfo}>
              {`${t('app.packageNumber')} ${index + 1}: ${pack?.width}x${
                pack?.length
              }x${pack?.height} ${t('app.cm')}, ${pack?.width} ${t('app.kg')}`}
            </ListItem>
          ))}
          {warehouseShipment?.comment && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.warehouseComment')}: ${warehouseShipment?.comment}`}
            </ListItem>
          )}
          {warehouseShipment?.pieces?.length ? (
            <>
              <Box pb={1} pt={1} pl={2}>
                <Typography variant='h6'>{t('app.orderList')}</Typography>
              </Box>

              <TableContainer>
                <Table className={classes.tableStyles}>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'></TableCell>
                      <TableCell align='center'>
                        {t('app.trackingNumberLabel')}
                      </TableCell>
                      <TableCell align='center'>{t('app.accepted2')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {warehouseShipment?.pieces?.map((piece, index) => {
                      return (
                        <TableRow>
                          <TableCell align='center'>{index + 1}</TableCell>
                          <TableCell align='center'>
                            <NavLink
                              to={`${TRoutes.TRACK}/null/${piece?.customIdentifier}`}
                            >
                              <span>{piece?.customIdentifier}</span>
                            </NavLink>
                          </TableCell>
                          <TableCell align='center'>
                            {piece?.accepted && <CheckIcon />}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}

          {warehouseShipment?.products?.length ? (
            <>
              <Box pb={1} pt={1} pl={2}>
                <Typography variant='h6'>{t('app.listOfItems')}</Typography>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>ID</TableCell>
                      <TableCell align='center'>SKU</TableCell>
                      <TableCell align='center'>{t('app.product')}</TableCell>
                      <TableCell align='center'>
                        {t('app.expectedAccepted')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {warehouseShipment?.products?.map((product) => {
                      return (
                        <TableRow>
                          <TableCell align='center'>
                            <Link
                              to={TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS.replace(
                                ':id',
                                //@ts-ignore
                                product?.product?.id?.toString(),
                              )}
                            >
                              {product?.product?.barcodeId?.substring(
                                0,
                                product?.product?.barcodeId.length - 4,
                              )}
                            </Link>
                          </TableCell>
                          <TableCell align='center'>
                            {product?.product?.sku}
                          </TableCell>
                          <TableCell align='center'>
                            {product?.product?.name}
                          </TableCell>
                          <TableCell align='center'>
                            {product?.quantity}&nbsp;/&nbsp;
                            {product?.arrivalQuantity}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
          <div className={classes.wrapButtons}>
            <Tooltip title='Открыть штрихкод'>
              <Button
                fullWidth={isMediaQueryXs}
                variant='contained'
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${warehouseShipment?.barcode}`,
                  );
                }}
              >
                <img
                  src={iconBarcode}
                  alt='barcode'
                  className={`${classes.actionIcon}`}
                  style={{ width: 20, height: 20 }}
                />
                {t('app.barcode')}
              </Button>
            </Tooltip>
            <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
            {warehouseShipment?.note === 'send products' ? (
              <Tooltip title={t('app.openProductBC')}>
                <Button
                  fullWidth={isMediaQueryXs}
                  variant='contained'
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${barcodeListData?.getShipmentBarcodeList}`,
                    );
                  }}
                >
                  <img
                    src={iconBarcode}
                    alt='barcode'
                    className={`${classes.actionIcon}`}
                    style={{ width: 20, height: 20 }}
                  />
                  {t('app.productBC')}
                </Button>
              </Tooltip>
            ) : null}
          </div>
        </>
      ) : (
        <BoxCentered>
          <ShowLoadingText name={(t('app.shipment') || '').toLowerCase()} />
        </BoxCentered>
      )}
    </>
  );
};

export default WarehouseShipmentDetail;
