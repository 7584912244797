import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  addWarehouseBtn: {
    margin: '30px 0 30px',
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
  addIcon: {
    fill: '#1464f6',
  },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  inputLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  },
}));
