import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    marginTop: 25,

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  shipmentItems: {
    marginBottom: 35,

    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },

  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonsForSelected: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    marginRight: '40px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
}));
