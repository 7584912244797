import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  inputLabelPackage: {
    maxWidth: '174px',
  },
  contactFieldsTitle: {
    marginBottom: 17,
    marginTop: 17,
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: 0,
    paddingTop: '10px',
  },
  radio: {
    padding: 0,
  },
  labelRowText: {
    fontWeight: 'bold',
    marginLeft: 10,
    marginRight: 10,
  },
  tooltipIconButton: {
    padding: 0,
  },
  errorsMessage: {
    color: '#f44336',
  },
}));
