import {
  TableContainer,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableHead,
  Input,
  Button,
  FormHelperText,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import {
  Add as AddIcon,
  DeleteForever as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons';

import {
  SortableContainer,
  SortableHandle,
  SortableElement,
} from 'react-sortable-hoc';
import useStyles from './styles';
import {
  UpdateWarehouseZone,
  WarehouseZone,
  useCreateWarehouseZoneMutation,
  useDeleteWarehouseZoneMutation,
  useGetWarehouseZonesQuery,
  useUpdateWarehouseZoneMutation,
} from '../../../generated/graphql';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { Field, FieldProps, Form, Formik } from 'formik';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { debounce } from 'debounce';
import { DEBOUNCE } from '../../../utils/constants';
import { createZoneSchema } from '../../../utils/validationSchemes';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { useSnackbar } from 'notistack';
import { GET_WAREHOUSE_ZONES } from '../../../GraphQL/queries/getWarehouseZones';
import { useTranslation } from 'react-i18next';

const Zones = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [zoneId, setZoneId] = useState('');
  const [zones, setZones] = useState<WarehouseZone[]>([]);
  const [isOpenCreateZone, setIsOpenCreateZone] = useState(false);
  const [isOpenRemoveWarehouseZone, setIsOpenRemoveWarehouseZone] =
    useState(false);

  const { t } = useTranslation();

  const { data: zonesData, loading: zonesLoading } = useGetWarehouseZonesQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const [createWarehouseZone, { loading }] = useCreateWarehouseZoneMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: (response) => {
      setIsOpenCreateZone(false);
      enqueueSnackbar(
        t('app.cellCreated', { value: response?.createWarehouseZone?.name }),
        {
          variant: 'success',
        },
      );
    },
    refetchQueries: [
      {
        query: GET_WAREHOUSE_ZONES,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteWarehouseZone, { loading: deleteWarehouseZoneLoading }] =
    useDeleteWarehouseZoneMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onCompleted: (response) => {
        enqueueSnackbar(t('app.zoneDeleted'), {
          variant: 'success',
        });
        setIsOpenRemoveWarehouseZone(false);
        setZoneId('');
      },
      refetchQueries: [
        {
          query: GET_WAREHOUSE_ZONES,
        },
      ],
      awaitRefetchQueries: true,
    });

  const [updateWarehouseZone, { loading: updateWarehouseZoneLoading }] =
    useUpdateWarehouseZoneMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onCompleted: (response) => {
        enqueueSnackbar(t('app.zoneUpdated'), {
          variant: 'success',
        });
      },
      refetchQueries: [
        {
          query: GET_WAREHOUSE_ZONES,
        },
      ],
      awaitRefetchQueries: true,
    });

  useEffect(() => {
    if (!zonesData) return;
    setZones(zonesData?.getWarehouseZones as WarehouseZone[]);
  }, [zonesData]);

  const createZoneHandler = (values: any) => {
    createWarehouseZone({ variables: { ...values } });
  };

  const deleteZoneHandler = (deleteWarehouseZoneId: string) => {
    deleteWarehouseZone({ variables: { deleteWarehouseZoneId } });
  };

  const updateZoneHandler = (
    updateWarehouseZoneId: string,
    data: UpdateWarehouseZone,
  ) => {
    updateWarehouseZone({
      variables: { updateWarehouseZoneId, data: { ...data } },
    });
  };

  //  Component which uses drag-n-drop activation when clicking inside the component
  const DragHandle = SortableHandle(() => (
    <span style={{ ...{ cursor: 'move' } }}>
      <DragIndicatorIcon />{' '}
    </span>
  ));

  // Universal component for turning a TableBody into a sortable container
  // @ts-ignore
  const TableBodySortable = SortableContainer((asd) => {
    return <TableBody>{asd.children}</TableBody>;
  });

  const renderField = (
    name: string,
    label: string,
    setFieldValue: TFormikSetFieldValueDebounce,
    disable?: boolean,
  ) => (
    <Field name={name}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <InputLabel
            className={classes.inputLabel}
            shrink={false}
            htmlFor={`${name}-input`}
          >
            {label}
          </InputLabel>
          <Input
            disabled={disable}
            disableUnderline
            id={`${name}-input`}
            {...field}
            defaultValue={value}
            onChange={(e) => {
              const name = e.target.name;
              const value = e.target.value;
              setFieldValue(name, value);
            }}
          />
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );

  const Row = SortableElement(({ data, onRemove }: any) => {
    return (
      <TableRow>
        <TableCell style={{ width: '5%' }}>
          {/* @ts-ignore */}
          <DragHandle />
        </TableCell>
        <TableCell>
          <Tooltip title={t('app.editNamePrompt')}>
            <Formik
              initialValues={{ name: data.name }}
              enableReinitialize
              onSubmit={createZoneHandler}
            >
              {({ values, setFieldValue, dirty }) => {
                return (
                  <Input
                    disabled={!!data.totalCellsCount}
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                    onBlur={() => {
                      if (dirty)
                        updateZoneHandler(data?.id?.toString() as string, {
                          name: values.name,
                        });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        updateZoneHandler(data?.id?.toString() as string, {
                          name: values.name,
                        });
                      }
                    }}
                  />
                );
              }}
            </Formik>
          </Tooltip>
        </TableCell>
        <TableCell>{data.totalCellsCount}</TableCell>
        <TableCell>{data.freeCellsCount}</TableCell>
        <TableCell>{data.occupiedCellsCount}</TableCell>
        <TableCell>
          <Tooltip title={`${t('app.delete')} ${data.name}`}>
            <IconButton
              disabled={!!data?.totalCellsCount}
              size='small'
              className={classes.btnIcon}
              onClick={() => {
                onRemove(data.id);
              }}
            >
              <DeleteIcon className={classes.linkIcon} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  });

  // @ts-ignore
  const onSortEnd = ({ oldIndex, newIndex }, e) => {
    const dragItem = zones[oldIndex];
    const dragTargetItem = zones[newIndex];
    if (oldIndex !== newIndex) {
      updateZoneHandler(dragItem?.id?.toString() as string, {
        orderNumber: dragTargetItem.orderNumber,
      });
    }
  };

  const onRemove = (index: any) => {
    setZoneId(index.toString() as string);
    setIsOpenRemoveWarehouseZone(true);
  };

  const isLoading = zonesLoading || updateWarehouseZoneLoading;

  return (
    <>
      <Typography variant='h2' align='center' className={classes.title}>
        {(t('app.warehouseZoning') || '').toUpperCase()}
      </Typography>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.zone')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.totalCells')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.free')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.occupied')}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {/* @ts-ignore */}
          <TableBodySortable onSortEnd={onSortEnd}>
            {/* @ts-ignore */}
            {zones.length ? (
              isLoading ? (
                <TableRow>
                  <TableCell colSpan={15}>
                    <ShowLoadingText name={'зон'} />
                  </TableCell>
                </TableRow>
              ) : (
                zones?.map((row, index) => {
                  return (
                    <>
                      {/* @ts-ignore */}

                      <Row
                        index={index}
                        key={row.id}
                        data={row}
                        onRemove={onRemove}
                      />
                    </>
                  );
                })
              )
            ) : (
              <TableRow>
                <TableCell colSpan={12} align='center'>
                  {t('app.noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBodySortable>
        </Table>
      </TableContainer>
      <Button
        variant='text'
        className={classes.addWarehouseBtn}
        startIcon={<AddIcon className={classes.addIcon} />}
        onClick={() => setIsOpenCreateZone(true)}
      >
        {t('app.zone')}
      </Button>
      <ModalBox isOpen={isOpenCreateZone} setOpen={setIsOpenCreateZone}>
        <Typography variant='h6'>{t('app.creatingZone')}</Typography>
        <Formik
          initialValues={{ name: '' }}
          enableReinitialize
          validationSchema={createZoneSchema()}
          onSubmit={createZoneHandler}
        >
          {({ setFieldValue }) => {
            const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);
            return (
              <Form>
                {renderField('name', t('app.name'), setFieldValueDebounce)}

                <div className={classes.boxModalButtons}>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      setIsOpenCreateZone(false);
                    }}
                  >
                    {t('app.close')}
                  </Button>
                  <Button variant='contained' type='submit' disabled={loading}>
                    {t('app.create')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBox>
      <ModalBox
        isOpen={isOpenRemoveWarehouseZone}
        setOpen={setIsOpenRemoveWarehouseZone}
      >
        <Typography variant='h6'>{t('app.confirmDeleteZone')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setIsOpenRemoveWarehouseZone(false);
              setZoneId('');
            }}
          >
            {t('app.close')}
          </Button>
          <Button
            disabled={deleteWarehouseZoneLoading}
            variant='contained'
            onClick={() => deleteZoneHandler(zoneId)}
          >
            {t('app.delete')}
          </Button>
        </div>
      </ModalBox>
    </>
  );
};

export default Zones;
