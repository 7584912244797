import { gql } from '@apollo/client';

export const GET_B2B_ORDER_LIST_QUERY = gql`
  query GetB2BOrderList($filter: B2BOrderListFilter!) {
    getB2BOrderList(filter: $filter) {
      rows {
        id
        client {
          name
          id
        }
        barcodeId
        externalOrdeId
        paymentStatus {
          explain
          id
          name
        }
        shipmentPointTitle
        productsQty
        warehouseName
        totalCost
        createdAt
        shipmentDate
        warehouseStatus
      }
      count
    }
  }
`;
