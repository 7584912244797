import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { COLORS } from '../../utils/constants';

interface IBox extends BoxProps {
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  color?: string;
}

export const BoxCentered: React.FC<IBox> = ({
  justifyContent,
  alignItems,
  color,
  children,
  ...props
}) => {
  return (
    <Box
      display='flex'
      alignItems={alignItems || 'center'}
      justifyContent={justifyContent || 'center'}
      color={color || COLORS.BLACK}
      {...props}
    >
      {children}
    </Box>
  );
};
