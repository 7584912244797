import { gql } from '@apollo/client';

export const GET_B2B_SHIPMENT_POINTS_QUERY = gql`
  query GetB2BShipmentPoints($limit: Int, $offset: Int) {
    getB2BShipmentPoints(limit: $limit, offset: $offset) {
      rows {
        description
        id
        name
      }
      count
    }
  }
`;
