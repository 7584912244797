import {
  SendWarehouseShipmentOrderInput,
  SendWarehouseShipmentOrderWithoutBuyLabelInput,
  Vatkey,
  WarehouseShipmentOrder,
} from '../../generated/graphql';

export const createWarehouseShipmentOrderInitValue = (
  warehouseShipmentOrder: WarehouseShipmentOrder | null,
  isWithLabel: boolean,
) => {
  const shipment: SendWarehouseShipmentOrderInput = {
    warehouseId: warehouseShipmentOrder?.sender?.id,
    additionalInsurance: warehouseShipmentOrder?.additionalInsurance,
    insuranceAmount: warehouseShipmentOrder?.insuranceAmount,
    signature: warehouseShipmentOrder?.signature,
    warehouseShipmentOrderId: warehouseShipmentOrder?.id as string,
    dimensions: {
      length: warehouseShipmentOrder?.dimensions?.length,
      width: warehouseShipmentOrder?.dimensions?.width,
      height: warehouseShipmentOrder?.dimensions?.height,
      weight: warehouseShipmentOrder?.dimensions?.weight,
    },
    products: warehouseShipmentOrder?.products?.map((product: any) => {
      return {
        id: +product.relatedProduct.id,
        quantity: product.quantity,
        price: product.price,
      };
    }),
    carrierService: warehouseShipmentOrder?.carrierService,
    shipmentId: warehouseShipmentOrder?.easyPostShipmentId as string,
    rateId: warehouseShipmentOrder?.easyPostRateId as string,
    typeDeliveryId: warehouseShipmentOrder?.typeDelivery?.id as number,
    wrap: warehouseShipmentOrder?.wrap,
    message: warehouseShipmentOrder?.message,
    giftWrap: warehouseShipmentOrder?.giftWrap,
    ...(warehouseShipmentOrder?.vatKey !== Vatkey.Empty && {
      vatKey: warehouseShipmentOrder?.vatKey,
    }),
    ...(warehouseShipmentOrder?.vatValue && {
      vatValue: warehouseShipmentOrder?.vatValue,
    }),
  };

  const shipmentWithoutLabel: SendWarehouseShipmentOrderWithoutBuyLabelInput = {
    additionalInsurance: warehouseShipmentOrder?.additionalInsurance,
    carrierService: warehouseShipmentOrder?.carrierService,
    currency: warehouseShipmentOrder?.currency,
    dimensions: {
      length: warehouseShipmentOrder?.dimensions?.length,
      width: warehouseShipmentOrder?.dimensions?.width,
      height: warehouseShipmentOrder?.dimensions?.height,
      weight: warehouseShipmentOrder?.dimensions?.weight,
    },
    giftWrap: warehouseShipmentOrder?.giftWrap,
    handlingFee: warehouseShipmentOrder?.handlingFee,
    insuranceAmount: warehouseShipmentOrder?.insuranceAmount,
    message: warehouseShipmentOrder?.message,
    products: warehouseShipmentOrder?.products?.map((product: any) => {
      return {
        id: +product.relatedProduct.id,
        quantity: product.quantity,
        price: product.price,
      };
    }),
    signature: warehouseShipmentOrder?.signature,
    ...(warehouseShipmentOrder?.vatKey !== 'EMPTY' && {
      vatKey: warehouseShipmentOrder?.vatKey,
    }),
    ...(warehouseShipmentOrder?.vatValue && {
      vatValue: warehouseShipmentOrder?.vatValue,
    }),
    warehouseId: warehouseShipmentOrder?.sender?.id,
    warehouseShipmentOrderId: warehouseShipmentOrder?.id as string,
    wrap: warehouseShipmentOrder?.wrap,
  };
  return isWithLabel ? shipment : shipmentWithoutLabel;
};
