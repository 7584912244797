import React from 'react';
import useStyles from './styles';
import { B2BOrder, B2BOrderProduct } from '../../../generated/graphql';
import TableList from '../../../components/TableList/TableList';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../utils/constants';
import { productsHeadCells } from './options';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { useFormikContext } from 'formik';

const createData = (tableRowData: B2BOrderProduct) => {
  const relatedProduct = tableRowData.relatedProduct;
  const titleId = relatedProduct?.barcodeId?.split('.')[0];
  return {
    id: tableRowData?.productId?.toString(),
    titleId,
    productSku: relatedProduct?.sku,
    productName: relatedProduct?.name,
    b2bProductsQuantity: (
      <BoxCentered>
        {tableRowData?.expectedQuantity === tableRowData?.acceptedQuantity && (
          <CheckCircleOutlineIcon
            style={{ color: COLORS.GREEN, marginRight: '8px' }}
          />
        )}
        {`${tableRowData?.expectedQuantity} / ${tableRowData?.acceptedQuantity}`}
      </BoxCentered>
    ),
  };
};

const B2bShipmentPackagingStep1 = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values } = useFormikContext<B2BOrder>();
  const { technicalTaskFileUrl, products, comment } = values;

  const rowsProducts =
    products?.map((tableRowData) =>
      createData(tableRowData as B2BOrderProduct),
    ) || [];
  return (
    <div className={classes.stepContent}>
      <Box className={classes.contentWrapper}>
        {rowsProducts.length ? (
          <TableList headCells={productsHeadCells} rows={rowsProducts} />
        ) : null}

        {!!comment ? (
          <Typography>{`${t('app.comment')}: ${comment}`}</Typography>
        ) : null}
        {technicalTaskFileUrl ? (
          <Tooltip title={t('app.goToTask')}>
            <Link
              className={classes.actionLink}
              to={`/api/getAttachment/${technicalTaskFileUrl}`}
              target='_blank'
            >
              <IconButton component='span' style={{ padding: 0 }}>
                <AttachFileIcon />
              </IconButton>
              {t('app.task')}
            </Link>
          </Tooltip>
        ) : null}
      </Box>
    </div>
  );
};

export default B2bShipmentPackagingStep1;
