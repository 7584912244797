import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { DELIVERY_STATUS_BY_ID } from '../../../utils/constants';
import { useGetStatusesListQuery } from '../../../generated/graphql';
import { IFilters } from '../../../utils/types';

interface IFilterSelectDeliveryStatus {
  value?: string | number;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectDeliveryStatus = ({
  value,
  setFilter,
}: IFilterSelectDeliveryStatus) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useGetStatusesListQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => {
          setFilter((state) => ({
            ...state,
            deliveryStatusId: Number(e.target.value),
            page: 1,
          }));
        }}
      >
        <MenuItem value='' disabled>
          {t('app.deliveryStatus')}
        </MenuItem>
        <MenuItem value={0}>{t('app.allDeliveryStatuses')}</MenuItem>
        {data?.getStatusesList?.DeliveryStatusList?.map((status) => {
          return status && status.id ? (
            <MenuItem key={status.id} value={status.id}>
              {t(DELIVERY_STATUS_BY_ID[Number(status.id)])}
            </MenuItem>
          ) : null;
        })}
      </Select>
    </FormControl>
  );
};

export default FilterSelectDeliveryStatus;
