import React from 'react';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ModalBox from '../../ModalBox/ModalBox';
import { FastField, FieldProps, Form, Formik, FormikProps } from 'formik';
import { useCreateOrEditB2BShipmentPointMutation } from '../../../generated/graphql';
import { createOrEditB2BShipmentPointSchema } from '../../../utils/validationSchemes';
import {
  IB2bShipmentsPointCreateModal,
  IB2bShipmentsPointValues,
} from './types';
import { useSnackbar } from 'notistack';
import { GET_B2B_SHIPMENT_POINTS_QUERY } from '../../../GraphQL/queries/getB2BShipmentPoints';

const B2bShipmentsPointCreateModal = ({
  isOpenB2bShipmentsPointCreateModal,
  setIsB2bShipmentsPointCreateModal,
  item: { id, name, description },
  setItemToEdit,
}: IB2bShipmentsPointCreateModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [createOrEditB2BShipmentPointMutation, { loading }] =
    useCreateOrEditB2BShipmentPointMutation();

  const handleSubmit = (values: IB2bShipmentsPointValues) => {
    createOrEditB2BShipmentPointMutation({
      variables: {
        ...values,
        ...(!!id && { createOrEditB2BShipmentPointId: id }),
      },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(
            !!id
              ? t('app.b2bShipmentsPointSuccessfullyChanged')
              : t('app.b2bShipmentsPointSuccessfullyCreated'),
            { variant: 'success' },
          );
          handleCloseModal();
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [GET_B2B_SHIPMENT_POINTS_QUERY],
    });
  };

  const handleCloseModal = () => {
    setIsB2bShipmentsPointCreateModal(false);
    setItemToEdit({ id: null, name: '', description: '' });
  };

  return (
    <ModalBox
      isOpen={isOpenB2bShipmentsPointCreateModal}
      setOpen={setIsB2bShipmentsPointCreateModal}
    >
      <Typography variant='h6'>
        {!!id
          ? t('app.editingb2bShipmentsPoint')
          : t('app.creatingb2bShipmentsPoint')}
      </Typography>
      <Formik
        initialValues={{
          name: name || '',
          description: description || '',
        }}
        onSubmit={handleSubmit}
        validationSchema={createOrEditB2BShipmentPointSchema}
      >
        {({ setFieldValue }: FormikProps<IB2bShipmentsPointValues>) => {
          return (
            <Form>
              <Box display='flex' flexDirection='column'>
                <FastField name='name'>
                  {({ field: { value, ...field }, meta }: FieldProps) => {
                    return (
                      <FormControl
                        className={classes.inputLabel}
                        error={meta.touched && !!meta.error}
                      >
                        <InputLabel>{t('app.name2')}</InputLabel>
                        <Input
                          disableUnderline
                          defaultValue={value}
                          {...field}
                          onChange={(e) =>
                            setFieldValue('name', e.target.value)
                          }
                        />

                        {meta.touched && meta.error && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                </FastField>
                <FastField name='description'>
                  {({ field: { value, ...field }, meta }: FieldProps) => {
                    return (
                      <FormControl
                        className={classes.inputLabel}
                        error={meta.touched && !!meta.error}
                      >
                        <InputLabel>{t('app.description')}</InputLabel>
                        <Input
                          disableUnderline
                          defaultValue={value}
                          {...field}
                          onChange={(e) =>
                            setFieldValue('description', e.target.value)
                          }
                        />

                        {meta.touched && meta.error && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                </FastField>
              </Box>
              <div className={classes.boxModalButtons}>
                <Button
                  variant='contained'
                  onClick={handleCloseModal}
                  color='secondary'
                >
                  {t('app.close')}
                </Button>
                <Button disabled={loading} type='submit' variant='contained'>
                  {!!id ? t('app.edit') : t('app.create')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ModalBox>
  );
};

export default B2bShipmentsPointCreateModal;
