import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { Add as AddIcon } from '@material-ui/icons';
import {
  DeliveryCountry,
  useCreateWarehouseMutation,
  useGetDeliveryCountriesLazyQuery,
  useGetWarehousesListQuery,
  useRemoveWarehouseMutation,
  useUpdateWarehouseMutation,
} from '../../../generated/graphql';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { Edit as EditIcon } from '@material-ui/icons';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  COLORS,
  DEBOUNCE,
  WAREHOUSE_OPERATIONS,
} from '../../../utils/constants';
import { useSnackbar } from 'notistack';
import { Unless } from 'react-if';
import PhoneInput from 'react-phone-number-input';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { FastField, Field, FieldProps, Form, Formik } from 'formik';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { debounce } from 'debounce';
import { initialWarehouseValues } from '../../../utils/helpers';
import { formWarehouseSchema } from '../../../utils/validationSchemes';
import {
  RenderCountryFlagImage,
  ShowLoadingText,
} from '../../../utils/helperComponents';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const WarehousesList: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [warehouseList, setWarehouseList] = useState<any[]>([]);

  const { t } = useTranslation();

  const [isOpenRemoveWarehouse, setIsOpenRemoveWarehouse] = useState(false);
  const [isOpenAddWarehouse, setIsOpenAddWarehouse] = useState(false);
  const [warehouseToEdit, setWarehouseToEdit] = useState<any>({});
  const [warehouseToRemove, setWarehouseToRemove] = useState<any>({});
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [countryItems, setCountryItems] = useState<DeliveryCountry[] | null>(
    null,
  );

  const { data, loading, error, refetch } = useGetWarehousesListQuery();

  const [
    queryLazyGetCountries,
    {
      data: dataQueryLazyCountries,
      loading: isLoadingQueryLazyCountries,
      error: errorQueryLazyCountries,
    },
  ] = useGetDeliveryCountriesLazyQuery();

  const [createWarehouseMutation, { loading: createWarehouseLoading }] =
    useCreateWarehouseMutation();
  const [removeWarehouseMutation, { loading: updateWarehouseLoading }] =
    useRemoveWarehouseMutation();
  const [updateWarehouseMutation, { loading: deleteWarehouseLoading }] =
    useUpdateWarehouseMutation();

  useEffect(() => {
    if (!data?.getWarehousesList?.rows) return;
    setWarehouseList(data?.getWarehousesList?.rows);
  }, [data]);

  useEffect(() => {
    queryLazyGetCountries();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataQueryLazyCountries?.deliveryCountries?.length) {
      setCountryItems(
        dataQueryLazyCountries.deliveryCountries as DeliveryCountry[],
      );
    }
  }, [dataQueryLazyCountries]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });
  errorQueryLazyCountries?.message &&
    enqueueSnackbar(errorQueryLazyCountries.message, { variant: 'error' });

  const handleCreateWarehouse = (input: any) => {
    setIsLoadingAction(true);
    delete input.country;
    createWarehouseMutation({
      variables: {
        input: {
          ...input,
          code: input.name,
        },
      },
    })
      .then((response) => {
        enqueueSnackbar(
          t('app.warehouseSuccessfullyCreated', {
            value: response?.data?.createWarehouse?.code,
          }),
          {
            variant: 'success',
          },
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        refetch();
        setIsOpenAddWarehouse(false);
        setIsLoadingAction(false);
      });
  };

  const handleUpdateWarehouse = (input: any, id: any) => {
    setIsLoadingAction(true);
    delete input.country;
    updateWarehouseMutation({
      variables: {
        id,
        input: {
          ...input,
          code: input.name,
        },
      },
    })
      .then((response) => {
        enqueueSnackbar(
          t('app.warehouseSuccessfullyChanged', {
            value: response?.data?.updateWarehouse?.code,
          }),
          {
            variant: 'success',
          },
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        refetch();
        setIsOpenAddWarehouse(false);
        setWarehouseToEdit({});
        setIsLoadingAction(false);
        setIsEditing(false);
      });
  };

  const handleRemoveWarehouse = (id: any) => {
    setIsLoadingAction(true);
    removeWarehouseMutation({
      variables: { id },
    })
      .then((response) => {
        enqueueSnackbar(
          t('app.warehouseSuccessfullyDeleted', {
            value: response?.data?.removeWarehouse?.code,
          }),
          {
            variant: 'success',
          },
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        refetch();
        setIsOpenRemoveWarehouse(false);
        setIsLoadingAction(false);
      });
  };

  const renderField = (
    name: string,
    label: string,
    setFieldValue: TFormikSetFieldValueDebounce,
    marginRight: string,
    disable?: boolean,
  ) => (
    <Field name={name}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl
          style={{ marginRight }}
          error={!!(meta.touched && meta.error)}
        >
          <InputLabel
            className={classes.inputLabel}
            shrink={false}
            htmlFor={`${name}-input`}
          >
            {label}
          </InputLabel>
          <Input
            disabled={disable}
            disableUnderline
            id={`${name}-input`}
            {...field}
            defaultValue={value}
            onChange={(e) => {
              const name = e.target.name;
              const value = e.target.value;
              setFieldValue(name, value);
            }}
          />
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );

  return (
    <>
      <Typography variant='h2' align='center'>
        {(t('app.allWarehouses') || '').toUpperCase()}
      </Typography>

      <Button
        variant='text'
        className={classes.addWarehouseBtn}
        startIcon={<AddIcon className={classes.addIcon} />}
        onClick={() => {
          setIsOpenAddWarehouse(true);
          setWarehouseToEdit({});
          setIsEditing(false);
        }}
      >
        {t('app.addWarehouse')}
      </Button>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>{t('app.warehouseName')}</TableCell>
              <TableCell>{t('app.services')}</TableCell>
              <TableCell>{t('app.managerName')}</TableCell>
              <TableCell>{t('app.address')}</TableCell>
              <TableCell align='center'>{t('app.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!data || loading || isLoadingAction) && (
              <TableRow>
                <TableCell colSpan={15}>
                  <ShowLoadingText name={t('app.warehouses')} />
                </TableCell>
              </TableRow>
            )}
            {!loading && !isLoadingAction && !!warehouseList.length
              ? warehouseList?.map((warehouse) => {
                  return (
                    <TableRow>
                      <TableCell>{warehouse.code}</TableCell>
                      <TableCell>
                        <Box>
                          {warehouse.availableOperation.map(
                            (operation: any) => (
                              <>
                                {t(WAREHOUSE_OPERATIONS[operation.operation])}
                                <br />
                              </>
                            ),
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>{warehouse.managerName}</TableCell>
                      <TableCell>
                        {warehouse?.company}
                        <br />
                        {warehouse?.address}
                        <br />
                        {warehouse?.city}, {warehouse?.zipCode},{' '}
                        {warehouse?.country?.nameEng}
                        <br />
                        {warehouse?.phone}
                      </TableCell>
                      <TableCell>
                        <BoxCentered>
                          <Tooltip
                            title={`${t('app.editWarehouse')} ${
                              warehouse?.code
                            }`}
                          >
                            <IconButton
                              size='small'
                              className={classes.btnIcon}
                              onClick={() => {
                                setWarehouseToEdit(warehouse);
                                setIsOpenAddWarehouse(true);
                                setIsEditing(true);
                              }}
                            >
                              <EditIcon className={classes.linkIcon} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title={`${t('app.deleteWarehouse')} ${
                              warehouse?.code
                            }`}
                          >
                            <IconButton
                              size='small'
                              onClick={() => {
                                setWarehouseToEdit(warehouse);
                                setWarehouseToRemove(warehouse);
                                setIsOpenRemoveWarehouse(true);
                              }}
                            >
                              <DeleteForeverIcon
                                style={{ color: COLORS.RED }}
                              />
                            </IconButton>
                          </Tooltip>
                        </BoxCentered>
                      </TableCell>
                    </TableRow>
                  );
                })
              : !!data &&
                !loading &&
                !isLoadingAction && (
                  <TableRow>
                    <TableCell colSpan={12} align='center'>
                      {t('app.noData')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalBox
        isOpen={isOpenRemoveWarehouse}
        setOpen={setIsOpenRemoveWarehouse}
      >
        <Typography variant='h6'>{t('app.confirmDeleteWarehouse')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setIsOpenRemoveWarehouse(false)}
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => handleRemoveWarehouse(warehouseToRemove.id)}
            disabled={deleteWarehouseLoading}
          >
            {t('app.delete')}
          </Button>
        </div>
      </ModalBox>

      <ModalBox isOpen={isOpenAddWarehouse} setOpen={setIsOpenAddWarehouse}>
        <Typography variant='h6'>
          {!isEditing ? t('app.addWarehouse') : t('app.editWarehouse')}
        </Typography>
        <Formik
          initialValues={initialWarehouseValues(warehouseToEdit)}
          enableReinitialize
          validationSchema={formWarehouseSchema()}
          onSubmit={(values) => {
            !isEditing
              ? handleCreateWarehouse(values)
              : handleUpdateWarehouse(values, warehouseToEdit.id);
          }}
        >
          {({ values, setFieldValue }) => {
            const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);
            return (
              <Form>
                <Box
                  style={{
                    width: '50%',
                    paddingRight: '20px',
                  }}
                >
                  {renderField(
                    'name',
                    t('app.warehouseName'),
                    setFieldValueDebounce,
                    '0px',
                  )}
                </Box>
                <Box
                  style={{
                    width: '50%',
                    paddingRight: '20px',
                    marginTop: '20px',
                  }}
                >
                  <Field name='operations'>
                    {({ field: { value, ...field }, meta }: FieldProps) => (
                      <FormControl>
                        <InputLabel id='operations-label'>
                          {t('app.services')}
                        </InputLabel>
                        <Select
                          labelId='operations-label'
                          id='operations'
                          multiple
                          value={value}
                          onChange={(e: React.ChangeEvent<typeof value>) =>
                            setFieldValue(
                              'operations',
                              typeof value === 'string'
                                ? e.target.value.split(',')
                                : e.target.value,
                            )
                          }
                          renderValue={(selected) =>
                            (selected as [])
                              .map((e) => t(WAREHOUSE_OPERATIONS[e]))
                              .join(', ')
                          }
                        >
                          {Object.entries(WAREHOUSE_OPERATIONS).map(
                            ([key, value]) => (
                              <MenuItem key={key} value={key}>
                                <Checkbox
                                  color='primary'
                                  checked={values?.operations?.some(
                                    (op: string) =>
                                      WAREHOUSE_OPERATIONS[op] === value,
                                  )}
                                />
                                <ListItemText primary={t(value)} />
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        {meta.touched && meta.error && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                  {renderField(
                    'managerName',
                    t('app.managerName'),
                    setFieldValueDebounce,
                    '40px',
                  )}
                  {renderField(
                    'company',
                    t('app.company'),
                    setFieldValueDebounce,
                    '0px',
                  )}
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                  <FastField name='phone'>
                    {({ field, meta }: any) => (
                      <FormControl className={classes.phoneInput}>
                        <InputLabel
                          shrink={false}
                          htmlFor={`phone-input-${field.name}`}
                        >
                          {t('app.phone')}
                        </InputLabel>
                        <PhoneInput
                          id={`phone-input-${field.name}`}
                          defaultCountry='US'
                          international
                          {...field}
                          onChange={(phone) => setFieldValue('phone', phone)}
                        />
                        <Unless
                          condition={
                            !meta.touched ||
                            isPossiblePhoneNumber(field.value || '')
                          }
                        >
                          <FormHelperText className={classes.phoneInputWarning}>
                            {t('app.warningCheckNumber')}
                          </FormHelperText>
                        </Unless>
                      </FormControl>
                    )}
                  </FastField>
                  {renderField('email', 'Email', setFieldValueDebounce, '0px')}
                </Box>
                <Box display={'flex'} flexDirection={'row'}>
                  {renderField(
                    'address',
                    t('app.address'),
                    setFieldValueDebounce,
                    '40px',
                  )}
                  {renderField(
                    'address2',
                    `${t('app.address')}2`,
                    setFieldValueDebounce,
                    '0px',
                  )}
                </Box>

                <Box display={'flex'} flexDirection={'row'}>
                  {renderField(
                    'city',
                    t('app.city'),
                    setFieldValueDebounce,
                    '20px',
                  )}
                  {renderField(
                    'state',
                    t('app.stateRegion'),
                    setFieldValueDebounce,
                    '20px',
                  )}
                  {renderField(
                    'zipCode',
                    t('app.index'),
                    setFieldValueDebounce,
                    '0px',
                  )}
                </Box>

                <FastField name='country'>
                  {({ field, meta }: any) => {
                    return (
                      <FormControl
                        style={{
                          width: '50%',
                          marginTop: '20px',
                          paddingRight: '20px',
                        }}
                        error={!!(meta.touched && meta.error)}
                      >
                        <InputLabel shrink={false} htmlFor='country-input'>
                          {t('app.country')}
                        </InputLabel>
                        <Autocomplete
                          id={`warehouse-country-input`}
                          disableClearable
                          options={countryItems as any}
                          getOptionLabel={(option) =>
                            `${option.name}${
                              option?.nameEng ? '/' + option?.nameEng : ''
                            }`
                          }
                          defaultValue={
                            isLoadingQueryLazyCountries
                              ? { name: t('app.loadCountries') }
                              : field?.value?.name
                              ? field?.value
                              : { name: t('app.selectCountry') }
                          }
                          renderOption={(country) => {
                            return (
                              <MenuItem key={country.name} value={country}>
                                <BoxCentered justifyContent='flex-start'>
                                  {country?.iso && (
                                    <RenderCountryFlagImage
                                      countryISO={country?.iso}
                                      countryName={country.name}
                                    />
                                  )}
                                  {country.name}/{country.nameEng}
                                </BoxCentered>
                              </MenuItem>
                            );
                          }}
                          onChange={(event, newValue) => {
                            setFieldValueDebounce('countryId', newValue.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id={`warehouse-country-input`}
                              {...field}
                              value={field?.value}
                            />
                          )}
                        />
                        {meta.touched && meta.error && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                </FastField>

                <div className={classes.boxModalButtons}>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      setIsOpenAddWarehouse(false);
                      setWarehouseToEdit({});
                      setIsEditing(false);
                    }}
                  >
                    {t('app.close')}
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={
                      isEditing
                        ? updateWarehouseLoading
                        : createWarehouseLoading
                    }
                  >
                    {!isEditing ? t('app.add') : t('app.change')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBox>
    </>
  );
};

export default WarehousesList;
