import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import SignIn from '../viewes/SingIn';

import { TRoutes } from '../utils/helpers';

export default function PublicRoutes() {
  return (
    <Switch>
      <Route path={TRoutes.AUTH_SIGN_IN} exact component={SignIn} />
      <Redirect from={TRoutes.MAIN} exact to={TRoutes.AUTH_SIGN_IN} />
      <Redirect from='*' to={TRoutes.MAIN} exact />
    </Switch>
  );
}
