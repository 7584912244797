import { gql } from '@apollo/client';

export const QUERY_EXCHANGE_RATE = gql`
  query getTheExchangeRate {
    getTheExchangeRate {
      exchangeRate {
        code
        nominal
        value
      }
      lastUpdate
    }
  }
`;
