import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import GetAppIcon from '@material-ui/icons/GetApp';
import EventIcon from '@material-ui/icons/Event';
import { ShowLoadingText } from '../../utils/helperComponents';
import { DatePicker } from '@material-ui/pickers';
import useStyles from './styles';
import ModalBox from '../../components/ModalBox/ModalBox';
import { Formik } from 'formik';
import {
  Report,
  ReportType,
  useCreateReportByFulfillmentMutation,
  useCreateReportByProductsMutation,
  useCreateReportByTransactionsMutation,
  useGetReportsLazyQuery,
  useGetWarehousesListQuery,
  Warehouse,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  LOCAL_STORAGE_KEYS,
  REPORT_TYPES,
} from '../../utils/constants';
import { LIMIT_ITEM_PAGE } from '../../utils/helpers';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { useTranslation } from 'react-i18next';

interface IReportsFilter {
  page: number;
  itemsLimit: number;
}

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

const Reports = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenReportsModal, setisOpenReportsModal] = useState(false);
  const [warehouseId, setwarehouseId] = useState(2);
  const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
  const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
  const [type, setType] = useState('');
  const [reports, setReports] = useState<Report[] | null>(null);

  const { t } = useTranslation();

  const [filter, setFilter] = useState<IReportsFilter>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const { data: warehouses, error: warehousesError } =
    useGetWarehousesListQuery();

  const [
    getReportsLazyQuery,
    {
      loading: isLoadingGetReports,
      error: getReportsError,
      data: getReportsData,
    },
  ] = useGetReportsLazyQuery({ fetchPolicy: 'network-only' });

  const [
    createReportByTransactions,
    {
      loading: isLoadingCreateReportByTransactions,
      error: createReportByTransactionsError,
    },
  ] = useCreateReportByTransactionsMutation();

  const [
    createReportByProducts,
    {
      loading: isLoadingCreateReportByProducts,
      error: createReportByProductsError,
    },
  ] = useCreateReportByProductsMutation();
  const [
    createReportByFulfillment,
    {
      loading: isLoadingCreateReportByFulfillment,
      error: createReportByFulfillmentError,
    },
  ] = useCreateReportByFulfillmentMutation();

  warehousesError?.message &&
    enqueueSnackbar(warehousesError.message, { variant: 'error' });
  createReportByTransactionsError?.message &&
    enqueueSnackbar(createReportByTransactionsError.message, {
      variant: 'error',
    });
  createReportByProductsError?.message &&
    enqueueSnackbar(createReportByProductsError.message, { variant: 'error' });
  createReportByFulfillmentError?.message &&
    enqueueSnackbar(createReportByFulfillmentError.message, {
      variant: 'error',
    });
  getReportsError?.message &&
    enqueueSnackbar(getReportsError.message, {
      variant: 'error',
    });

  useEffect(() => {
    if (!warehouses?.getWarehousesList?.rows) return;
    warehouses?.getWarehousesList?.rows &&
      setWarehouseList(warehouses?.getWarehousesList?.rows as Warehouse[]);
  }, [warehouses]);

  useEffect(() => {
    if (!getReportsData?.getReports?.rows) return;
    getReportsData?.getReports?.rows &&
      setReports(getReportsData?.getReports?.rows as Report[]);
  }, [getReportsData]);

  useEffect(() => {
    refetchReports();
    // eslint-disable-next-line
  }, [filter]);

  const getReportsText = (item: Report) => {
    if (item.type) {
      const report = t(REPORT_TYPES[item.type]);
      if (report) return report;
    }

    return t('app.unknownReportType');
  };

  const refetchReports = useDebouncedCallback(() => {
    getReportsLazyQuery({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit,
        limit: filter.itemsLimit,
      },
    });
  }, 400);

  const handleDownloadReport = (fileToken: any) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    if (!token) {
      enqueueSnackbar(t('app.unableToReadAuthToken'), {
        variant: 'error',
      });
      return;
    }

    fetch(`${process.env.REACT_APP_API_DOMAIN}/api/report/${fileToken}`, {
      headers: { authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (!response.ok) {
          enqueueSnackbar(response.statusText, { variant: 'error' });
          throw new Error(response.statusText);
        }

        return response.blob();
      })
      .then((response) => {
        let file = window.URL.createObjectURL(response);
        window.location.assign(file);
      })
      .catch((reason) => {
        reason?.message &&
          enqueueSnackbar(reason.message, { variant: 'error' });
      });
  };

  const handleCreateReport = (values: any) => {
    switch (type) {
      case 'TRANSACTIONS':
        delete values.warehouseId;
        delete values.productId;
        createReportByTransactions({ variables: { input: values } }).then(
          (res) => {
            enqueueSnackbar(t('app.reportSuccessfullyCreated'), {
              variant: 'success',
            });
          },
        );
        break;
      case 'PRODUCT':
        createReportByProducts({ variables: { input: values } }).then((res) => {
          enqueueSnackbar(t('app.reportSuccessfullyCreated'), {
            variant: 'success',
          });
        });
        break;
      case 'FULFILLMENT':
        delete values.productId;
        createReportByFulfillment({ variables: { input: values } }).then(
          (res) => {
            enqueueSnackbar(t('app.reportSuccessfullyCreated'), {
              variant: 'success',
            });
          },
        );
        break;

      default:
        break;
    }
    setisOpenReportsModal(false);
    refetchReports();
  };

  return (
    <>
      <Typography variant='h2' align='center'>
        {t('app.reports').toUpperCase()}
      </Typography>

      <div className={classes.buttonsForSelected}>
        <FormControl className={classes.filtersItemSelect}>
          <BoxCentered pl={2}>
            <span>{t('app.show')}</span>
            <Select
              displayEmpty
              disableUnderline
              value={filter.itemsLimit}
              onChange={(e) => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.ITEMS_LIMIT,
                  e.target.value as string,
                );
                setFilter(
                  (state) =>
                    ({
                      ...state,
                      itemsLimit: e.target.value,
                      page: 1,
                    } as typeof filter),
                );
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </BoxCentered>
        </FormControl>
        <Button
          variant='contained'
          onClick={() => {
            setisOpenReportsModal(true);
          }}
        >
          <LibraryBooksIcon className={classes.libraryIcon} />{' '}
          {t('app.createReport')}
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'> {t('app.created')}</TableCell>
              <TableCell align='center'> {t('app.reportType')}</TableCell>
              <TableCell align='center'> {t('app.timeRange')}</TableCell>
              <TableCell align='center'> {t('app.download')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!reports || isLoadingGetReports) && (
              <TableRow>
                <TableCell colSpan={4}>
                  <ShowLoadingText name='отчетов' />
                </TableCell>
              </TableRow>
            )}
            {reports && !isLoadingGetReports
              ? reports
                  .filter((report) => report.type !== ReportType.Shipments)
                  ?.map?.(
                    (item) =>
                      item && (
                        <TableRow>
                          <TableCell align='center'>
                            {moment(Number(item.createdAt)).format(
                              DATE_TIME_FORMAT,
                            )}
                          </TableCell>
                          <TableCell align='center'>
                            {getReportsText(item)}
                          </TableCell>
                          <TableCell align='center'>
                            {moment(Number(item.from)).format(DATE_FORMAT)} -{' '}
                            {moment(Number(item.to)).format(DATE_FORMAT)}
                          </TableCell>
                          <TableCell align='center'>
                            <Tooltip title={t('app.clickToDownloadReport')}>
                              <GetAppIcon
                                onClick={() => {
                                  handleDownloadReport(item.fileToken);
                                }}
                                className={classes.downloadIcon}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ),
                  )
              : !reports?.length &&
                !isLoadingGetReports && (
                  <TableRow>
                    <TableCell colSpan={4} align='center'>
                      {t('app.noRecords')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className={classes.pagination}
        count={
          getReportsData
            ? //@ts-ignore
              Math.ceil(getReportsData?.getReports?.count / filter.itemsLimit)
            : 1
        }
        page={filter.page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />
      <ModalBox isOpen={isOpenReportsModal} setOpen={setisOpenReportsModal}>
        <Typography variant='h6' align='center'>
          {t('app.createReport')}
        </Typography>
        <Typography style={{ marginBottom: '12px' }}>
          {t('app.reportType')}
        </Typography>
        <Formik
          initialValues={{
            from: new Date(),
            to: new Date(),
            productId: '',
            warehouseId: warehouseId,
          }}
          onSubmit={handleCreateReport}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            return (
              <>
                <Select
                  className={classes.typeSelect}
                  id={'type'}
                  value={type}
                  onChange={(e) => {
                    //@ts-ignore
                    setType(e.target.value);
                  }}
                >
                  {Object.entries(REPORT_TYPES).map(([key, value]) => {
                    return (
                      <MenuItem value={key} key={key}>
                        {t(value)}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Typography style={{ marginBottom: '12px' }}>
                  {t('app.period')}
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  style={{ marginBottom: '24px' }}
                >
                  <EventIcon fontSize='large' />
                  <DatePicker
                    className={classes.datePicker}
                    id='start_date'
                    value={values.from}
                    onChange={(date) => {
                      date && setFieldValue('from', date.toDate());
                    }}
                    {...{ maxDate: values.to }}
                    format='yyyy-MM-DD'
                  />
                  <Typography>{'-'}</Typography>

                  <DatePicker
                    id='to'
                    className={classes.datePicker}
                    value={values.to}
                    onChange={(date) => {
                      date && setFieldValue('to', date.toDate());
                    }}
                    {...{ maxDate: new Date() }}
                    format='yyyy-MM-DD'
                  />
                </Box>

                {type === 'PRODUCT' && (
                  <Box style={{ marginBottom: '24px' }}>
                    <InputLabel
                      className={classes.inputLabel}
                      shrink={false}
                      htmlFor={`productId-input`}
                    >
                      {'ID товара'}
                    </InputLabel>
                    <Input
                      className={classes.input}
                      disableUnderline
                      id={`productId-input`}
                      value={values.productId}
                      onChange={(e) => {
                        setFieldValue('productId', e.target.value);
                      }}
                    />
                  </Box>
                )}

                {(type === 'PRODUCT' || type === 'FULFILLMENT') && (
                  <FormControl variant='standard'>
                    <Typography style={{ marginBottom: '12px' }}>
                      {t('app.chooseWarehouse')}
                    </Typography>
                    <Select
                      className={classes.typeSelect}
                      id='warehouse'
                      value={
                        warehouse
                          ? warehouse
                          : warehouseList?.find(
                              (item) => item.id === warehouseId,
                            )
                      }
                      onChange={(e) => {
                        setWarehouse(e.target.value as Warehouse);
                        setwarehouseId(
                          Number((e.target.value as Warehouse).id),
                        );
                        setFieldValue(
                          'warehouseId',
                          Number((e.target.value as Warehouse).id),
                        );
                      }}
                    >
                      <MenuItem
                        value={t('app.selectWarehouse')}
                        disabled
                        style={{ display: 'none' }}
                      >
                        {t('app.selectWarehouse')}
                      </MenuItem>
                      {warehouseList?.map((item: any) => (
                        <MenuItem key={item.code} value={item}>
                          {item.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <div className={classes.boxModalButtons}>
                  <Button
                    style={{ marginRight: '24px' }}
                    variant='contained'
                    onClick={() => {
                      setisOpenReportsModal(false);
                    }}
                  >
                    {t('app.cancel')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={
                      isLoadingCreateReportByTransactions ||
                      isLoadingCreateReportByProducts ||
                      isLoadingCreateReportByFulfillment
                    }
                    startIcon={<GetAppIcon />}
                  >
                    {t('app.create')}
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </ModalBox>
    </>
  );
};

export default Reports;
