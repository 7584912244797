import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '14px',
    color: COLORS.LIGHT_GREY,
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '100%',
    cursor: 'pointer',
  },
  iconWrapper: {
    position: 'relative',
  },
  dot: {
    position: 'absolute',
    right: '15px',
    top: '4px',
    width: '11px',
    height: '11px',
    border: `2px solid ${COLORS.WHITE}`,
    borderRadius: '50%',
    backgroundColor: COLORS.BLACK,
  },
  personIcon: {
    color: COLORS.BLACK,
    marginRight: '5px',
    padding: '0px',
    height: '30px',
  },
}));
