import React, { useState } from 'react';
import { Button, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../utils/types';

const FilterInputSearch: React.FC<{
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}> = ({ setFilter }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };
  const startSearch = (e: any) => {
    e.preventDefault();

    setFilter((state: any) => ({
      ...state,
      search: search,
      page: 1,
    }));
  };

  return (
    <form className={classes.filtersSearch} onSubmit={startSearch}>
      <Input
        placeholder={t('app.enterText')}
        className={classes.filtersSearchField}
        onChange={handleChange}
        value={search}
        disableUnderline
      />
      <Button
        className={classes.filtersSearchSubmit}
        variant='contained'
        type='submit'
        aria-label='search'
      >
        <SearchIcon />
      </Button>
    </form>
  );
};

export default FilterInputSearch;
