import { makeStyles } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export default makeStyles((theme) => ({
  filtersSearch: {
    display: 'flex',
    marginLeft: 'auto',

    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filtersSearchField: {
    width: 162,
    height: 44,

    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filtersSearchSubmit: {
    backgroundColor: '#000000',
    padding: 0,
    minWidth: 'auto',
    width: 44,
    height: 44,
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
}));
