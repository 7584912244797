import { gql } from '@apollo/client';

export const getBalance = gql`
  query getBalance(
    $limit: Int
    $offset: Int
    $dateFrom: String
    $dateTo: String
  ) {
    getBalance(
      limit: $limit
      offset: $offset
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      count
      rows {
        id
        name
        email
        company
        income
        paymentParcel
        discrepancyInWeight
        balance
        indebtedness
      }
      totalIncome
      totalPaymentParcel
      totalDiscrepancyInWeight
      totalIndebtedness
      totalBalance
    }
  }
`;
