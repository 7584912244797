import {
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './styles';
import TableBodyRequestHandler from '../../components/TableBodyRequestHandler/TableRequestHandler';
import {
  LOCAL_STORAGE_KEYS,
  NOTIFICATIONS_TABLE_CELLS_ARRAY,
} from '../../utils/constants';
import NotificationsTableRowComponent from '../../components/NotificationsComponents/NotificationsTableRowComponent/NotificationsTableRowComponent';
import {
  Notification,
  useGetNotificationListLazyQuery,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import { IFilters } from '../../utils/types';
import { LIMIT_ITEM_PAGE } from '../../utils/helpers';
import PaginationComponent from '../../components/PaginationComponent/PaginationComponent';
import FilterSelectLimitItems from '../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';
import SendNotificationModal from '../../components/Modals/SendNotificationModal/SendNotificationModal';

const Notifications = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

  const [isOpenSendNotificationModal, setIsOpenSendNotificationModal] =
    useState(false);

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: itemsLimit ? Number(itemsLimit) : LIMIT_ITEM_PAGE,
  });

  const [getNotificationListLazyQuery, { data, loading }] =
    useGetNotificationListLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    getNotificationListLazyQuery({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit!,
        limit: filter.itemsLimit,
      },
    });
    // eslint-disable-next-line
  }, [filter]);

  const handltOpenSendNotificationModal = () => {
    setIsOpenSendNotificationModal(true);
  };

  return (
    <>
      <Typography variant='h2' align='center' className={classes.title}>
        {t('app.notifications')}
      </Typography>
      <div className={classes.filters}>
        <FilterSelectLimitItems
          value={filter.itemsLimit}
          setFilter={setFilter}
          localStorageItemsLimitKey={LOCAL_STORAGE_KEYS.ITEMS_LIMIT}
        />
        <Button
          className={classes.addButton}
          variant='contained'
          onClick={handltOpenSendNotificationModal}
          startIcon={<AddIcon />}
        >
          {t('app.create')}
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {NOTIFICATIONS_TABLE_CELLS_ARRAY.map((text) => (
                <TableCell key={text} align='center' rowSpan={2}>
                  {t(text)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={data?.getNotificationList?.rows?.length as number}
              colSpan={NOTIFICATIONS_TABLE_CELLS_ARRAY.length}
              loading={loading}
            >
              {data?.getNotificationList?.rows?.length
                ? data?.getNotificationList?.rows?.map((item) => (
                    <NotificationsTableRowComponent
                      key={item?.sendAt}
                      item={item as Notification}
                    />
                  ))
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={data?.getNotificationList?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />

      <SendNotificationModal
        isOpenSendNotificationModal={isOpenSendNotificationModal}
        setIsOpenSendNotificationModal={setIsOpenSendNotificationModal}
      />
    </>
  );
};

export default Notifications;
