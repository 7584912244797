import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  authTitle: {
    marginBottom: 20,
  },
  formControl: {
    marginBottom: 24,
  },
  rememberMe: {
    marginBottom: 30,
  },
  signButton: {
    marginBottom: 22,
  },
}));
