import {
  TableContainer,
  TableRow,
  Typography,
  TableCell,
  TableBody,
  Table,
  Paper,
  TableHead,
  Input,
  Button,
  FormHelperText,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import {
  Add as AddIcon,
  DeleteForever as DeleteIcon,
  Search as SearchIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons';

import {
  SortableContainer,
  SortableHandle,
  SortableElement,
} from 'react-sortable-hoc';
import useStyles from './styles';
import {
  UpdateWarehouseZoneCell,
  WarehouseZone,
  WarehouseZoneCell,
  WarehouseZoneCellsResult,
  useCreateWarehouseZoneCellMutation,
  useDeleteWarehouseZoneCellMutation,
  useGetWarehouseZoneCellsLazyQuery,
  useGetWarehouseZonesQuery,
  useUpdateWarehouseZoneCellMutation,
  useUploadWarehouseZoneCellsMutation,
} from '../../../generated/graphql';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { Field, FieldProps, Form, Formik } from 'formik';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { debounce } from 'debounce';
import { DEBOUNCE, LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import { createZoneSchema } from '../../../utils/validationSchemes';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { useSnackbar } from 'notistack';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { LIMIT_ITEM_PAGE } from '../../../utils/helpers';
import { GET_WAREHOUSE_ZONE_CELLS } from '../../../GraphQL/queries/getWarehouseZoneCells';
import { Pagination } from '@material-ui/lab';
import { GET_WAREHOUSE_ZONES } from '../../../GraphQL/queries/getWarehouseZones';
import { useTranslation } from 'react-i18next';

interface IWarehouseZoneCellFilter {
  page: number;
  itemsLimit: number;
  search: string;
}

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

const Cells = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [cellId, setCellId] = useState('');
  const [cells, setCells] = useState<WarehouseZoneCellsResult | null>(null);
  const [zones, setZones] = useState<WarehouseZone[]>([]);
  const [isOpenCreateCell, setIsOpenCreateCell] = useState(false);
  const [isOpenRemoveWarehouseCell, setIsOpenRemoveWarehouseCell] =
    useState(false);
  const [filter, setFilter] = useState<IWarehouseZoneCellFilter>({
    page: 1,
    search: '',
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const { t } = useTranslation();

  const [
    getWarehouseZoneCellsLazyQuery,
    { data: cellsData, loading: cellsLoading },
  ] = useGetWarehouseZoneCellsLazyQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const { loading: zonesLoading } = useGetWarehouseZonesQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onCompleted: (response) => {
      setZones(response?.getWarehouseZones as WarehouseZone[]);
    },
  });

  const [createWarehouseZoneCell, { loading: createCellLoading }] =
    useCreateWarehouseZoneCellMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onCompleted: (response) => {
        enqueueSnackbar(
          t('app.cellCreated', {
            value: response?.createWarehouseZoneCell?.name,
          }),
          {
            variant: 'success',
          },
        );
        setIsOpenCreateCell(false);
      },
      refetchQueries: [
        {
          query: GET_WAREHOUSE_ZONE_CELLS,
        },
        {
          query: GET_WAREHOUSE_ZONES,
        },
      ],
      awaitRefetchQueries: true,
    });

  const [deleteWarehouseZoneCell, { loading: deleteWarehouseZoneCellLoading }] =
    useDeleteWarehouseZoneCellMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onCompleted: () => {
        setIsOpenRemoveWarehouseCell(false);
        setCellId('');
        enqueueSnackbar(t('app.cellDeleted'), {
          variant: 'success',
        });
      },
      refetchQueries: [
        {
          query: GET_WAREHOUSE_ZONE_CELLS,
        },
        {
          query: GET_WAREHOUSE_ZONES,
        },
      ],
      awaitRefetchQueries: true,
    });

  const [updateWarehouseZoneCell, { loading: updateCellLoading }] =
    useUpdateWarehouseZoneCellMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onCompleted: () => {
        enqueueSnackbar(t('app.cellUpdated'), {
          variant: 'success',
        });
      },
      refetchQueries: [
        {
          query: GET_WAREHOUSE_ZONE_CELLS,
          variables: {
            search: filter.search,
            offset: (filter.page - 1) * filter.itemsLimit,
            limit: filter.itemsLimit,
          },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [uploadCellsList, { loading: uploadCellsListLoading }] =
    useUploadWarehouseZoneCellsMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onCompleted: (response) => {
        enqueueSnackbar(t('app.cellUpdated'), {
          variant: 'success',
        });
      },
      refetchQueries: [
        {
          query: GET_WAREHOUSE_ZONE_CELLS,
          variables: {
            search: filter.search,
            offset: (filter.page - 1) * filter.itemsLimit,
            limit: filter.itemsLimit,
          },
        },
      ],
      awaitRefetchQueries: true,
    });

  useEffect(() => {
    if (!cellsData) return;
    setCells(cellsData?.getWarehouseZoneCells as WarehouseZoneCellsResult);
  }, [cellsData]);

  useEffect(() => {
    getWarehouseZoneCellsLazyQuery({
      variables: {
        search: filter.search,
        offset: (filter.page - 1) * filter.itemsLimit,
        limit: filter.itemsLimit,
      },
    });
    // eslint-disable-next-line
  }, [filter]);

  const createZoneCellHandler = (values: any) => {
    createWarehouseZoneCell({ variables: { data: { ...values } } });
  };

  const deleteZoneCellHandler = (deleteWarehouseZoneCellId: string) => {
    deleteWarehouseZoneCell({ variables: { deleteWarehouseZoneCellId } });
  };

  const updateZoneCellHandler = (
    updateWarehouseZoneCellId: string,
    data: UpdateWarehouseZoneCell,
  ) => {
    updateWarehouseZoneCell({
      variables: { updateWarehouseZoneCellId, data: { ...data } },
    });
  };

  const selectFileUpload = () => {
    let fileInput = document.getElementById('inputUploadFile');
    if (!fileInput) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('id', 'inputUploadFile');
      fileInput.setAttribute(
        'accept',
        '.xls,.xlsx,.xlsm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12',
      );
      fileInput.style.visibility = 'hidden';
      document.body.append(fileInput);
      //@ts-ignore
      fileInput.addEventListener('change', uploadCellsListFile, false);
      //@ts-ignore
      fileInput.type = 'file';
    }
    fileInput.click();
  };

  const uploadCellsListFile = ({
    target: {
      //@ts-ignore
      validity,
      //@ts-ignore
      files: [file],
    },
  }) => {
    let dataArray = new FormData();
    const inputUploadFile = document.getElementById('inputUploadFile');
    //@ts-ignore
    if (inputUploadFile?.files?.length) {
      //@ts-ignore
      dataArray.append('file', inputUploadFile.files[0]);

      validity.valid &&
        uploadCellsList({
          variables: {
            file: file,
          },
        });
      inputUploadFile.remove();
    }
  };

  const DragHandle = SortableHandle(() => (
    <span style={{ ...{ cursor: 'move' } }}>
      <DragIndicatorIcon />
    </span>
  ));

  const TableBodySortable = SortableContainer(({ children }: any) => (
    <TableBody>{children}</TableBody>
  ));

  const renderField = (
    name: string,
    label: string,
    setFieldValue: TFormikSetFieldValueDebounce,
    disable?: boolean,
  ) => (
    <Field name={name}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <InputLabel
            className={classes.inputLabel}
            shrink={false}
            htmlFor={`${name}-input`}
          >
            {label}
          </InputLabel>
          <Input
            disabled={disable}
            disableUnderline
            id={`${name}-input`}
            {...field}
            defaultValue={value}
            onChange={(e) => {
              const name = e.target.name;
              const value = e.target.value;
              setFieldValue(name, value);
            }}
          />
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );

  const Row = SortableElement(
    ({ data, ...other }: { data: WarehouseZoneCell }) => {
      return (
        <>
          <TableRow>
            <TableCell style={{ width: '5%' }}>
              {/* @ts-ignore */}
              <DragHandle />
            </TableCell>
            <TableCell>
              <Formik
                initialValues={{ name: data.name }}
                enableReinitialize
                onSubmit={createZoneCellHandler}
              >
                {({ values, setFieldValue, dirty }) => {
                  return (
                    <Input
                      value={values.name}
                      disabled={!!data.totalProductsCount}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      onBlur={() => {
                        if (dirty)
                          updateZoneCellHandler(
                            data?.id?.toString() as string,
                            {
                              name: values.name,
                            },
                          );
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          updateZoneCellHandler(
                            data?.id?.toString() as string,
                            {
                              name: values.name,
                            },
                          );
                        }
                      }}
                    />
                  );
                }}
              </Formik>
            </TableCell>
            <TableCell>
              <FormControl>
                <Select
                  value={data.warehouseZoneId}
                  disabled={!!data?.totalProductsCount}
                  onChange={(e) => {
                    updateZoneCellHandler(data?.id?.toString() as string, {
                      warehouseZoneId: e.target.value as number,
                    });
                  }}
                >
                  {zones?.map((zone: any) => (
                    <MenuItem key={zone.id} value={zone.id}>
                      {zone.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              {data.totalProductsCount ? t('app.occupied') : t('app.free')}
            </TableCell>
            <TableCell>
              <Tooltip title={`${t('app.delete')} ${data.name}`}>
                <IconButton
                  disabled={!!data?.totalProductsCount}
                  size='small'
                  className={classes.btnIcon}
                  onClick={() => {
                    setCellId(data?.id?.toString() as string);
                    setIsOpenRemoveWarehouseCell(true);
                  }}
                >
                  <DeleteIcon className={classes.linkIcon} />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell align='center'>
              <Select value='Действие'>
                <MenuItem value='Действие' disabled style={{ display: 'none' }}>
                  {t('app.action')}
                </MenuItem>

                <MenuItem
                  disableGutters
                  divider
                  className={classes.actionMenuItem}
                >
                  <Tooltip title={t('app.clickToOpenBarcode')}>
                    <a
                      href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${data?.barcode}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.actionLink}
                    >
                      <img
                        src={iconBarcode}
                        alt='barcode'
                        className={`${classes.actionIcon}`}
                        style={{ width: 20, height: 20 }}
                      />
                      {t('app.barcode')}
                    </a>
                  </Tooltip>
                </MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </>
      );
    },
  );

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const dragItem = cells?.rows?.[oldIndex];
    const dragTargetItem = cells?.rows?.[newIndex];
    if (oldIndex !== newIndex) {
      updateZoneCellHandler(dragItem?.id?.toString() as string, {
        orderNumber: dragTargetItem?.orderNumber,
      });
    }
  };

  const isLoading = cellsLoading || zonesLoading || updateCellLoading;

  return (
    <>
      <Typography variant='h2' align='center' className={classes.title}>
        {(t('app.cellAddresses') || '').toUpperCase()}
      </Typography>

      <div className={classes.filters}>
        <Button
          variant='contained'
          className={classes.loadButton}
          onClick={selectFileUpload}
          startIcon={uploadCellsListLoading && <CircularProgress size={22} />}
          disabled={uploadCellsListLoading}
        >
          {t('app.downloadDef')}
        </Button>
        <FormControl className={classes.filtersItemSelect}>
          <BoxCentered pl={2}>
            <span>{t('app.show')}</span>
            <Select
              displayEmpty
              disableUnderline
              value={filter.itemsLimit}
              onChange={(e) => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.ITEMS_LIMIT,
                  //@ts-ignore
                  e.target.value,
                );
                //@ts-ignore
                setFilter((state) => ({
                  ...state,
                  itemsLimit: e.target.value,
                  page: 1,
                }));
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </BoxCentered>
        </FormControl>
        <form
          className={classes.filtersSearch}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            placeholder={t('app.enterText')}
            className={classes.filtersSearchField}
            onChange={(e) => {
              setFilter((state) => ({
                ...state,
                search: e.target.value,
                page: 1,
              }));
            }}
            value={filter.search}
            disableUnderline
          />
          <Button
            className={classes.filtersSearchSubmit}
            variant='contained'
            type='submit'
            aria-label='search'
          >
            <SearchIcon />
          </Button>
        </form>
      </div>

      <Button
        variant='text'
        className={classes.addCellBtn}
        startIcon={<AddIcon className={classes.addIcon} />}
        onClick={() => setIsOpenCreateCell(true)}
      >
        {t('app.cell')}
      </Button>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.cell')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.belongsToZone')}
              </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.status')}
              </TableCell>
              <TableCell> </TableCell>
              <TableCell align='center' rowSpan={2}>
                {t('app.action')}
              </TableCell>
            </TableRow>
          </TableHead>
          {/* @ts-ignore */}
          <TableBodySortable onSortEnd={onSortEnd}>
            {cells?.rows?.length ? (
              isLoading ? (
                <TableRow>
                  <TableCell colSpan={15}>
                    <ShowLoadingText name={'зон'} />
                  </TableCell>
                </TableRow>
              ) : (
                cells?.rows?.map((row, index) => {
                  // @ts-ignore
                  return <Row index={index} key={row.id} data={row} />;
                })
              )
            ) : (
              <TableRow>
                <TableCell colSpan={12} align='center'>
                  {t('app.noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBodySortable>
        </Table>
      </TableContainer>
      <Pagination
        className={classes.pagination}
        count={cells?.count ? Math.ceil(cells?.count / filter?.itemsLimit) : 1}
        page={filter.page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />

      <ModalBox isOpen={isOpenCreateCell} setOpen={setIsOpenCreateCell}>
        <Typography variant='h6'> {t('app.creatingCell')}</Typography>
        <Formik
          initialValues={{ name: '', warehouseZoneId: '' }}
          enableReinitialize
          validationSchema={createZoneSchema()}
          onSubmit={createZoneCellHandler}
        >
          {({ values, setFieldValue }) => {
            const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);
            return (
              <Form>
                {renderField('name', t('app.name'), setFieldValueDebounce)}
                <FormControl variant='standard'>
                  <InputLabel
                    className={classes.inputLabel}
                    htmlFor='warehouseZoneId'
                  >
                    {t('app.selectZone')}
                  </InputLabel>
                  <Select
                    id='warehouseZoneId'
                    value={values.warehouseZoneId}
                    onChange={(e) => {
                      setFieldValue(
                        'warehouseZoneId',
                        e.target.value as number,
                      );
                    }}
                  >
                    {zones?.map((zone: any) => (
                      <MenuItem key={zone.id} value={zone.id}>
                        {zone.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className={classes.boxModalButtons}>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      setIsOpenCreateCell(false);
                    }}
                  >
                    {t('app.close')}
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={createCellLoading}
                  >
                    {t('app.create')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBox>
      <ModalBox
        isOpen={isOpenRemoveWarehouseCell}
        setOpen={setIsOpenRemoveWarehouseCell}
      >
        <Typography variant='h6'>{t('app.confirmDeleteCell')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setIsOpenRemoveWarehouseCell(false);
              setCellId('');
            }}
          >
            {t('app.close')}
          </Button>
          <Button
            disabled={deleteWarehouseZoneCellLoading}
            variant='contained'
            onClick={() => deleteZoneCellHandler(cellId)}
          >
            {t('app.delete')}
          </Button>
        </div>
      </ModalBox>
    </>
  );
};

export default Cells;
