import { colorsEnum } from '../../../utils/constants';
import { TRoutes } from '../../../utils/helpers';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  UndeclaredShipmentListItem,
  WarehouseShipmentStatus,
} from '../../../generated/graphql';

export const getActionOptions = (
  item: UndeclaredShipmentListItem | null,
  openModalForCancelHandler: () => void,
) => [
  {
    link: `${
      TRoutes.UNDECLARED_ARRIVAL_DETAILS_WITHOUT_ID
    }${item?.id?.toString()}`,
    Icon: SearchIcon,
    title: 'app.view',
    tooltipText: 'app.clickToOpenViewShipments',
  },
  {
    link: `#`,
    Icon: CancelIcon,
    title: 'app.cancel',
    onClick: openModalForCancelHandler,
    color: colorsEnum.RED,
    disabled: item?.warehouseStatus !== WarehouseShipmentStatus.Created,
    tooltipText: 'app.clickToCancelOrder',
  },
];
