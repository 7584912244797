import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    '& .MuiBox-root': {
      padding: '0px',
    },
  },
  tabs: {
    display: 'contents',
    width: '480px',
  },
  mainTitle: {
    marginBottom: 32,
    textTransform: 'uppercase',

    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      marginBottom: 15,
    },
  },
}));

export default styles;
