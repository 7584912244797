import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { Add as AddIcon } from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Edit as EditIcon } from '@material-ui/icons';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { ManagerRoles } from '../../generated/graphql';
import { ShowLoadingText } from '../../utils/helperComponents';
import { COLORS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

const AddManagerTable: React.FC<{
  setIsOpenAddWarehouseManager: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setRole: React.Dispatch<React.SetStateAction<ManagerRoles | null>>;
  setWarehouseManagerToEdit: React.Dispatch<any>;
  setWarehouseManagerToRemove: React.Dispatch<any>;
  setIsOpenRemoveWarehouse: React.Dispatch<any>;
  loading: boolean;
  isLoadingAction: boolean;
  role: ManagerRoles;
  warehouseManagersList: any[];
}> = ({
  setIsOpenAddWarehouseManager,
  setIsEditing,
  setRole,
  setWarehouseManagerToEdit,
  setWarehouseManagerToRemove,
  setIsOpenRemoveWarehouse,
  loading,
  isLoadingAction,
  role,
  warehouseManagersList,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant='text'
        className={classes.addWarehouseBtn}
        startIcon={<AddIcon className={classes.addIcon} />}
        onClick={() => {
          setIsOpenAddWarehouseManager(true);
          setIsEditing(false);
          setRole(role as ManagerRoles);
        }}
      >
        {role === 'ADMIN'
          ? t('app.addAdministrator')
          : t('app.addWarehouseEmployee')}
      </Button>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                {role === 'ADMIN'
                  ? t('app.administrator')
                  : t('app.warehouseEmployee')}
              </TableCell>
              <TableCell>{t('app.warehouseName')}</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell align='center'>{t('app.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!warehouseManagersList || loading || isLoadingAction) && (
              <TableRow>
                <TableCell colSpan={15}>
                  <ShowLoadingText name={t('app.managers')} />
                </TableCell>
              </TableRow>
            )}
            {!loading && !isLoadingAction && !!warehouseManagersList.length
              ? warehouseManagersList
                  ?.filter((manager) => manager.role === role)
                  .map((warehouseManager) => {
                    return (
                      <TableRow>
                        <TableCell>{warehouseManager.name}</TableCell>
                        <TableCell>{warehouseManager.warehouse.code}</TableCell>
                        <TableCell>{warehouseManager.email}</TableCell>
                        <TableCell>
                          <BoxCentered>
                            <Tooltip
                              title={`${t('app.edit')} ${
                                warehouseManager.name
                              }`}
                            >
                              <IconButton
                                size='small'
                                className={classes.btnIcon}
                                onClick={() => {
                                  setWarehouseManagerToEdit(warehouseManager);
                                  setIsOpenAddWarehouseManager(true);
                                  setIsEditing(true);
                                  setRole(role);
                                }}
                              >
                                <EditIcon className={classes.linkIcon} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title={
                                role === 'ADMIN'
                                  ? t('app.deleteAdministrator')
                                  : t('app.deleteWarehouseEmployee')
                              }
                            >
                              <IconButton
                                size='small'
                                onClick={() => {
                                  setWarehouseManagerToRemove(warehouseManager);
                                  setIsOpenRemoveWarehouse(true);
                                  setRole(role);
                                }}
                              >
                                <DeleteForeverIcon
                                  style={{ color: COLORS.RED }}
                                />
                              </IconButton>
                            </Tooltip>
                          </BoxCentered>
                        </TableCell>
                      </TableRow>
                    );
                  })
              : !warehouseManagersList.length &&
                !loading &&
                !isLoadingAction && (
                  <TableRow>
                    <TableCell colSpan={12} align='center'>
                      {t('app.noData')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AddManagerTable;
