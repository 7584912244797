import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    marginTop: 25,

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  filtersItem: {
    maxWidth: 100,
  },
  filtersItemSelect: {
    width: 'auto',
    '& .MuiInput-formControl': {
      border: 'none',

      [theme.breakpoints.down('xs')]: {
        '& .MuiInput-input': {
          paddingLeft: 0,
        },
      },
    },
  },
  filtersItemSelectIcon: {
    position: 'absolute',
    right: 0,
    top: 13,
    width: 20,
    height: 20,
  },
  shipmentItems: {
    marginBottom: 35,

    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  pagination: {
    marginTop: '32px',
    fontWeight: 'bold',
    '& .MuiPagination-ul': {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'nowrap',
        justifyContent: 'center',
      },
    },
    '& .MuiButtonBase-root': {
      width: 44,
      height: 44,
      borderRadius: '50%',
      padding: 0,
      margin: 0,
      marginRight: 8,

      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 35,
        height: 35,
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      width: 12,
      height: 44,
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      minWidth: 'auto',
      marginRight: 8,
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '25px',
    },
  },
  actionIconEdit: {
    color: 'tomato',
  },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonsForSelected: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'flex-end',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  actionMenuItem: {
    padding: 0,
  },
  actionLink: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    textDecoration: 'none',
    padding: '6px 16px',
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
  },
  actionIconWatch: {
    color: 'green',
  },
  actionIconCancel: {
    color: 'red',
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
  buttonForSelected: {
    marginRight: '30px',
  },
  filtersSearch: {
    display: 'flex',
    marginLeft: 'auto',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filtersSearchField: {
    width: 162,
    height: 44,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filtersSearchSubmit: {
    backgroundColor: '#000000',
    padding: 0,
    minWidth: 'auto',
    width: 44,
    height: 44,
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  inputLabel: {
    maxWidth: '280px',
    marginBottom: '30px',
  },
  inputLabelPackage: {
    maxWidth: '174px',
  },
  inputLabelOperation: {
    maxWidth: '360px',
  },
}));
