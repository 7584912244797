import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { IFilters } from '../../../utils/types';
import { deliveryOptions } from './options';

interface IFilterSelectDeliveryService {
  value?: DeliveryService;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectDeliveryService = ({
  value,
  setFilter,
}: IFilterSelectDeliveryService) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => {
          setFilter((state) => ({
            ...state,
            deliveryService: e.target.value as DeliveryService,
            page: 1,
          }));
        }}
      >
        {deliveryOptions.map(({ value, label, src }) => {
          if (!value) {
            return <MenuItem value=''>{t('app.carrier')}</MenuItem>;
          }
          return (
            <MenuItem value={value} className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={src} alt={value} />
              {t(label as string)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FilterSelectDeliveryService;
