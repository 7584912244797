import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { ITableListHeader } from '../types';
import { useTranslation } from 'react-i18next';

export const TableListHeader = ({ headCells }: ITableListHeader) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((column) => (
          <TableCell key={column.label} align='center'>
            {t(column.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
