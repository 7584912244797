import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export default makeStyles((theme) => ({
  mainTitle: {
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      marginBottom: 15,
    },
  },
  boxForm: {
    display: 'flex',
    justifyContent: 'center',
    // '& div': {
    //     marginLeft: '5px',
    // }
  },
  virtualizedWrapper: {
    marginTop: 32,
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: '70px',
    width: '100%',
    boxShadow: '0px 5px 10px #888888',
  },
  infoHeader: {
    margin: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItem: 'center',
  },
  titleTrackNumber: {
    color: 'blue',
  },
  titleAdded: {
    margin: '10px 20px',
  },
  titleCommon: {
    margin: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoFooter: {
    margin: '0px',
  },
  infoSubFooter: {
    margin: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  placeholder: {
    color: 'grey',
  },
  errorMsg: {
    color: COLORS.RED,
  },
  carrierCodeIcon: {
    width: 20,
    maxHeight: 16,
    marginRight: 10,
  },
  carrierCodeSelectedItem: {
    display: 'flex',
    alignItems: 'center',
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  stepLabel: {
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: 'grey',
    },
  },
}));
