import { UserOrderRules } from '../../../generated/graphql';

export const filterSelectSortingOptions = [
  { title: 'app.sortingFilter', value: '' },
  { title: 'app.balanceFilter', value: UserOrderRules.Balance },
  {
    title: 'app.lastTransactionFilter',
    value: UserOrderRules.LastTransaction,
  },
  {
    title: 'app.verificationFilter',
    value: UserOrderRules.Verification,
  },
];
