import React, { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ModalBox from '../../ModalBox/ModalBox';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { CONFIGURATION_LABELS, TariffOptions } from '../../../utils/constants';

import SettingsCalculatorModalOptionsComponent from './SettingsCalculatorModalOptionsComponent';
import SettingsCalculatorModalOptionComponent from './SettingsCalculatorModalOptionComponent';

export interface FormValues {
  [key: string]: number | string;
}

export interface InitialValues {
  [key: string]: FormValues[];
}

export const SettingsCalculatorModal = ({
  isOpen,
  setIsOpen,
  inputFieldName,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inputFieldName: string;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOptionsArray, setIsOptionsArray] = useState(false);
  const { values } = useFormikContext<InitialValues>();

  const inputProps = {
    type: 'number',
    min: 0,
    step: 0.1,
  };

  const modalValues = values[inputFieldName];

  useEffect(() => {
    if (!modalValues) return;
    setIsOptionsArray(Array.isArray(modalValues));
  }, [inputFieldName, modalValues]);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const createEmptyObject = (keys: string[]): FormValues => {
    return keys.reduce((acc, key) => {
      if (key === 'name') {
        acc[key] = '';
      } else {
        acc[key] = 0;
      }

      return acc;
    }, {} as FormValues);
  };

  const handleAddRow = (arrayHelpers: FieldArrayRenderProps) => () => {
    const keys = Object.keys(modalValues[0]);
    const newValue = createEmptyObject(keys);
    arrayHelpers.push(newValue);
  };

  const handleRemoveRow =
    (arrayHelpers: FieldArrayRenderProps, indexToRemove: number) => () => {
      arrayHelpers.remove(indexToRemove);
    };

  const getLabel = (option: string) => {
    if (
      option === TariffOptions.STEP_COST &&
      inputFieldName === TariffOptions.INBOUND_PROCESSING_COST
    ) {
      return t('app.step_cost_per_piece');
    }
    if (
      option === TariffOptions.STEP_VALUE &&
      inputFieldName === TariffOptions.WH_STORAGE_COST_CASES
    ) {
      return t('app.step_value_cell_volume');
    }
    return t(CONFIGURATION_LABELS[option]);
  };

  const getInputProps = (option: string) => {
    if (option === TariffOptions.STEP_VALUE) {
      return {
        type: 'number',
        min: 0,
        step: 1,
      };
    }
    if (option === TariffOptions.PERCENT_OF_DECLARED_COST) {
      return {
        type: 'number',
        min: 0,
        step: 0.01,
      };
    }
    if (option === TariffOptions.NAME) {
      return {
        type: 'text',
        min: 0,
      };
    }
    return inputProps;
  };

  return (
    <ModalBox isOpen={isOpen} setOpen={setIsOpen}>
      <Typography variant='h6'>{t(`app.${inputFieldName}`)}</Typography>
      <FieldArray name={inputFieldName}>
        {(arrayHelpers) => (
          <Box
            display='flex'
            flexDirection={isOptionsArray ? 'column' : 'row'}
            className={classes.modalWrapper}
          >
            <Box display='flex' flexDirection='column'>
              {isOptionsArray
                ? modalValues?.map((value: any, i: number) => {
                    const valuesOptions = Object.keys(value);
                    return (
                      <SettingsCalculatorModalOptionsComponent
                        key={inputFieldName}
                        valuesOptions={valuesOptions}
                        inputFieldName={inputFieldName}
                        i={i}
                        getLabel={getLabel}
                        getInputProps={getInputProps}
                        modalValues={modalValues}
                        handleRemoveRow={handleRemoveRow}
                        handleAddRow={handleAddRow}
                        arrayHelpers={arrayHelpers}
                      />
                    );
                  })
                : Object.keys(modalValues)?.map((option) => {
                    const name = `${inputFieldName}.${option}`;
                    return (
                      <SettingsCalculatorModalOptionComponent
                        name={name}
                        option={option}
                        getInputProps={getInputProps}
                      />
                    );
                  })}
            </Box>
          </Box>
        )}
      </FieldArray>
      <div className={classes.boxModalButtons}>
        <Button
          variant='contained'
          onClick={handleCloseModal}
          color='secondary'
        >
          {t('app.close')}
        </Button>
      </div>
    </ModalBox>
  );
};

export default SettingsCalculatorModal;
