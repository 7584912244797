/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { COUNTRIES_CIS } from '../utils/constants';

type Props = {
  children: React.ReactNode;
};
export const AppFormProvider = ({ children }: Props) => {
  const { formValue, handleSetFormContext } = useForm();
  const { allCountries, sngCountry } = useCountry();
  const [consolidationContext, setConsolidationContext] = useState();

  return (
    <AppFormContext.Provider
      value={{
        formValue,
        handleSetFormContext,
        allCountries,
        sngCountry,
        consolidationContext,
        setConsolidationContext,
      }}
    >
      {children}
    </AppFormContext.Provider>
  );
};

type ContextProps = {
  formValue?: any;
  handleSetFormContext: any;
  allCountries: any;
  sngCountry: any;
  consolidationContext: any;
  setConsolidationContext: any;
};

export const AppFormContext = React.createContext<ContextProps>({
  formValue: {},
  handleSetFormContext: () => {},
  allCountries: [],
  sngCountry: [],
  consolidationContext: [],
  setConsolidationContext: () => {},
});

export const useAppFormContext = () => {
  const context = React.useContext(AppFormContext);
  if (!context) {
    throw new Error('useAppFormContext must be used within a AppFormProvider');
  }
  return context;
};

const useForm = () => {
  const [formValue, setFormValue] = useState<any>({
    fuelSurcharge: undefined,
    dollarRate: null,
  });
  const handleSetFormContext = (key: string, value: any) => {
    setFormValue((prevfilter: any) => {
      return {
        ...prevfilter,
        [key]: value,
      };
    });
  };

  return { formValue, handleSetFormContext };
};

const useCountry = () => {
  const [allCountries, setAllCountries] = React.useState<any[any]>([]);
  const [sngCountry, setSngCountry] = useState(COUNTRIES_CIS);

  useEffect(() => {
    const countryList = JSON.parse(
      localStorage.getItem('deliveryCountries') as any,
    );

    const allCountries = countryList?.length
      ? [...countryList].sort((a, b) => {
          return a && b && a.name < b.name
            ? -1
            : a && b && a.name > b.name
            ? 1
            : 0;
        })
      : [];
    setAllCountries(allCountries);
  }, []);

  useEffect(() => {
    if (!allCountries) return;
    let arr: any = [];
    allCountries?.map((e: any) => {
      COUNTRIES_CIS.find((item): void => {
        if (item.iso === e.iso) {
          arr.push(e);
        }
      });
    });

    setSngCountry(arr);
  }, [allCountries]);

  return { allCountries, sngCountry };
};
