import { gql } from '@apollo/client';

export const GET_USER_DISCOUNT_QUERY = gql`
  query GetUserDiscount($userId: Int) {
    getUserDiscount(userId: $userId) {
      id
      userId
      creditLimit
      orderHandlingDiscountPercent
      warehouseStorageDiscountPercent
      forwardingDiscountPercent
      returnHandlingDiscountPercent
      inboundDiscountPercent
      undeclaredInboundDiscountPercent
    }
  }
`;
