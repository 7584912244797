import { makeStyles, createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    inputLabel: {
      marginTop: '30px',
    },
    boxModalButtons: {
      margin: '30px 0 0',
      display: 'flex',
      justifyContent: 'space-around',
      gap: '24px',
    },
    textareaAutosize: {
      color: '#000',
      padding: '13px 16px',
      fontSize: '14px',
      lineHeight: '20px',
      font: 'inherit',
    },
  });

export default makeStyles(styles);
