import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import {
  LOCAL_STORAGE_KEYS,
  SWAP_HISTORY_TABLE_CELLS_ARRAY,
} from '../../../utils/constants';
import {
  WarehouseProductLog,
  useGetWarehouseProductLogLazyQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { LIMIT_ITEM_PAGE } from '../../../utils/helpers';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';
import { IFilters } from '../../../utils/types';
import FilterInputSearch from '../../../components/FilterComponents/FilterInputSerch/FilterInputSerch';
import TableBodyRequestHandler from '../../../components/TableBodyRequestHandler/TableRequestHandler';
import SwapHistoryTableRowComponent from '../../../components/SwapHistoryComponents/SwapHistoryTableRowComponent/SwapHistoryTableRowComponent';
import FilterSelectLimitItems from '../../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

const SwapHistory = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [swapHistoryData, setSwapHistoryData] = useState<WarehouseProductLog[]>(
    [],
  );

  const { t } = useTranslation();

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    search: '',
  });

  const [getSwapHistory, { data, loading }] =
    useGetWarehouseProductLogLazyQuery({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    });

  useEffect(() => {
    if (!data?.getWarehouseProductLog?.rows) return;

    data?.getWarehouseProductLog?.rows &&
      setSwapHistoryData(
        data?.getWarehouseProductLog?.rows as WarehouseProductLog[],
      );

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    refetchSwapHistory();
    // eslint-disable-next-line
  }, [filter]);

  const refetchSwapHistory = useDebouncedCallback(() => {
    getSwapHistory({
      variables: {
        limit: filter.itemsLimit!,
        offset: (filter.page - 1) * filter.itemsLimit!,
        barcodeId: filter.search,
      },
    });
  }, 400);

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {t('app.movements')}
      </Typography>

      <div className={classes.filters}>
        <FilterSelectLimitItems
          value={filter.itemsLimit}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />
        <FilterInputSearch setFilter={setFilter} />
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {SWAP_HISTORY_TABLE_CELLS_ARRAY.map((text) => (
                <TableCell align='center' rowSpan={2}>
                  {t(text)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={2}
              colSpan={SWAP_HISTORY_TABLE_CELLS_ARRAY.length}
              loading={loading}
            >
              {swapHistoryData?.map((history) => (
                <SwapHistoryTableRowComponent
                  key={history.id}
                  history={history}
                />
              ))}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={data?.getWarehouseProductLog?.count}
        limit={filter.itemsLimit!}
        currentPage={filter.page}
        setFilter={setFilter}
      />
    </Container>
  );
};

export default SwapHistory;
