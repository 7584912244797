import React from 'react';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { TRoutes } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import { SwitcherVerification } from '../SwitcherVerification/SwitcherVerification';
import moment from 'moment';
import { DATE_FORMAT, discountsEnums } from '../../utils/constants';
import { User, UserRoles } from '../../generated/graphql';
import { TSetStateString } from '../../utils/types';

interface UserRowItemProps {
  user: User;
  setDiscountUserId: React.Dispatch<React.SetStateAction<React.ReactText>>;
  setIsDiscountModalOpen: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setDiscountValue: React.Dispatch<React.SetStateAction<React.ReactText>>;
  verifyPassportDataMutation: () => void;
  verifyLegalDataMutation: () => void;
  handleSetCreditLimit: any;
  isCreditModalOpen: boolean;
  setIsCreditModalOpen: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setUserId: React.Dispatch<React.SetStateAction<string | number | null>>;
  role?: UserRoles | null;
  setIsDiscount: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setCreditValue: React.Dispatch<React.SetStateAction<number>>;
  setDiscountProperty: TSetStateString;
}
export default function UserRowItem({
  user,
  setDiscountUserId,
  setIsDiscountModalOpen,
  setDiscountValue,
  verifyPassportDataMutation,
  verifyLegalDataMutation,
  handleSetCreditLimit,
  isCreditModalOpen,
  setIsCreditModalOpen,
  setUserId,
  role,
  setIsDiscount,
  setCreditValue,
  setDiscountProperty,
}: UserRowItemProps) {
  return (
    <TableRow>
      <TableCell align='center'>{user.id}</TableCell>
      <TableCell align='center'>
        {moment(user.createdAt).format(DATE_FORMAT)}
      </TableCell>
      <TableCell align='center'>
        <Link
          to={TRoutes.PAYMENT_STATISTICS_PER_USER.replace(
            ':userId',
            user?.id.toString(),
          )}
        >
          {user?.name}
        </Link>
      </TableCell>
      <TableCell align='center'>{user.balance}</TableCell>
      <TableCell align='center'>{user.company}</TableCell>
      <TableCell align='left'>
        <BoxCentered justifyContent={'flex-start'}>
          {user?.city?.toUpperCase()} {user.state} {user.zipCode}
          <br />
          {user?.country?.nameEng}
        </BoxCentered>
      </TableCell>
      <TableCell align='center'>
        <a href={`tel:${user.phone}`}>{user.phone}</a>
      </TableCell>
      <TableCell align='center'>
        <a
          href={`mailto:${user.email}?subject=Pick and Pack&body=Здравствуйте, ${user.name}!`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {user.email}
        </a>
      </TableCell>
      <TableCell align='center'>
        {user?.passportData && role !== 'ADMIN' ? (
          <SwitcherVerification
            value={user?.passportData?.isDataVerified}
            verifyPassportDataMutation={verifyPassportDataMutation}
            id={user?.passportData?.id}
          />
        ) : null}
        {user?.entityLegalData && role !== 'ADMIN' ? (
          <SwitcherVerification
            value={user?.entityLegalData?.isDataVerified}
            verifyPassportDataMutation={verifyLegalDataMutation}
            id={user?.entityLegalData?.id}
            valueName='entityLegalDataId'
          />
        ) : null}
      </TableCell>
      <TableCell align='center'>
        {user.creditLimit || 0}
        {role === 'SUPER_ADMIN' ? (
          <IconButton
            size='small'
            edge='end'
            onClick={() => {
              setIsDiscount(false);
              setIsDiscountModalOpen(true);
              setDiscountUserId(user.id);
              setDiscountValue(Number(user.creditLimit));
              setDiscountProperty(discountsEnums.CREDIT_LIMIT);
            }}
          >
            <EditIcon color='primary' />
          </IconButton>
        ) : null}
      </TableCell>
      {/* <TableCell align='center'>
        {user.discount || 0}%
        {role === 'SUPER_ADMIN' ? (
          <IconButton
            size='small'
            edge='end'
            onClick={() => {
              setIsDiscount(true);
              setIsDiscountModalOpen(true);
              setDiscountUserId(user.id);
              setDiscountValue(user.discount || 0);
            }}
            disabled
          >
            <EditIcon color='primary' />
          </IconButton>
        ) : null}
      </TableCell>
      <TableCell align='center'>
        {(user.whFeePersonalDiscount && user.whFeePersonalDiscount * 100) || 0}%
        {role === 'SUPER_ADMIN' ? (
          <IconButton
            size='small'
            edge='end'
            onClick={() => {
              setIsDiscount(false);
              setIsDiscountModalOpen(true);
              setDiscountUserId(user.id);
              setDiscountValue(
                (user.whFeePersonalDiscount &&
                  user.whFeePersonalDiscount * 100) ||
                  0,
              );
              setDiscountProperty(
                discountsEnums.WAREHOUSE_STORAGE_DISCOUNT_PERCENT,
              );
            }}
          >
            <EditIcon color='primary' />
          </IconButton>
        ) : null}
      </TableCell> */}
    </TableRow>
  );
}
