import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainTitle: {
    textAlign: 'center',
  },
  placeholder: {
    color: 'grey',
  },
  selected: {
    margin: '25px 0px',
  },
  filtered: {
    width: '110px',
    padding: '5px 16px',
  },
  icon: {
    top: '0px',
    width: '20%',
    height: '100%',
    background: 'grey',
  },
  pagination: {
    marginTop: '32px',
    fontWeight: 'bold',
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    marginTop: 25,

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  filtersItemSelect: {
    width: 'auto',
    '& .MuiInput-formControl': {
      border: 'none',

      [theme.breakpoints.down('xs')]: {
        '& .MuiInput-input': {
          paddingLeft: 0,
        },
      },
    },
  },
  dateLabel: {
    margin: 0,
  },
  totalRow: {
    border: '2px solid black',
  },
}));
