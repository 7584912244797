import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import FilterSelectLimitItems from '../../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';
import { IFilters } from '../../../utils/types';
import {
  B2B_SHIPMENT_POINTS_TABLE_CELLS_ARRAY,
  LOCAL_STORAGE_KEYS,
} from '../../../utils/constants';
import { LIMIT_ITEM_PAGE } from '../../../utils/helpers';
import TableBodyRequestHandler from '../../../components/TableBodyRequestHandler/TableRequestHandler';
import {
  B2BShipmentPoint,
  useDeleteB2BShipmentPointMutation,
  useGetB2BShipmentPointsLazyQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';
import B2bShipmentPointsTableRowComponent from '../../../components/B2bShipmentsComponents/B2bShipmentPointsTableRowComponent';
import B2bShipmentsPointCreateModal from '../../../components/Modals/B2bShipmentsModals/B2bShipmentsPointCreateModal';
import DeleteItemModal from '../../../components/Modals/DeleteItemModal';
import { GET_B2B_SHIPMENT_POINTS_QUERY } from '../../../GraphQL/queries/getB2BShipmentPoints';

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

const B2bShipmentsPoints = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [
    isOpenB2bShipmentsPointCreateModal,
    setIsB2bShipmentsPointCreateModal,
  ] = useState(false);
  const [
    isOpenB2bShipmentsPointDeleteModal,
    setIsB2bShipmentsPointDeleteModal,
  ] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<number | null>(null);

  const [itemToEdit, setItemToEdit] = useState<B2BShipmentPoint>({
    name: '',
    description: '',
    id: null,
  });

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const [getB2BShipmentPointsLazyQuery, { data, loading }] =
    useGetB2BShipmentPointsLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    getB2BShipmentPointsLazyQuery({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit!,
        limit: filter.itemsLimit,
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });
    // eslint-disable-next-line
  }, [filter]);

  const [
    deleteB2BShipmentPointMutation,
    { loading: deleteB2BShipmentPointMutationLoading },
  ] = useDeleteB2BShipmentPointMutation();

  const handleDeleteCloseModal = () => {
    setIsB2bShipmentsPointDeleteModal(false);
    setItemIdToDelete(null);
  };

  const handleDelete = () => {
    deleteB2BShipmentPointMutation({
      variables: { deleteB2BShipmentPointId: itemIdToDelete },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar('app.b2bShipmentsPointSuccessfullyDeleted', {
            variant: 'success',
          });
          handleDeleteCloseModal();
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [GET_B2B_SHIPMENT_POINTS_QUERY],
    });
  };

  const handleOpemModalToCreate = () => {
    setIsB2bShipmentsPointCreateModal(true);
  };

  return (
    <>
      <Typography variant='h2' align='center' className={classes.mainTitle}>
        {t('app.b2bShipmentsPoints')}
      </Typography>
      <div className={classes.buttonsForSelected}>
        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />
        <Button variant='contained' onClick={handleOpemModalToCreate}>
          {t('app.add')}
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {B2B_SHIPMENT_POINTS_TABLE_CELLS_ARRAY.map((text) => (
                <TableCell key={text} align='center' rowSpan={2}>
                  {t(text)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={data?.getB2BShipmentPoints?.rows?.length as number}
              colSpan={B2B_SHIPMENT_POINTS_TABLE_CELLS_ARRAY.length}
              loading={loading}
            >
              {data?.getB2BShipmentPoints?.rows?.length
                ? data?.getB2BShipmentPoints?.rows?.map((item) => (
                    <B2bShipmentPointsTableRowComponent
                      key={item?.id}
                      item={item as B2BShipmentPoint}
                      setItemToEdit={setItemToEdit}
                      setIsB2bShipmentsPointCreateModal={
                        setIsB2bShipmentsPointCreateModal
                      }
                      setItemIdToDelete={setItemIdToDelete}
                      setIsB2bShipmentsPointDeleteModal={
                        setIsB2bShipmentsPointDeleteModal
                      }
                    />
                  ))
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={data?.getB2BShipmentPoints?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />

      <B2bShipmentsPointCreateModal
        isOpenB2bShipmentsPointCreateModal={isOpenB2bShipmentsPointCreateModal}
        setIsB2bShipmentsPointCreateModal={setIsB2bShipmentsPointCreateModal}
        item={itemToEdit}
        setItemToEdit={setItemToEdit}
      />

      <DeleteItemModal
        isOpenModal={isOpenB2bShipmentsPointDeleteModal}
        setIsOpenModal={setIsB2bShipmentsPointDeleteModal}
        handleCloseModal={handleDeleteCloseModal}
        loading={deleteB2BShipmentPointMutationLoading}
        handleDelete={handleDelete}
        title={t('app.confirmDeletePoint')}
      />
    </>
  );
};

export default B2bShipmentsPoints;
