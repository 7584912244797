import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { colorsEnum } from '../../utils/constants';
import { B2BOrderListItem, WarehouseB2BStatus } from '../../generated/graphql';
import { TRoutes } from '../../utils/helpers';

export const getActionOptions = (
  order: B2BOrderListItem,
  openModalForCancelHandler: () => void,
  isSuperAdmin: boolean,
) => {
  return [
    {
      link: `${TRoutes.B2B_ORDERS_DETAILS_WITHOUT_ID}${order?.id?.toString()}`,
      Icon: SearchIcon,
      title: 'app.view',
      color: colorsEnum.GREEN,
      tooltipText: 'app.clickToOpenViewShipments',
    },
    {
      link: `${TRoutes.B2B_ORDER_PACKAGING_WITHOUT_ID}${order?.id?.toString()}`,
      Icon: LocalShippingIcon,
      title: 'app.goToPackaging',
      color: colorsEnum.BLUE,
      tooltipText: 'app.goToPackaging',
      disabled: !(
        order?.warehouseStatus === WarehouseB2BStatus.Created ||
        order?.warehouseStatus === WarehouseB2BStatus.Picked ||
        order?.warehouseStatus === WarehouseB2BStatus.Processing
      ),
    },

    {
      link: `#`,
      Icon: CancelIcon,
      title: 'app.cancel',
      onClick: openModalForCancelHandler,
      disabled:
        !isSuperAdmin || order?.warehouseStatus === WarehouseB2BStatus.Created,
      color: colorsEnum.RED,
      tooltipText: 'app.clickToCancelOrder',
    },
  ];
};
