import { gql } from '@apollo/client';

export const GET_WAREHOUSE_ZONES = gql`
  query GetWarehouseZones {
    getWarehouseZones {
      freeCellsCount
      id
      isDefault
      name
      occupiedCellsCount
      orderNumber
      totalCellsCount
    }
  }
`;
