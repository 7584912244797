import React from 'react';

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ModalBox from '../../ModalBox/ModalBox';
import { FastField, FieldProps, Form, Formik, FormikProps } from 'formik';
import {
  Notification,
  useSendNotificationMutation,
} from '../../../generated/graphql';
import { sendNotificationSchema } from '../../../utils/validationSchemes';
import { ISendNotificationModal, ISendNotificationValues } from './types';
import { useSnackbar } from 'notistack';
import { QUERY_NOTIFICATION_LIST } from '../../../GraphQL/queries/getNotificationList';

const SendNotificationModal = ({
  isOpenSendNotificationModal,
  setIsOpenSendNotificationModal,
}: ISendNotificationModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [sendNotificationMutation, { loading }] = useSendNotificationMutation();

  const handleCloseModal = () => {
    setIsOpenSendNotificationModal(false);
  };

  const handleSubmit = (values: ISendNotificationValues) => {
    sendNotificationMutation({
      variables: { ...values },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.notificationSent'), { variant: 'success' });
          handleCloseModal();
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [QUERY_NOTIFICATION_LIST],
    });
  };

  return (
    <ModalBox
      isOpen={isOpenSendNotificationModal}
      setOpen={setIsOpenSendNotificationModal}
    >
      <Typography variant='h6'>{t('app.sendNotification')}</Typography>
      <Formik
        initialValues={{
          topic: '',
          text: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={sendNotificationSchema}
      >
        {({ setFieldValue, errors }: FormikProps<Notification>) => {
          return (
            <Form>
              <Box display='flex' flexDirection='column'>
                <FastField name='topic'>
                  {({ field: { value, ...field }, meta }: FieldProps) => {
                    return (
                      <FormControl
                        className={classes.inputLabel}
                        error={meta.touched && !!meta.error}
                      >
                        <InputLabel>{t('app.themeNotifications')}</InputLabel>
                        <Input
                          disableUnderline
                          defaultValue={value}
                          {...field}
                          onChange={(e) =>
                            setFieldValue('topic', e.target.value)
                          }
                        />

                        {meta.touched && meta.error && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                </FastField>
                <FastField name='text'>
                  {({ field: { value, ...field }, meta }: FieldProps) => {
                    return (
                      <FormControl
                        className={classes.inputLabel}
                        error={meta.touched && !!meta.error}
                      >
                        <InputLabel>{t('app.textNotifications')}</InputLabel>
                        <TextareaAutosize
                          rowsMin={2}
                          defaultValue={value}
                          {...field}
                          onChange={(e) =>
                            setFieldValue('text', e.target.value)
                          }
                          className={classes.textareaAutosize}
                        />

                        {meta.touched && meta.error && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                </FastField>
              </Box>
              <div className={classes.boxModalButtons}>
                <Button
                  variant='contained'
                  onClick={handleCloseModal}
                  color='secondary'
                >
                  {t('app.close')}
                </Button>
                <Button disabled={loading} type='submit' variant='contained'>
                  {t('app.sendNotification')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ModalBox>
  );
};

export default SendNotificationModal;
