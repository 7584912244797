import { gql } from '@apollo/client';

export const getStatusesList = gql`
  query getStatusesList {
    getStatusesList {
      DeliveryStatusList {
        id
        name
        explain
      }
      PaymentStatusList {
        id
        name
        explain
      }
    }
  }
`;
