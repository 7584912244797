import React from 'react';
import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';

import { B2BServices } from '../../generated/graphql';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Edit as EditIcon } from '@material-ui/icons';
import { COLORS } from '../../utils/constants';

const B2bShipmentServicesTableRowComponent = ({
  item,
  setIsOpenCreateOrEditModal,
  setItemToEdit,
  setIsOpenDeleteModal,
  setItemIdToDelete,
}: {
  item: B2BServices;
  setItemToEdit: React.Dispatch<React.SetStateAction<B2BServices>>;
  setIsOpenCreateOrEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setItemIdToDelete: React.Dispatch<React.SetStateAction<number | null>>;
  setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOpenEditModal = () => {
    setIsOpenCreateOrEditModal(true);
    setItemToEdit(item);
  };

  const handleOpenDeleteModal = () => {
    setIsOpenDeleteModal(true);
    setItemIdToDelete(Number(item?.id));
  };

  return (
    <TableRow>
      <TableCell align='center'>{item.code}</TableCell>
      <TableCell align='center'>{item.title}</TableCell>
      <TableCell align='center'>{item.description}</TableCell>
      <TableCell align='center'>{item.costInUSD}</TableCell>

      <TableCell align='center'>
        <BoxCentered>
          <Tooltip title={`${t('app.edit')} ${item.title}`}>
            <IconButton
              size='small'
              className={classes.btnIcon}
              onClick={handleOpenEditModal}
            >
              <EditIcon className={classes.linkIcon} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('app.delete')}>
            <IconButton size='small' onClick={handleOpenDeleteModal}>
              <DeleteForeverIcon style={{ color: COLORS.RED }} />
            </IconButton>
          </Tooltip>
        </BoxCentered>
      </TableCell>
    </TableRow>
  );
};

export default B2bShipmentServicesTableRowComponent;
