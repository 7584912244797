import { TRoutes } from '../../../utils/helpers';
import SearchIcon from '@material-ui/icons/Search';
import { IActionOptions } from './types';

export const actionOptions: IActionOptions[] = [
  {
    link: TRoutes.RETURN_DETAILS,
    Icon: SearchIcon,
    title: 'app.view',
  },
];
