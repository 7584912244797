import ups from '../../../assets/img/carriers/ups.svg';
import dhl from '../../../assets/img/carriers/dhl.svg';
import usps from '../../../assets/img/carriers/usps.png';
import fedex from '../../../assets/img/carriers/fedex.svg';
import tnt from '../../../assets/img/carriers/TNT.png';
import boxberry from '../../../assets/img/carriers/boxberry.png';
import dpd from '../../../assets/img/carriers/dpd.png';
import postRussia from '../../../assets/img/carriers/post_russia.png';
import apcLogistics from '../../../assets/img/carriers/apc_logistics.png';
import amazon from '../../../assets/img/carriers/amazon.png';
import other from '../../../assets/img/carriers/other.png';
import { DeliveryService } from '../../../generated/graphql';

export const deliveryOptions = [
  { value: '' },
  {
    value: DeliveryService.AmazonLogistics,
    label: 'app.amazonLogistics',
    src: amazon,
  },
  {
    value: DeliveryService.Ups,
    label: 'app.ups',
    src: ups,
  },
  {
    value: DeliveryService.Dhl,
    label: 'app.dhl',
    src: dhl,
  },
  {
    value: DeliveryService.Usps,
    label: 'app.usps',
    src: usps,
  },
  {
    value: DeliveryService.Fedex,
    label: 'app.fedex',
    src: fedex,
  },
  {
    value: DeliveryService.Tnt,
    label: 'app.tnt',
    src: tnt,
  },
  {
    value: DeliveryService.PostRussia,
    label: 'app.russianPost',
    src: postRussia,
  },
  {
    value: DeliveryService.Dpd,
    label: 'app.dpd',
    src: dpd,
  },
  {
    value: DeliveryService.Apc,
    label: 'app.apc',
    src: apcLogistics,
  },
  {
    value: DeliveryService.Boxberry,
    label: 'app.boxberry',
    src: boxberry,
  },
  {
    value: DeliveryService.Other,
    label: 'app.other',
    src: other,
  },
];
