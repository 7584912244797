import React from 'react';
import ModalBox from '../ModalBox/ModalBox';
import { Button, Typography } from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface ICancelWarehouseShipmentOrderModal {
  orderIdToCancel: number | null;
  isOpenConfirmCancel: boolean;
  setIsOpenConfirmCancel: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderIdToCancel?: React.Dispatch<React.SetStateAction<number | null>>;
  cancelWarehouseShipmentOrderHandler: (id: number) => void;
  cancelWarehouseShipmentOrderLoading: boolean;
}

const CancelWarehouseShipmentOrderModal = ({
  orderIdToCancel,
  isOpenConfirmCancel,
  setIsOpenConfirmCancel,
  setOrderIdToCancel,
  cancelWarehouseShipmentOrderHandler,
  cancelWarehouseShipmentOrderLoading,
}: ICancelWarehouseShipmentOrderModal) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setIsOpenConfirmCancel(false);
    setOrderIdToCancel && setOrderIdToCancel(null);
  };

  const handleCancelWarehouseShipmentOrder = () => {
    cancelWarehouseShipmentOrderHandler(Number(orderIdToCancel));
  };

  return (
    <ModalBox isOpen={isOpenConfirmCancel} setOpen={setIsOpenConfirmCancel}>
      <Typography variant='h6'>{t('app.confirmCancelOrder')}</Typography>
      <div className={classes.boxModalButtons}>
        <Button
          variant='contained'
          onClick={handleCloseModal}
          color='secondary'
        >
          {t('app.close')}
        </Button>
        <Button
          disabled={cancelWarehouseShipmentOrderLoading}
          variant='contained'
          onClick={handleCancelWarehouseShipmentOrder}
        >
          {t('app.cancelTheOrder')}
        </Button>
      </div>
    </ModalBox>
  );
};

export default CancelWarehouseShipmentOrderModal;
