import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Else, If, Then } from 'react-if';
import { useMutation } from '@apollo/client';
import useStyles from './styles';
import { MUTATION_DEBITING_AND_CREDITING_FUNDS } from '../../GraphQL/mutations/debitingAndCreditingFunds';
import { ShowLoadingText } from '../../utils/helperComponents';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import {
  COLORS,
  LOCAL_STORAGE_KEYS,
  PAYMENT_TYPES,
} from '../../utils/constants';
import PaymentsTable from '../../components/PaymentsTable/PaymentsTable';
import { useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import {
  Availability_Operation,
  B2BServices,
  Discount,
  Transaction,
  TransactionTypes,
  useGetB2BShipmentServicesQuery,
  useGetCurrentUserQuery,
  useGetPaymentHistoryListByUserIdLazyQuery,
  useGetTransactionTypesListLazyQuery,
  useGetUserDiscountQuery,
  useUpdateCreditLimitForUserMutation,
  useVerifyLegalDataMutation,
  useVerifyPassportDataMutation,
} from '../../generated/graphql';
import { useDebouncedCallback } from 'use-debounce';
import { LIMIT_ITEM_PAGE } from '../../utils/helpers';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ModalBox from '../../components/ModalBox/ModalBox';
import { AllInfoPasportData } from './AllInfoPasportData';
import { AllInfoUrData } from './AllInfoUrData';
import { useGetUserQuery } from '../../generated/graphql';
import { SwitcherVerification } from '../../components/SwitcherVerification/SwitcherVerification';
import { ModalDiscount, useDiscount } from '../Users/discount';
import { ModalCredit, useCredit } from '../Users/creditLimit';
import { useTranslation } from 'react-i18next';
import Discounts from './Discounts';

export default function PaymentsHistory() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [destination, setDestination] = useState('');
  const [amount, setAmount] = useState(0);
  const [isDebiting, setIsDebiting] = useState(false);
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [isServiceFee, setisServiceFee] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const [isOpenUnitModal, setIsOpenUnitModal] = useState(false);
  const [discountProperty, setDiscountProperty] = useState('');
  const [selectOptions, setSelectOptions] = useState<B2BServices[]>([]);
  const [serviceQuantity, setServiceQuantity] = useState(0);
  const [serviceAmount, setServiceAmount] = useState(0);

  const RATE_USD = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.RATE_USD));

  const { data: userData, refetch: userRefetch } = useGetUserQuery({
    variables: {
      id: parseInt(userId),
    },
  });

  const { data: userDiscount } = useGetUserDiscountQuery({
    variables: {
      userId: parseInt(userId),
    },
  });

  const { data: servicesData, loading: servicesLoading } =
    useGetB2BShipmentServicesQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      fetchPolicy: 'network-only',
    });

  const [paymentType, setPaymentType] = useState<TransactionTypes | null>(null);

  const optionsNotForDebiting = [t('app.paymentViaBankTransfer')];

  const [filter, setFilter] = useState({
    page: 1,
  });
  const {
    isDiscountModalOpen,
    setIsDiscountModalOpen,
    setDiscountUserId,
    discountValue,
    setDiscountValue,
    discountValueError,
    setDiscountValueError,
    isDiscount,
  } = useDiscount();

  const { isCreditModalOpen, setIsCreditModalOpen } = useCredit();

  const [
    debitingAndCreditingFunds,
    { loading: isLoadingDebitingAndCreditingFunds },
  ] = useMutation(MUTATION_DEBITING_AND_CREDITING_FUNDS);

  const [getPaymentsLazy, { loading: isLoading, error, data }] =
    useGetPaymentHistoryListByUserIdLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [
    getTransactionTypesList,
    { data: paymentTypesListData, loading: paymentTypesListLoading },
  ] = useGetTransactionTypesListLazyQuery({
    onError(error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    fetchPolicy: 'network-only',
  });

  const { data: currentUserData } = useGetCurrentUserQuery();

  const [verifyPassportDataMutation] = useVerifyPassportDataMutation();

  const [verifyLegalDataMutation] = useVerifyLegalDataMutation();

  const [updateCreditLimitForUserMutation] =
    useUpdateCreditLimitForUserMutation();

  useEffect(() => {
    if (!!servicesData?.getB2BShipmentServices?.rows) {
      setSelectOptions(
        servicesData?.getB2BShipmentServices?.rows as B2BServices[],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    getTransactionTypesList({
      variables: {
        operation: (!isDebiting
          ? 'INCREMENT'
          : 'DECREMENT') as Availability_Operation,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDebiting]);

  useEffect(() => {
    refetchPaymentsLazy();
    // eslint-disable-next-line
  }, [filter]);

  const refetchPaymentsLazy = useDebouncedCallback(() => {
    getPaymentsLazy({
      variables: {
        userId: +userId,
        offset: (filter.page - 1) * LIMIT_ITEM_PAGE,
        limit: LIMIT_ITEM_PAGE,
      },
    });
  });

  const handleSetCreditLimit = ({ userId, value }: any) => {
    updateCreditLimitForUserMutation({
      variables: {
        userId: userId,
        value: value,
      },
    })
      .then(() => {
        userRefetch();
      })
      .catch(() => {});
  };

  if (error) {
    enqueueSnackbar(error.message, { variant: 'error' });
  }

  if (!+userId) {
    return (
      <Box display='flex' justifyContent='center' color={COLORS.RED}>
        <Typography variant='h6'>{t('app.errorUserId', { userId })}</Typography>
      </Box>
    );
  }

  const getPaymentTypeText = (paymentType: TransactionTypes) => {
    if (paymentType === 'ADMIN_OPERATION') {
      let reason = '';
      if (isDebiting) {
        reason = t('app.manualDebit');
      } else {
        reason = t('app.manualRefill');
      }
      return reason;
    }
    if (paymentType) {
      const type = t(PAYMENT_TYPES[paymentType]);
      if (type) return type;
    }
    return t('app.unknownPaymentType');
  };

  const debitingAndCreditingFundsHandler = () => {
    debitingAndCreditingFunds({
      variables: {
        userId: +userId,
        amount: isServiceFee ? serviceAmount * serviceQuantity : +amount,
        reason: isServiceFee
          ? `${destination} ${serviceQuantity}`
          : destination,
        isDebiting: isDebiting,
        type: isServiceFee
          ? TransactionTypes.ServiceFee
          : (paymentType as TransactionTypes),
      },
      onCompleted: (data) => {
        if (data.debitingAndCreditingFunds.status === 'succeeded') {
          enqueueSnackbar(t('app.transactionSaved'), {
            variant: 'success',
          });
          setAmount(0);
          setDestination('');
          setPaymentType(null);
          setIsFormOpened(false);
          refetchPaymentsLazy();
          setisServiceFee(false);
          setServiceAmount(0);
          setServiceQuantity(0);
        }
      },
      onError: (reason) => {
        if (reason?.message) {
          enqueueSnackbar(reason?.message, {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(t('app.somethingWentWrong'), {
            variant: 'error',
          });
        }
        setIsFormOpened(false);
      },
    });
  };

  const isDebibtingDisabledButton =
    isLoadingDebitingAndCreditingFunds ||
    !destination ||
    !amount ||
    +amount === 0 ||
    (!isServiceFee && !paymentType) ||
    paymentTypesListLoading;

  const isServiceFeeDisabledButton =
    isLoadingDebitingAndCreditingFunds ||
    !destination ||
    !serviceAmount ||
    !serviceQuantity ||
    serviceAmount * serviceQuantity === 0 ||
    servicesLoading;

  return (
    <>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} sm={12}>
          <Typography variant='h2' className={classes.mainTitle}>
            {t('app.customerInformation')}
          </Typography>
          <Divider />
          <Grid container spacing={2} justify='center'>
            <Grid item xs={12} sm={6}>
              <Box mt={2} mb={2}>
                <Typography variant='h3'>{t('app.customerInfo')}</Typography>
              </Box>
              <List>
                <ListItem>
                  {t('app.fullName')}:&nbsp;
                  {data?.getPaymentHistoryListByUserId?.name}
                </ListItem>
                <ListItem>
                  {t('app.address')}:&nbsp;
                  {data?.getPaymentHistoryListByUserId?.address}
                </ListItem>
                {data?.getPaymentHistoryListByUserId?.address2 ? (
                  <ListItem>
                    {t('app.addressNum', { value: 2 })}:&nbsp;
                    {data?.getPaymentHistoryListByUserId?.address2}
                  </ListItem>
                ) : null}
                {data?.getPaymentHistoryListByUserId?.address3 ? (
                  <ListItem>
                    {t('app.addressNum', { value: 3 })}:&nbsp;
                    {data?.getPaymentHistoryListByUserId?.address3}
                  </ListItem>
                ) : null}
                <ListItem>
                  {t('app.stateRegion')}:&nbsp;
                  {data?.getPaymentHistoryListByUserId?.state}
                </ListItem>
                <ListItem>
                  {t('app.country')}:&nbsp;
                  {i18n.resolvedLanguage === 'en'
                    ? data?.getPaymentHistoryListByUserId?.country?.nameEng
                    : data?.getPaymentHistoryListByUserId?.country?.name}
                </ListItem>
                <ListItem>
                  {t('app.city')}:&nbsp;
                  {userData?.getUser?.city}
                </ListItem>
                <ListItem>
                  {t('app.phone')}:&nbsp;
                  {data?.getPaymentHistoryListByUserId?.phone}
                </ListItem>

                <ListItem>
                  Email:&nbsp;
                  {data?.getPaymentHistoryListByUserId?.email && (
                    <a
                      href={`mailto:${data?.getPaymentHistoryListByUserId?.email}`}
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    >
                      {data?.getPaymentHistoryListByUserId?.email}
                    </a>
                  )}
                </ListItem>
                <ListItem>
                  {t('app.currentBalance')}:&nbsp;
                  {data?.getPaymentHistoryListByUserId?.balance &&
                  data?.getPaymentHistoryListByUserId?.balance > 0
                    ? data?.getPaymentHistoryListByUserId?.balance?.toFixed(2)
                    : '0'}
                </ListItem>
                <ListItem>
                  {t('app.debt')}:&nbsp;
                  {data?.getPaymentHistoryListByUserId?.balance &&
                  data?.getPaymentHistoryListByUserId?.balance < 0
                    ? data?.getPaymentHistoryListByUserId?.balance?.toFixed(2)
                    : '0'}
                </ListItem>
              </List>
              <ModalBox isOpen={isOpenUnitModal} setOpen={setIsOpenUnitModal}>
                {userData?.getUser?.entityLegalData?.id ? (
                  <AllInfoUrData
                    entityLegalData={userData?.getUser?.entityLegalData}
                  />
                ) : (
                  <AllInfoPasportData user={userData?.getUser} />
                )}
              </ModalBox>
              {currentUserData?.currentUser?.role === 'SUPER_ADMIN' ? (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  mr={4}
                  ml={2}
                >
                  <Button
                    variant='contained'
                    startIcon={<RemoveIcon style={{ color: COLORS.RED }} />}
                    onClick={() => {
                      setIsDebiting(true);
                      setIsFormOpened(true);
                      setisServiceFee(false);
                      setDestination('');
                    }}
                  >
                    {t('app.debit')}
                  </Button>
                  <Divider orientation='vertical' />
                  <Button
                    variant='contained'
                    startIcon={<AddIcon style={{ color: COLORS.GREEN }} />}
                    onClick={() => {
                      setIsDebiting(false);
                      setIsFormOpened(true);
                      setisServiceFee(false);
                      setDestination('');
                    }}
                  >
                    {t('app.credit')}
                  </Button>
                </Box>
              ) : null}
              <Box
                display='flex'
                alignItems='center'
                justifyContent='start'
                ml={2}
                mt={2}
              >
                <Button
                  variant='contained'
                  startIcon={<RemoveIcon style={{ color: COLORS.RED }} />}
                  onClick={() => {
                    setIsDebiting(true);
                    setIsFormOpened(true);
                    setisServiceFee(true);
                    setDestination('');
                    setServiceAmount(0);
                    setServiceQuantity(0);
                  }}
                >
                  {t('app.serviceFee')}
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ListItem style={{ marginTop: '48px' }}>
                <FindInPageIcon
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => setIsOpenUnitModal(true)}
                />
                {t('app.viewAllInfo')}
              </ListItem>

              <ListItem style={{ justifyContent: 'space-between' }}>
                {t('app.verification')}
                {userData?.getUser?.passportData ? (
                  <SwitcherVerification
                    value={userData?.getUser?.passportData.isDataVerified}
                    verifyPassportDataMutation={verifyPassportDataMutation}
                    id={userData?.getUser?.passportData?.id}
                  />
                ) : null}
                {userData?.getUser?.entityLegalData ? (
                  <SwitcherVerification
                    value={userData?.getUser?.entityLegalData?.isDataVerified}
                    verifyPassportDataMutation={verifyLegalDataMutation}
                    id={userData?.getUser?.entityLegalData?.id}
                    valueName='entityLegalDataId'
                  />
                ) : null}
              </ListItem>

              <Discounts
                discountData={userDiscount?.getUserDiscount as Discount}
                isAdmin={currentUserData?.currentUser?.role === 'SUPER_ADMIN'}
                setIsDiscountModalOpen={setIsDiscountModalOpen}
                setDiscountUserId={setDiscountUserId}
                setDiscountProperty={setDiscountProperty}
                userId={userId}
                setDiscountValue={setDiscountValue}
              />
            </Grid>
          </Grid>

          <If condition={isLoading}>
            <Then>
              <ShowLoadingText name={t('app.transactionHistory')} />
            </Then>
            <Else>
              <Box mt={2} mb={2}>
                <Typography variant='h6' className={classes.mainTitle}>
                  {t('app.operationHistory')}
                </Typography>
              </Box>
              <PaymentsTable
                transactions={
                  (data?.getPaymentHistoryListByUserId
                    ?.rows as Transaction[]) || []
                }
                count={data?.getPaymentHistoryListByUserId?.count || 1}
                page={filter.page}
                setFilter={setFilter}
              />
            </Else>
          </If>
        </Grid>
      </Grid>
      <ModalBox isOpen={isFormOpened} setOpen={setIsFormOpened}>
        <>
          <Box mt={2} mb={2}>
            <Typography variant='h3'>
              {isServiceFee
                ? t('app.serviceFee')
                : isDebiting
                ? t('app.debit')
                : t('app.credit')}
            </Typography>
          </Box>
          {isServiceFee ? (
            <>
              <FormControl>
                <InputLabel shrink={false} htmlFor='operation'>
                  {t('app.operation')}
                </InputLabel>
                <Autocomplete
                  className={classes.inputServiceOperation}
                  options={selectOptions}
                  filterOptions={(options, { inputValue }) =>
                    options.filter(
                      (option) =>
                        option?.title
                          ?.toLowerCase()
                          ?.includes(inputValue.toLowerCase()) ||
                        option?.code
                          ?.toLowerCase()
                          ?.includes(inputValue.toLowerCase()),
                    )
                  }
                  getOptionLabel={(option) => {
                    return `${option.code} ${option?.title}` || '';
                  }}
                  value={
                    selectOptions.find(
                      (option) =>
                        `${option.code} ${option.title}` === destination,
                    ) || null
                  }
                  onChange={(e, newValue) => {
                    setDestination(`${newValue?.code} ${newValue?.title}`);
                    setServiceAmount(Number(newValue?.costInUSD) * RATE_USD);
                  }}
                  renderOption={(option) => {
                    return <>{`${option.code} ${option?.title}`}</>;
                  }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          // autoComplete: 'my_services',
                        }}
                      />
                    </div>
                  )}
                />
              </FormControl>
              <InputLabel shrink={false} htmlFor='serviceQuantity'>
                {t('app.quantity')}
              </InputLabel>
              <Input
                className={classes.inputServiceOperation}
                id='serviceQuantity'
                disableUnderline
                value={serviceQuantity}
                inputProps={{
                  type: 'number',
                  min: 0,
                  step: 1,
                }}
                onChange={(e) => {
                  if (e.target.value && +e.target.value) {
                    setServiceQuantity(+e.target.value);
                  }
                }}
              />
              <InputLabel shrink={false} htmlFor='serviceAmount'>
                {t('app.unitPrice')}
              </InputLabel>
              <Input
                className={classes.inputServiceOperation}
                id='serviceAmount'
                disableUnderline
                value={serviceAmount}
                inputProps={{
                  type: 'number',
                  min: 0,
                  step: 0.1,
                }}
                onChange={(e) => {
                  if (e.target.value && +e.target.value) {
                    setServiceAmount(+e.target.value);
                  }
                }}
              />
              <InputLabel shrink={false} htmlFor='amount'>
                {t('app.amount')}
              </InputLabel>
              <Input
                className={classes.inputServiceOperation}
                id='amount'
                disableUnderline
                value={serviceAmount * serviceQuantity}
                disabled
              />
            </>
          ) : (
            <>
              <FormControl>
                <InputLabel shrink={false} htmlFor='destination'>
                  {t('app.purpose')}
                </InputLabel>

                <Autocomplete
                  className={classes.inputLabelOperation}
                  loadingText={`${t('app.loading')}...`}
                  noOptionsText={t('app.noHints')}
                  closeText={t('app.close')}
                  value={destination}
                  options={isDebiting ? [] : optionsNotForDebiting}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: isDebiting
                            ? 'debiting_destination'
                            : 'crediting_destination',
                        }}
                      />
                    </div>
                  )}
                  onInputChange={(e, newValue) => {
                    setDestination(newValue);
                  }}
                />
              </FormControl>

              <FormControl>
                <InputLabel shrink={false} htmlFor='transactionTypes'>
                  {t('app.transactionType')}
                </InputLabel>

                <Select
                  className={classes.inputLabelOperation}
                  disableUnderline
                  value={paymentType}
                  onChange={(e) => {
                    setPaymentType(e.target.value as TransactionTypes);
                  }}
                  displayEmpty
                >
                  {!!paymentTypesListData &&
                    paymentTypesListData?.getTransactionTypesList?.map(
                      (item) => (
                        <MenuItem
                          key={item?.id}
                          value={item?.paymentType as TransactionTypes}
                        >
                          {getPaymentTypeText(
                            item?.paymentType as TransactionTypes,
                          )}
                        </MenuItem>
                      ),
                    )}
                </Select>
              </FormControl>
              <InputLabel shrink={false} htmlFor='amount'>
                {t('app.amount')}
              </InputLabel>
              <Input
                id='amount'
                disableUnderline
                value={amount}
                inputProps={{
                  type: 'number',
                  min: 0,
                  step: 0.1,
                }}
                onChange={(e) => {
                  if (e.target.value && +e.target.value) {
                    setAmount(+e.target.value);
                  }
                }}
                startAdornment={
                  <InputAdornment position='start'>
                    {isDebiting ? <RemoveIcon /> : <AddIcon />}
                  </InputAdornment>
                }
              />
            </>
          )}

          <Box mt={2}>
            <Button
              variant='contained'
              startIcon={
                <SaveIcon
                  style={
                    isDebiting ? { color: COLORS.RED } : { color: COLORS.GREEN }
                  }
                />
              }
              disabled={
                isServiceFee
                  ? isServiceFeeDisabledButton
                  : isDebibtingDisabledButton
              }
              onClick={debitingAndCreditingFundsHandler}
            >
              {isDebiting ? t('app.debitT') : t('app.creditT')}
            </Button>
          </Box>
        </>
      </ModalBox>
      {/* @ts-ignore */}
      <ModalDiscount
        isDiscountModalOpen={isDiscountModalOpen}
        setIsDiscountModalOpen={setIsDiscountModalOpen}
        discountUserId={userId}
        discountValue={discountValue}
        setDiscountValueError={setDiscountValueError}
        setDiscountValue={setDiscountValue}
        discountValueError={discountValueError}
        classes={classes}
        setDiscountUserId={setDiscountUserId}
        enqueueSnackbar={enqueueSnackbar}
        isUser
        userName={userData?.getUser?.name}
        userRefetch={userRefetch}
        isDiscount={isDiscount}
        discountProperty={discountProperty}
      />
      {/* @ts-ignore */}
      <ModalCredit
        isCreditModalOpen={isCreditModalOpen}
        setIsCreditModalOpen={setIsCreditModalOpen}
        classes={classes}
        userId={userId}
        handleSetCreditLimit={handleSetCreditLimit}
        userCreditLimit={Number(userData?.getUser?.creditLimit)}
      />
    </>
  );
}
