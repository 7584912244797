import React from 'react';
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LinearProgress } from '@material-ui/core';
import PrivateLayout from './PrivateLayout/PrivateLayout';
import PublicLayout from './PublicLayout';
import getCurrentUserQuery from '../GraphQL/queries/getCurrentUser';
import { useLocation } from 'react-router';
import { LOCAL_STORAGE_KEYS } from '../utils/constants';

export default function Routes() {
  const { enqueueSnackbar } = useSnackbar();

  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);

  const location = useLocation();

  const isSignInPage = location.pathname.includes('sign-in');

  // Don't download initial data here (except currentUser) because they will not download again after relogin
  const { loading, error, data } = useQuery(getCurrentUserQuery);

  if (loading) return <LinearProgress style={{ height: 10 }} />;
  if (error) {
    !isSignInPage && enqueueSnackbar(error.message, { variant: 'error' });
    return <PublicLayout />;
  }

  if (data.currentUser && token) {
    return <PrivateLayout user={data.currentUser} />;
  } else {
    return <PublicLayout />;
  }
}
