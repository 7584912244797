import React from 'react';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TRoutes } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

interface PaymentStatisticsRowItemProps {
  payment: {
    id: string;
    name: string;
    company: string;
    email: string;
    income: string;
    paymentParcel: string;
  };
}
const PaymentStatisticsRowItem: React.FC<PaymentStatisticsRowItemProps> = ({
  payment,
}) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell align='center'>{payment.id}</TableCell>
      <TableCell align='center'>
        <Tooltip title={t('app.clickToViewStatistics')}>
          <Link
            to={TRoutes.PAYMENT_STATISTICS_PER_USER.replace(
              ':userId',
              payment.id,
            )}
          >
            {payment.name}
          </Link>
        </Tooltip>
      </TableCell>
      <TableCell align='center'>{payment.company}</TableCell>
      <TableCell align='center'>
        {payment.email ? (
          <a href={`mailto:${payment.email}`} target='_blank' rel='noreferrer'>
            {payment.email}
          </a>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align='center'>{payment.income}</TableCell>
      <TableCell align='center'>{payment.paymentParcel}</TableCell>
    </TableRow>
  );
};

export default PaymentStatisticsRowItem;
