import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { DATE_FORMAT, LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import {
  WarehouseCellProduct,
  useGetWarehouseZoneCellsResiduesLazyQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { LIMIT_ITEM_PAGE, TRoutes } from '../../../utils/helpers';
import SearchIcon from '@material-ui/icons/Search';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ISwapHistoryFilter {
  page: number;
  itemsLimit: number;
  search: string;
}

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

const Residues = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [residuesData, setResiduesData] = useState<WarehouseCellProduct[]>([]);

  const { t } = useTranslation();

  const [filter, setFilter] = useState<ISwapHistoryFilter>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    search: '',
  });

  const [getResidues, { data, loading }] =
    useGetWarehouseZoneCellsResiduesLazyQuery({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    });

  useEffect(() => {
    if (!data?.getWarehouseZoneCellsResidues?.rows) return;

    data?.getWarehouseZoneCellsResidues?.rows &&
      setResiduesData(
        data?.getWarehouseZoneCellsResidues?.rows as WarehouseCellProduct[],
      );

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    refetchResidues();
    // eslint-disable-next-line
  }, [filter]);

  const refetchResidues = useDebouncedCallback(() => {
    getResidues({
      variables: {
        options: {
          limit: filter.itemsLimit,
          offset: (filter.page - 1) * filter.itemsLimit,
          search: filter.search,
        },
      },
    });
  }, 400);

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {t('app.remainsByCells')}
      </Typography>

      <div className={classes.filters}>
        <FormControl className={classes.filtersItemSelect}>
          <BoxCentered pl={2}>
            <span>{t('app.show')}</span>
            <Select
              displayEmpty
              disableUnderline
              value={filter.itemsLimit}
              onChange={(e) => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.ITEMS_LIMIT,
                  //@ts-ignore
                  e.target.value,
                );
                //@ts-ignore
                setFilter((state) => ({
                  ...state,
                  itemsLimit: e.target.value,
                  page: 1,
                }));
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </BoxCentered>
        </FormControl>
        <form
          className={classes.filtersSearch}
          onSubmit={(e) => {
            e.preventDefault();
            refetchResidues();
          }}
        >
          <Input
            placeholder={t('app.enterText')}
            className={classes.filtersSearchField}
            onChange={(e) => {
              setFilter((state) => ({
                ...state,
                search: e.target.value,
                page: 1,
              }));
            }}
            value={filter.search}
            disableUnderline
          />
          <Button
            className={classes.filtersSearchSubmit}
            variant='contained'
            type='submit'
            aria-label='search'
          >
            <SearchIcon />
          </Button>
        </form>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>{t('app.date')}</TableCell>
              <TableCell align='center'>{t('app.productName')}</TableCell>
              <TableCell align='center'>SKU</TableCell>
              <TableCell align='center'>{t('app.cell')}</TableCell>
              <TableCell align='center'>{t('app.quantity')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {residuesData.length ? (
              loading ? (
                <TableRow>
                  <TableCell colSpan={9} align='center'>
                    <ShowLoadingText name={t('app.productsR')} />
                  </TableCell>
                </TableRow>
              ) : (
                residuesData?.map((residue) => {
                  return (
                    residue && (
                      <TableRow>
                        <TableCell align='center'>
                          <Link
                            to={TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS.replace(
                              ':id',
                              residue?.product?.id.toString() as string,
                            )}
                          >
                            {residue.product?.barcodeId?.split('.')[0]}
                          </Link>
                        </TableCell>
                        <TableCell align='center'>
                          {moment(Number(residue.product?.createdAt)).format(
                            DATE_FORMAT,
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          {residue.product?.name}
                        </TableCell>
                        <TableCell align='center'>
                          {residue.product?.sku}
                        </TableCell>
                        <TableCell align='center'>{residue.cellName}</TableCell>
                        <TableCell align='center'>{residue.quantity}</TableCell>
                      </TableRow>
                    )
                  );
                })
              )
            ) : (
              <TableRow>
                <TableCell colSpan={12} align='center'>
                  {t('app.noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classes.pagination}
        count={
          data
            ? Math.ceil(
                //@ts-ignore
                data?.getSwapHistoryWarehouseCellProduct?.count /
                  filter.itemsLimit,
              )
            : 1
        }
        page={filter.page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />
    </Container>
  );
};

export default Residues;
