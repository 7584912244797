import { IconButton, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { COLORS } from '../../utils/constants';
import useStyles from './styles';
import { Maybe, UserRoles } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import BuildIcon from '@material-ui/icons/Build';
import { getOptions } from './options';
import MenuItemSelect from './MenuItemSelect';

interface ISettingsSelect {
  userRole?: Maybe<UserRoles>;
}

const SettingsSelect = ({ userRole }: ISettingsSelect) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const isSuperAdmin = userRole === UserRoles.SuperAdmin;

  const options = getOptions(isSuperAdmin);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      className={classes.userSettingsSelect}
      value={0}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <MenuItem value={0} style={{ display: 'none' }}>
        <IconButton className={classes.personIcon}>
          <BuildIcon style={{ color: COLORS.BLACK, width: '20px' }} />
        </IconButton>
        {t('app.settings')}
      </MenuItem>

      {options.map(({ link, title, Icon, imgSrc, tooltipText }) => (
        <MenuItemSelect
          key={title}
          link={link}
          title={title}
          Icon={Icon}
          imgSrc={imgSrc}
          tooltipText={tooltipText}
          onClick={handleClose}
        />
      ))}
    </Select>
  );
};

export default SettingsSelect;
