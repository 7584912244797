import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  addWarehouseBtn: {
    margin: '30px 0 30px',
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
  addIcon: {
    fill: '#1464f6',
  },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  inputLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  },
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  phoneInputWarning: {
    color: '#ff9800',
  },
  phoneInput: {
    marginRight: '40px',
    marginTop: '20px',

    '& .PhoneInputInput': {
      color: '#000',
      border: '1px solid #4f4f4f',
      backgroundColor: 'transparent',
      width: '100%',
      height: 'auto',
      padding: '13px 16px',
      fontSize: '14px',
      lineHeight: '20px',

      '& input:focus': {
        outline: 'none',
      },
    },
  },
}));
