import React from 'react';
import { Container } from '@material-ui/core';
import PublicRoutes from '../../routes/PublicRoutes';
import useStyles from './styles';

export default function PublicLayout() {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <div className={classes.wrapper}>
        <PublicRoutes />
      </div>
    </Container>
  );
}
