import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
} from '@material-ui/core';
import { FastField, FieldProps } from 'formik';
import React from 'react';
import CreateIcon from '@material-ui/icons/Create';
import { TProps } from './types';
import { useTranslation } from 'react-i18next';

const InputCalculator = ({
  classes,
  setFieldValue,
  label,
  name,
  inputProps,
  startAdornment,
  setModalName,
  fullWidth = false,
  setIsOpenModal = () => {},
  isButtonForOpenModal = false,
  isString = false,
}: TProps) => {
  const { t } = useTranslation();
  const handleModalOpen = () => {
    setIsOpenModal(true);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {label}
      </Grid>
      <Grid item xs={12} sm={4}>
        <FastField name={name}>
          {({ field: { value, ...field }, meta }: FieldProps) =>
            isButtonForOpenModal ? (
              <IconButton
                onClick={() => {
                  handleModalOpen();
                  setModalName(name);
                }}
              >
                <CreateIcon />
              </IconButton>
            ) : (
              <FormControl error={meta.touched && !!meta.error}>
                <Input
                  disableUnderline
                  id={name}
                  defaultValue={value}
                  className={fullWidth ? '' : classes.inputNumber}
                  {...field}
                  startAdornment={startAdornment}
                  inputProps={inputProps}
                  onChange={(event) => {
                    if (isString) setFieldValue(name, event.target.value);
                    else setFieldValue(name, +event.target.value);
                  }}
                />
                {meta.touched && !!meta.error && (
                  <FormHelperText>{t(meta.error)}</FormHelperText>
                )}
              </FormControl>
            )
          }
        </FastField>
      </Grid>
    </Grid>
  );
};

export default InputCalculator;
