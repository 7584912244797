import React from 'react';
export interface StoreSchema {
  statuses: { payment: never[]; delivery: never[] };
  setPaymentStatuses: React.Dispatch<React.SetStateAction<never[]>>;
  setDeliveryStatuses: React.Dispatch<React.SetStateAction<never[]>>;
}

export const StoreContext = React.createContext<StoreSchema>({
  statuses: {
    payment: [],
    delivery: [],
  },
  setPaymentStatuses: () => {},
  setDeliveryStatuses: () => {},
});

const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [getPaymentStatuses, setPaymentStatuses] = React.useState([]);
  const [getDeliveryStatuses, setDeliveryStatuses] = React.useState([]);

  const store = {
    statuses: {
      payment: getPaymentStatuses,
      delivery: getDeliveryStatuses,
    },
    setPaymentStatuses,
    setDeliveryStatuses,
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
