import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Typography,
} from '@material-ui/core';
import { FastField, FieldProps, useFormikContext } from 'formik';
import React from 'react';
import { COLORS, TariffOptions } from '../../../utils/constants';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import useStyles from './styles';
import { ISettingsCalculatorModalOptionsComponent } from './types';

const SettingsCalculatorModalOptionsComponent = ({
  valuesOptions,
  inputFieldName,
  i,
  getLabel,
  getInputProps,
  modalValues,
  handleRemoveRow,
  handleAddRow,
  arrayHelpers,
}: ISettingsCalculatorModalOptionsComponent) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const isFirstElement = !i && modalValues.length === 1;
  const isLastElement = i === modalValues.length - 1 && !!i;

  return (
    <Box
      key={inputFieldName}
      display='flex'
      flexDirection='row'
      style={{ gap: '20px' }}
    >
      {valuesOptions?.map((option, index) => {
        const name = `${inputFieldName}[${i}].${option}`;

        return (
          <Box
            key={name}
            display='flex'
            flexDirection='column'
            justifyContent='center'
          >
            {!i && <Typography>{getLabel(option)}</Typography>}
            <Box display='flex' flexDirection='row' alignItems='flex-end'>
              <FastField name={name}>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl
                    error={meta.touched && !!meta.error}
                    className={classes.inputLabel}
                  >
                    <Input
                      disableUnderline
                      id={name}
                      defaultValue={value}
                      {...field}
                      inputProps={getInputProps(option)}
                      onChange={(event) => {
                        setFieldValue(
                          name,
                          option === TariffOptions.NAME
                            ? event.target.value
                            : +event.target.value,
                        );
                      }}
                    />
                    {meta.touched && !!meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>

              {index === valuesOptions.length - 1 ? (
                <Box display='flex' flexDirection='row'>
                  {isLastElement && (
                    <>
                      <IconButton onClick={handleRemoveRow(arrayHelpers, i)}>
                        <DeleteForeverIcon style={{ color: COLORS.RED }} />
                      </IconButton>
                      <IconButton onClick={handleAddRow(arrayHelpers)}>
                        <AddIcon />
                      </IconButton>
                    </>
                  )}
                  {isFirstElement && (
                    <IconButton onClick={handleAddRow(arrayHelpers)}>
                      <AddIcon />
                    </IconButton>
                  )}
                </Box>
              ) : null}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SettingsCalculatorModalOptionsComponent;
