import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    marginTop: 25,

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  filtersSearch: {
    display: 'flex',
    marginLeft: 'auto',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filtersSearchField: {
    width: 162,
    height: 44,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  filtersSearchSubmit: {
    backgroundColor: '#000000',
    padding: 0,
    minWidth: 'auto',
    width: 44,
    height: 44,
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  pagination: {
    fontWeight: 'bold',
    marginTop: 32,
    '& .MuiPagination-ul': {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'nowrap',
        justifyContent: 'center',
      },
    },
    '& .MuiButtonBase-root': {
      width: 44,
      height: 44,
      borderRadius: '50%',
      padding: 0,
      margin: 0,
      marginRight: 8,

      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 35,
        height: 35,
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      width: 12,
      height: 44,
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      minWidth: 'auto',
      marginRight: 8,
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '25px',
    },
  },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  errorMsg: {
    color: 'red',
  },
  filtersItemSelect: {
    width: 'auto',
    marginRight: '16px',
    '& .MuiInput-formControl': {
      border: 'none',

      [theme.breakpoints.down('xs')]: {
        '& .MuiInput-input': {
          paddingLeft: 0,
        },
      },
    },
  },
}));
