import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainTitle: {
    textAlign: 'center',
  },
  placeholder: {
    color: 'grey',
  },
  selected: {
    margin: '25px 0px',
  },
  filtered: {
    width: '110px',
    padding: '5px 16px',
  },
  icon: {
    top: '0px',
    width: '20%',
    height: '100%',
    background: 'grey',
  },
  pagination: {
    marginTop: '32px',
    fontWeight: 'bold',
  },

  wrapperOuter: {
    fontSize: '16px',
    width: '800px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  wrapperInner: {
    color: 'write',
    maxWidth: '300px',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  icons: {
    marginRight: '20px !important',
  },
  alertWrapper: {
    display: 'flex',
    height: '70px',
    border: '1px solid #d7d713',
    marginTop: '20px',
  },
  alertIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70px',
    borderRight: '1px solid #d7d713',
  },
  alertTextWrapper: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonAddWrapper: {
    marginTop: '20px',
    display: 'flex',
    cursor: 'pointer',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
  },
  inputLabelOperation: {
    maxWidth: '268px',
  },
  inputServiceOperation: {
    minWidth: '468px',
  },
}));
