import React, { useEffect, useState } from 'react';

import useStyles from './styles';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import HomeIcon from '@material-ui/icons/Home';
import { Box, Grid } from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { RenderCountryFlagImage } from '../../utils/helperComponents';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BusinessIcon from '@material-ui/icons/Business';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

export const AllInfoPasportData = ({ user }: any) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { file } = useGetImg(user?.passportData?.scanPath);

  return (
    <Grid container spacing={3} className={classes.wrapperOuter}>
      <Grid item md={6} style={{ display: 'flex' }}>
        <Box className={classes.wrapperInner}>
          <Box className={classes.line}>
            <AccessibilityIcon className={classes.icons} /> {user?.name}
          </Box>
          <Box className={classes.line}>
            <BusinessIcon className={classes.icons} /> {user?.company}
          </Box>
          <Box className={classes.line}>
            <HomeIcon className={classes.icons} /> {user?.address}
          </Box>
          <Box className={classes.line}>
            <LocationCityIcon className={classes.icons} /> {user?.city}{' '}
            {user?.state}
          </Box>
          <Box className={classes.line}>
            <RenderCountryFlagImage
              countryISO={user?.country?.iso as string}
              countryName={user?.country?.name as string}
              className={classes.icons}
            />
            {user?.country?.name}
          </Box>
          <Box className={classes.line}>
            <PhoneIcon className={classes.icons} /> {user?.phone}
          </Box>
          <Box className={classes.line}>
            <EmailIcon className={classes.icons} /> {user?.email}
          </Box>
        </Box>
        <Box>{/* <EditIcon style={{ cursor: 'pointer' }} /> */}</Box>
      </Grid>
      {user?.passportData ? (
        <Grid item md={6} style={{ display: 'flex' }}>
          <Box className={classes.wrapperInner}>
            <Box className={classes.line}>{t('app.passportData')}</Box>
            <Box className={classes.line}>
              {`${t('app.fullName')}: ${user?.passportData?.lastName} ${
                user?.passportData?.firstName
              } ${user?.passportData?.middleName}`}
            </Box>
            <Box className={classes.line}>
              {`${t('app.dateOfBirth')}: ${user?.passportData?.birthDay}`}
            </Box>
            <Box className={classes.line}>
              {`${t('app.seriesAndNumber')}: ${
                user?.passportData?.seriesAndNumberOfPassport
              }`}
            </Box>
            <Box className={classes.line}>
              {`${t('app.dateOfIssue')}: ${
                user?.passportData?.dateOfIssueOfTheDocument
              }`}
            </Box>
            <Box className={classes.line}>
              {`${t('app.tin')}: ${user?.passportData?.inn}`}
            </Box>
            {file ? (
              <Box className={classes.line}>
                <a href={file as any} target='_blank' rel='noreferrer'>
                  {t('app.passportScan')}
                </a>
              </Box>
            ) : null}
          </Box>
          <Box>
            {user?.passportData?.isDataVerified ? <VerifiedUserIcon /> : null}
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

const useGetImg = (pathImg: string) => {
  const [file, setFile] = useState(null);
  let path =
    process.env.REACT_APP_API_DOMAIN + '/api/get-file/passportData/' + pathImg;

  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  useEffect(() => {
    fetch(path, {
      method: 'GET',
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        setFile(window.URL.createObjectURL(blob) as any);
      })
      .catch((error) => {
        console.info(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { file };
};
