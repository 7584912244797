import React from 'react';
import { Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { GetCarrierCodeImage } from '../../utils/helperComponents';
import { TRoutes } from '../../utils/helpers';
import styles from './styles';

interface ITrackTrackNumberComponent {
  carrierCode?: string | null;
  trackNumber: string;
}

const TrackTrackNumberComponent = ({
  carrierCode,
  trackNumber,
}: ITrackTrackNumberComponent) => {
  const { t } = useTranslation();
  const classes = styles();
  return (
    <BoxCentered>
      {carrierCode ? <GetCarrierCodeImage carrierCode={carrierCode} /> : null}
      <Tooltip
        title={`${t('app.trackShipment')} ${
          carrierCode ? carrierCode.toUpperCase() : ''
        } ${trackNumber}`}
      >
        <NavLink
          className={classes.btnIcon}
          to={`${TRoutes.TRACK}/${carrierCode}/${trackNumber}`}
        >
          <span>{trackNumber}</span>
        </NavLink>
      </Tooltip>
    </BoxCentered>
  );
};

export default TrackTrackNumberComponent;
