import React, { useEffect, useState } from 'react';
import ModalBox from '../ModalBox/ModalBox';
import { Formik } from 'formik';

import {
  SendWarehouseShipmentOrderInput,
  SendWarehouseShipmentOrderWithoutBuyLabelInput,
  useGetWarehouseShipmentOrderByIdLazyQuery,
  useSendWarehouseShipmentOrderMutation,
  useSendWarehouseShipmentOrderWihtoutBuyLabelMutation,
  WarehouseShipmentOrder,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import SendWarehouseShipmentForm from './SendWarehouseShipmentForm/SendWarehouseShipmentForm';
import { createWarehouseShipmentOrderInitValue } from './createInitValues';
import { useTranslation } from 'react-i18next';
import { QUERY_WAREHOUSE_SHIPMENT_ORDERS } from '../../GraphQL/queries/getWarehouseShipmentOrders';

const SendWarehouseShipmentOrder: React.FC<{
  isOpenSendParcel: boolean;
  setOpenSendParcel: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  isWithLabel: boolean;
}> = ({ isOpenSendParcel, setOpenSendParcel, id, isWithLabel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isMessage, setIsMessage] = useState<boolean>(false);

  const { t } = useTranslation();

  const [warehouseShipmentOrder, setWarehouseShipmentOrder] =
    useState<WarehouseShipmentOrder | null>(null);

  const [sendWarehouseSipmentOrderMutation] =
    useSendWarehouseShipmentOrderMutation();

  const [sendWarehouseSipmentOrderWihtoutBuyLabelMutation] =
    useSendWarehouseShipmentOrderWihtoutBuyLabelMutation();

  const [getWarehouseShipmentOrderByIdLazyQuery, { data, error, loading }] =
    useGetWarehouseShipmentOrderByIdLazyQuery();

  const fetchWarehouseShipmentOrderById = (id: string) => {
    getWarehouseShipmentOrderByIdLazyQuery({
      variables: {
        id,
      },
    });
  };

  useEffect(() => {
    if (!id) return;

    fetchWarehouseShipmentOrderById(id);
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (!data?.getWarehouseShipmentOrderById) return;
    data?.getWarehouseShipmentOrderById &&
      setWarehouseShipmentOrder(
        data?.getWarehouseShipmentOrderById as WarehouseShipmentOrder,
      );
    //eslint-disable-next-line
  }, [data]);

  error?.message && enqueueSnackbar(error?.message, { variant: 'error' });

  const handlerSendWarehouseOrder = (
    data:
      | SendWarehouseShipmentOrderInput
      | SendWarehouseShipmentOrderWithoutBuyLabelInput,
  ) => {
    if (isWithLabel) {
      sendWarehouseSipmentOrderMutation({
        variables: {
          data: {
            ...(data as SendWarehouseShipmentOrderInput),
            message: isMessage ? data.message : null,
          },
        },
        onCompleted: (data) => {
          if (data) {
            enqueueSnackbar(t('app.orderSent'), { variant: 'success' });
            setOpenSendParcel(false);
          }
        },
        onError: (error) => {
          if (error) {
            enqueueSnackbar(error?.message, { variant: 'error' });
          }
        },
        refetchQueries: [QUERY_WAREHOUSE_SHIPMENT_ORDERS],
      });
    } else {
      sendWarehouseSipmentOrderWihtoutBuyLabelMutation({
        variables: {
          data: {
            ...(data as SendWarehouseShipmentOrderWithoutBuyLabelInput),
            message: isMessage ? data.message : null,
          },
        },
        onCompleted: (data) => {
          if (data) {
            enqueueSnackbar(t('app.orderSent'), { variant: 'success' });
            setOpenSendParcel(false);
          }
        },
        onError: (error) => {
          if (error) {
            enqueueSnackbar(error?.message, { variant: 'error' });
          }
        },
        refetchQueries: [QUERY_WAREHOUSE_SHIPMENT_ORDERS],
      });
    }
  };

  return (
    <ModalBox isOpen={isOpenSendParcel} setOpen={setOpenSendParcel}>
      <Formik
        initialValues={createWarehouseShipmentOrderInitValue(
          warehouseShipmentOrder,
          isWithLabel,
        )}
        enableReinitialize
        onSubmit={handlerSendWarehouseOrder}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <SendWarehouseShipmentForm
            values={values}
            setFieldValue={setFieldValue}
            warehouseShipmentOrder={warehouseShipmentOrder}
            setOpenSendParcel={setOpenSendParcel}
            loading={loading}
            isMessage={isMessage}
            setIsMessage={setIsMessage}
            isSubmitting={isSubmitting}
            isWithLabel={isWithLabel}
          />
        )}
      </Formik>
    </ModalBox>
  );
};

export default SendWarehouseShipmentOrder;
