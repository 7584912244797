import { gql } from '@apollo/client';

export const QUERY_NOTIFICATION_LIST = gql`
  query GetNotificationList($limit: Int, $offset: Int) {
    getNotificationList(limit: $limit, offset: $offset) {
      rows {
        data
        isRead
        text
        sendAt
        topic
      }
      count
    }
  }
`;
