import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export default makeStyles((theme) => ({
  addWarehouseBtn: {
    margin: '30px 0 30px',
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
  addIcon: {
    fill: '#1464f6',
  },
  actionMenuItem: {
    padding: 0,
  },
  actionIconWatch: {
    color: 'green',
  },
  actionLink: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    textDecoration: 'none',
    padding: '6px 16px',
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
  },
  actionIconCancel: {
    color: COLORS.RED,
  },
  actionIconSend: {
    color: COLORS.BLUE,
  },
}));
