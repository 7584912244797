import { Switch } from '@material-ui/core';
import React, { useState } from 'react';

interface SwitcherVerificationType {
  value: boolean;
  verifyPassportDataMutation: any;
  id: number;
  valueName?: string;
}

export const SwitcherVerification: React.FC<SwitcherVerificationType> = ({
  value,
  valueName = 'passportId',
  verifyPassportDataMutation,
  id,
}) => {
  const [checked, setChecked] = useState(value);

  const ToggleChecked = () => {
    setChecked((prev) => !prev);
    verifyPassportDataMutation({
      variables: {
        [valueName]: id,
      },
    }).catch((error: Error) => {
      console.info(error);
    });
  };

  return <Switch checked={checked} onChange={ToggleChecked} />;
};
