import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { TRoutes } from '../../../utils/helpers';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  SenderAccountInput,
  useCreateSenderAccountMutation,
  useGetSenderAccountByIdLazyQuery,
  useUpdateSenderAccountMutation,
  useGetCurrentUserQuery,
  ServiceNameAvailableVariants,
} from '../../../generated/graphql';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { useParams } from 'react-router-dom';
import { When } from 'react-if';
import useStyles from './styles';
import { SenderAccountSchema } from '../../../utils/validationSchemes';
import { useTranslation } from 'react-i18next';

const SenderAccountsForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const useMatch = useRouteMatch<{
    id?: string;
    type?: string;
  }>();
  const isCreateMode =
    useMatch.path === TRoutes.SETTINGS_SENDER_ACCOUNTS_CREATE;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateView, setUpdateView] = useState(0);
  const params = useParams<{ parcelAmountParam?: string }>();

  const { t } = useTranslation();

  const { data: userData } = useGetCurrentUserQuery({});
  const classes = useStyles();

  const [
    getSenderAccountByIdLazy,
    {
      loading: isLoadingGetSenderAccountByIdLazy,
      error: errorGetSenderAccountByIdLazy,
      data: dataSenderAccountByIdLazyQuery,
    },
  ] = useGetSenderAccountByIdLazyQuery();

  const [create, { loading: isLoadingCreate }] =
    useCreateSenderAccountMutation();
  const [update, { loading: isLoadingUpdate }] =
    useUpdateSenderAccountMutation();

  useEffect(() => {
    if (isCreateMode) return;

    if (useMatch.params.id && +useMatch.params.id) {
      getSenderAccountByIdLazy({
        variables: {
          id: +useMatch.params.id,
        },
      });
    } else {
      history.push(TRoutes.NOT_FOUND);
    }
    // eslint-disable-next-line
  }, [useMatch.params.id]);

  useEffect(() => {
    setUpdateView((value) => ++value);
    // eslint-disable-next-line
  }, [dataSenderAccountByIdLazyQuery]);

  errorGetSenderAccountByIdLazy?.message &&
    enqueueSnackbar(errorGetSenderAccountByIdLazy?.message, {
      variant: 'error',
    });

  if (isLoadingGetSenderAccountByIdLazy) {
    return <ShowLoadingText name={t('app.contacts')} />;
  }

  if (!isCreateMode && !dataSenderAccountByIdLazyQuery) {
    return <ShowLoadingText name={t('app.contacts')} />;
  }

  const handlerSubmit = (
    values: SenderAccountInput,
    { setSubmitting }: FormikHelpers<SenderAccountInput>,
  ) => {
    //@ts-ignore
    const inputValues: SenderAccountInput = {
      accountName: values.accountName,
      status: values.status,
      accountNumber: values.accountNumber,
      password: values.password,
      siteId: values.siteId,
      requestorName: values.requestorName,
      requestorPhone: values.requestorPhone,
      requestorAddress: values.requestorAddress,
      requestorCity: values.requestorCity,
      requestorPostalCode: values.requestorPostalCode,
      TaxIdentificationNumber: values.TaxIdentificationNumber
        ? values.TaxIdentificationNumber
        : `${values.siteId}`,
      attentionName: values.attentionName,
      clientIdFEDEX: values.clientIdFEDEX,
      clientSecretFEDEX: values.clientSecretFEDEX,
      userNameUPS: values.userNameUPS,
      userPasswordUPS: values.userPasswordUPS,
      accessLicenseNumberUPS: values.accessLicenseNumberUPS,
      serviceName: values.serviceName,
      requestorCountryCode: values.requestorCountryCode,
      isTestMode: values.isTestMode,
      easypostProdApiKey: values.easypostProdApiKey,
      easypostTestApiKey: values.easypostTestApiKey,
    };

    if (isCreateMode) {
      create({
        variables: { input: inputValues },
      })
        .then(() => {
          enqueueSnackbar(t('app.accountSuccessfullySaved'), {
            variant: 'success',
          });
          history.push(TRoutes.SETTINGS_SENDER_ACCOUNTS);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        })
        .finally(() => setSubmitting(false));
    } else {
      if (useMatch.params.id) {
        delete inputValues.serviceName;
        update({
          variables: {
            input: {
              ...inputValues,
              id: +useMatch.params.id,
            },
          },
        })
          .then(() => {
            enqueueSnackbar(t('app.accountSuccessfullySaved'), {
              variant: 'success',
            });
            history.push(TRoutes.SETTINGS_SENDER_ACCOUNTS);
          })
          .catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    }
  };

  //@ts-ignore
  const initialValues: SenderAccountInput = isCreateMode
    ? {
        //@ts-ignore
        serviceName: `${params.type}`.toUpperCase(),
        siteId: '',
        accountNumber: '',
        accountName: '',
        password: '',
        status: true,
        requestorPostalCode: userData?.currentUser?.zipCode,
        requestorPhone: userData?.currentUser?.phone,
        requestorName:
          userData?.currentUser?.passportData?.firstName ||
          userData?.currentUser?.entityLegalData?.generalManager,
        requestorCountryCode: 'RU',
        requestorCity: userData?.currentUser?.city,
        requestorAddress: userData?.currentUser?.address,
        isTestMode: true,
      }
    : {
        serviceName:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.serviceName ||
          '',
        siteId:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.siteId || '',
        accountNumber:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.accountNumber ||
          '',
        accountName:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.accountName ||
          '',
        password:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.password || '',
        status: !!dataSenderAccountByIdLazyQuery?.getSenderAccountById?.status,
        requestorPostalCode:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.requestorPostalCode || '',
        requestorPhone:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.requestorPhone || '',
        requestorName:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.requestorName ||
          '',
        requestorCountryCode:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.requestorCountryCode || 'RU',
        requestorCity:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.requestorCity ||
          '',
        requestorAddress:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.requestorAddress || '',
        isTestMode:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.isTestMode,
        TaxIdentificationNumber:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.TaxIdentificationNumber,
        attentionName:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.attentionName,
        clientIdFEDEX:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.clientIdFEDEX,
        clientSecretFEDEX:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.clientSecretFEDEX,
        userNameUPS:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.userNameUPS,
        userPasswordUPS:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById?.userPasswordUPS,
        accessLicenseNumberUPS:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.accessLicenseNumberUPS,
        easypostTestApiKey:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.easypostTestApiKey,
        easypostProdApiKey:
          dataSenderAccountByIdLazyQuery?.getSenderAccountById
            ?.easypostProdApiKey,
      };

  return (
    <div>
      <Typography variant='h2' align='center'>
        {isCreateMode ? t('app.creation') : t('app.editing')}{' '}
        {t('app.senderAccount')}
      </Typography>
      <Divider />

      <Formik
        onSubmit={handlerSubmit}
        initialValues={initialValues}
        validationSchema={SenderAccountSchema}
      >
        {({ setFieldValue, values, setValues, errors }) => (
          <Form>
            <FastField name='serviceName'>
              {({ field: { value }, meta }: FieldProps) => (
                <BoxCentered mt={2} mb={2}>
                  <FormControl
                    component='fieldset'
                    error={meta.touched && !!meta.error}
                  >
                    <FormLabel component='legend'>{t('app.carrier')}</FormLabel>
                    <RadioGroup
                      row
                      name='sender-account-type'
                      value={value}
                      onChange={(e) => {
                        setValues({
                          ...initialValues,
                          serviceName: e.target
                            .value as ServiceNameAvailableVariants,
                        });
                      }}
                    >
                      <FormControlLabel
                        value='DHL'
                        label='DHL'
                        disabled={!isCreateMode}
                        control={<Radio />}
                      />

                      <FormControlLabel
                        value='TNT'
                        label='TNT'
                        disabled
                        control={<Radio />}
                      />

                      <FormControlLabel
                        value='FEDEX'
                        label='Fedex'
                        disabled={!isCreateMode}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value='EASYPOST'
                        label='Easypost'
                        disabled={!isCreateMode}
                        control={<Radio />}
                      />

                      <FormControlLabel
                        value='UPS'
                        label='UPS'
                        disabled={!isCreateMode}
                        control={<Radio />}
                      />

                      <FormControlLabel
                        value='USPS'
                        label='USPS'
                        disabled
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </FormControl>
                </BoxCentered>
              )}
            </FastField>
            <FastField name='accountName'>
              {({ field: { value, ...field }, meta }: FieldProps) => (
                <FormControl error={meta.touched && !!meta.error}>
                  <InputLabel htmlFor='accountName'>
                    {t('app.accountName')} *
                  </InputLabel>
                  <Input
                    id='accountName'
                    defaultValue={value}
                    disableUnderline
                    {...field}
                  />
                  {meta.touched && meta.error && (
                    <FormHelperText>{meta.error}</FormHelperText>
                  )}
                </FormControl>
              )}
            </FastField>
            <When condition={values?.serviceName?.toUpperCase() === 'DHL'}>
              <FastField name='status'>
                {({ field: { value } }: FieldProps) => (
                  <FormControl>
                    <InputLabel className={classes.fildMargin}>
                      {t('app.status')}:{' '}
                      {!!value ? t('app.active') : t('app.switchedOff')}
                    </InputLabel>
                    <Switch
                      checked={!!value}
                      onChange={() => {
                        setFieldValue('status', !value);
                      }}
                    />
                  </FormControl>
                )}
              </FastField>

              <FastField name='accountNumber' key='accountNumber'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='accountNumber'
                      className={classes.fildMargin}
                    >
                      {t('app.accountNumber')}{' '}
                      {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='accountNumber'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
            </When>
            <When condition={values?.serviceName?.toUpperCase() === 'FEDEX'}>
              <FastField name='clientIdFEDEX' key='clientIdFEDEX'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='clientIdFEDEX'
                      className={classes.fildMargin}
                    >
                      Client Id {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='clientIdFEDEX'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
              <FastField name='clientSecretFEDEX' key='clientSecretFEDEX'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='clientSecretFEDEX'
                      className={classes.fildMargin}
                    >
                      Client Secret {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='clientSecretFEDEX'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
              <FastField
                name='TaxIdentificationNumber'
                key='TaxIdentificationNumber'
              >
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='TaxIdentificationNumber'
                      className={classes.fildMargin}
                    >
                      Tax Identification Number{' '}
                      {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='TaxIdentificationNumber'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
              <FastField name='accountNumber' key='accountNumber'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='accountNumber'
                      className={classes.fildMargin}
                    >
                      account Number {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='accountNumber'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
            </When>
            <When condition={values?.serviceName?.toUpperCase() === 'UPS'}>
              <FastField name='userNameUPS' key='userNameUPS'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='userNameUPS'
                      className={classes.fildMargin}
                    >
                      User Name {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='userNameUPS'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
              <FastField name='userPasswordUPS' key='userPasswordUPS'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='userPasswordUPS'
                      className={classes.fildMargin}
                    >
                      User Password {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='userPasswordUPS'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
              <FastField
                name='accessLicenseNumberUPS'
                key='accessLicenseNumberUPS'
              >
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='accessLicenseNumberUPS'
                      className={classes.fildMargin}
                    >
                      Access License Number
                      {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='accessLicenseNumberUPS'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
              <FastField
                name='TaxIdentificationNumber'
                key='TaxIdentificationNumber'
              >
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='TaxIdentificationNumber'
                      className={classes.fildMargin}
                    >
                      Tax Identification Number{' '}
                      {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='TaxIdentificationNumber'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
              <FastField name='accountNumber' key='accountNumber'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='accountNumber'
                      className={classes.fildMargin}
                    >
                      account Number {values?.serviceName?.toUpperCase()} *
                    </InputLabel>
                    <Input
                      id='accountNumber'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
            </When>
            <When condition={values?.serviceName?.toUpperCase() === 'EASYPOST'}>
              <FastField name='easypostProdApiKey' key='easypostProdApiKey'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='easypostProdApiKey'
                      className={classes.fildMargin}
                    >
                      {t('app.key')} *
                    </InputLabel>
                    <Input
                      id='easypostProdApiKey'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
              <FastField name='easypostTestApiKey' key='easypostTestApiKey'>
                {({ field: { value, ...field }, meta }: FieldProps) => (
                  <FormControl error={meta.touched && !!meta.error}>
                    <InputLabel
                      htmlFor='easypostTestApiKey'
                      className={classes.fildMargin}
                    >
                      {t('app.testKey')} *
                    </InputLabel>
                    <Input
                      id='easypostTestApiKey'
                      defaultValue={value}
                      disableUnderline
                      {...field}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </FastField>
            </When>

            <FastField name='isTestMode'>
              {({ field: { value } }: FieldProps) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={() => {
                        setFieldValue('isTestMode', !value);
                      }}
                      color='primary'
                    />
                  }
                  label={<span>{t('app.activateTestMode')}</span>}
                />
              )}
            </FastField>

            <BoxCentered mt={2} justifyContent='flex-start'>
              <Button
                variant='contained'
                disabled={isLoadingCreate || isLoadingUpdate}
                type='submit'
              >
                {t('app.save')}
              </Button>
              <Divider orientation='vertical' />
              <Button
                variant='contained'
                onClick={() => history.push(TRoutes.SETTINGS_SENDER_ACCOUNTS)}
              >
                {t('app.cancel')}
              </Button>
            </BoxCentered>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SenderAccountsForm;
