import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  IconButton,
  Select,
  MenuItem,
  Typography,
  Grid,
  Tooltip,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { FastField, FieldProps, Form } from 'formik';
import ExposureIcon from '@material-ui/icons/Exposure';
import useStyles from './styles';
import {
  useGetWarehouseShipmentOrderAdditionalOptionsQuery,
  useGetWarehouseShipmentOrderDeliveryOptionsListLazyQuery,
} from '../../../generated/graphql';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { IWrapTypes } from '../../../utils/types';

const SendWarehouseShipmentForm: React.FC<{
  values: any;
  setFieldValue: any;
  warehouseShipmentOrder: any;
  setOpenSendParcel: any;
  loading: any;
  isMessage: any;
  setIsMessage: any;
  isSubmitting: boolean;
  isWithLabel: boolean;
}> = ({
  values,
  setFieldValue,
  warehouseShipmentOrder,
  setOpenSendParcel,
  loading,
  isMessage,
  setIsMessage,
  isSubmitting,
  isWithLabel,
}) => {
  const classes = useStyles();
  const [deliveryOptions, setDeliveryOptions] = useState<any[]>([]);
  const [isDefaultOption, setisDefaultOption] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [
    getWarehouseDeliveryOptionsLazyQuery,
    { data: deliveryOptionsData, loading: deliveryOptionsLoading },
  ] = useGetWarehouseShipmentOrderDeliveryOptionsListLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { data, loading: GetWarehouseShipmentOrderAdditionalOptionsLoading } =
    useGetWarehouseShipmentOrderAdditionalOptionsQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  useEffect(() => {
    if (values.message) setIsMessage(true);
    else setIsMessage(false);
    //eslint-disable-next-line
  }, [values.message]);

  useEffect(() => {
    if (warehouseShipmentOrder && isWithLabel) {
      setFieldValue('shipmentId', warehouseShipmentOrder?.easyPostShipmentId);
      setFieldValue('rateId', warehouseShipmentOrder?.easyPostRateId);
      setFieldValue('typeDeliveryId', warehouseShipmentOrder?.typeDelivery?.id);
    }
    //eslint-disable-next-line
  }, [warehouseShipmentOrder]);

  useEffect(() => {
    if (
      !deliveryOptionsData?.getWarehouseShipmentOrderDeliveryOptionsList
        ?.variants
    )
      return;
    deliveryOptionsData?.getWarehouseShipmentOrderDeliveryOptionsList
      .variants &&
      setDeliveryOptions(
        deliveryOptionsData?.getWarehouseShipmentOrderDeliveryOptionsList
          .variants,
      );
  }, [deliveryOptionsData]);

  const defaultDeliveryOption = {
    name: warehouseShipmentOrder?.carrierService,
    lastMileCost: warehouseShipmentOrder?.preliminaryCost,
    orderCostCurrency: warehouseShipmentOrder?.orderCostCurrency,
    __typename: 'DeliveryOptionsResponse',
  };

  const input: any = {
    //@ts-ignore
    warehouseId: warehouseShipmentOrder?.sender?.id,
    products: warehouseShipmentOrder?.products?.map((product: any) => {
      return {
        id: +product.relatedProduct.id,
        quantity: product.quantity,
        price: product.price,
        nameEN: product.relatedProduct.detailsDescriptionEN,
        code: product.relatedProduct.code,
        weight: +product.relatedProduct.netWeight,
        countryId: product.relatedProduct.country.id,
        sku: product.relatedProduct.sku,
      };
    }),

    receiver: {
      ...warehouseShipmentOrder?.receiver,
      //@ts-ignore
      id: Number(warehouseShipmentOrder?.receiver?.id),
      //@ts-ignore
      countryId: warehouseShipmentOrder?.receiver?.country?.id,
    },
    additionalInsurance: values?.additionalInsurance,
    //@ts-ignore
    ...(values.additionalInsurance && {
      insuranceAmount: Number(values.insuranceAmount),
    }),
    signature: values?.signature,
    dimensions: values.dimensions,
    wrap: values?.wrap,
    message: isMessage ? values.message : null,
    giftWrap: values.giftWrap,
    only_calculation: false,
    address_validation: false,
    ...(warehouseShipmentOrder?.vatKey !== 'EMPTY' && {
      vatKey: warehouseShipmentOrder?.vatKey,
    }),
    ...(warehouseShipmentOrder?.vatValue && {
      vatValue: warehouseShipmentOrder?.vatValue,
    }),
    warehouseShipmentOrderId: Number(warehouseShipmentOrder?.id),
  };
  input?.products?.forEach((product: any) => {
    //@ts-ignore
    delete product?.__typename;
  });
  //@ts-ignore
  delete input.receiver.country;
  //@ts-ignore
  delete input.receiver.__typename;

  const handlerGetWarehouseDeliveryOptionsLazyQuery = () => {
    if (!input.receiver.id) return;
    delete input.receiver.id;
    getWarehouseDeliveryOptionsLazyQuery({ variables: { data: input } });
  };

  const wrapTypes = data?.getWarehouseShipmentOrderAdditionalOptions?.wrappers;

  return (
    <Form>
      {!loading || !GetWarehouseShipmentOrderAdditionalOptionsLoading ? (
        <>
          <Typography variant='h6'>
            {t('app.sendOrder')} №
            {warehouseShipmentOrder?.barcodeId?.substring(
              0,
              warehouseShipmentOrder?.barcodeId.length - 4,
            )}
          </Typography>
          {isWithLabel ? (
            <>
              <Typography className={classes.contactFieldsTitle} variant='h3'>
                {t('app.weightAndDimensions')}
              </Typography>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={4}>
                  <FastField name={'dimensions.weight'}>
                    {({ field: { value, ...field }, meta }: FieldProps) => {
                      return (
                        <FormControl
                          className={classes.inputLabelPackage}
                          error={!!(meta.touched && meta.error && value !== 0)}
                        >
                          <InputLabel
                            shrink={false}
                            htmlFor={`input-dimensions.weight`}
                          >
                            {t('app.weight')}
                          </InputLabel>
                          <Input
                            disableUnderline
                            id={`input-dimensions.weight`}
                            {...field}
                            inputProps={{
                              type: 'number',
                              step: 0.01,
                              min: 0,
                            }}
                            startAdornment={
                              <InputAdornment position='end'>кг</InputAdornment>
                            }
                            defaultValue={value}
                            onChange={(e) => {
                              setFieldValue(
                                'dimensions.weight',
                                +e.target.value,
                              );
                            }}
                            value={value}
                          />
                          {meta.touched && meta.error && value !== 0 && (
                            <FormHelperText>{meta.error}</FormHelperText>
                          )}
                        </FormControl>
                      );
                    }}
                  </FastField>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={4}>
                  <FastField name={'dimensions.length'}>
                    {({ field: { value, ...field }, meta }: FieldProps) => (
                      <FormControl
                        className={classes.inputLabelPackage}
                        error={meta.touched && !!meta.error && value !== 0}
                      >
                        <InputLabel
                          shrink={false}
                          htmlFor={`input-dimensions.length`}
                        >
                          {t('app.length')}
                        </InputLabel>
                        <Input
                          disableUnderline
                          id={`input-dimensions.length`}
                          {...field}
                          inputProps={{
                            type: 'number',
                            min: 0,
                            step: 0.01,
                          }}
                          startAdornment={
                            <InputAdornment position='end'>
                              {t('app.cm')}
                            </InputAdornment>
                          }
                          defaultValue={value}
                          onChange={(e) => {
                            setFieldValue('dimensions.length', +e.target.value);
                          }}
                          value={value}
                        />
                        {meta.touched && !!meta.error && value !== 0 && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </FastField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FastField name={'dimensions.width'}>
                    {({ field: { value, ...field }, meta }: FieldProps) => (
                      <FormControl
                        className={classes.inputLabelPackage}
                        error={meta.touched && !!meta.error && value !== 0}
                      >
                        <InputLabel
                          shrink={false}
                          htmlFor={`input-dimensions.width`}
                        >
                          {t('app.width')}
                        </InputLabel>
                        <Input
                          disableUnderline
                          id={`input-dimensions.width`}
                          {...field}
                          inputProps={{
                            type: 'number',
                            min: 0,
                            step: 0.01,
                          }}
                          startAdornment={
                            <InputAdornment position='end'>
                              {t('app.cm')}
                            </InputAdornment>
                          }
                          defaultValue={value}
                          onChange={(e) => {
                            setFieldValue('dimensions.width', +e.target.value);
                          }}
                          value={value}
                        />
                        {meta.touched && !!meta.error && value !== 0 && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </FastField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FastField name={'dimensions.height'}>
                    {({ field: { value, ...field }, meta }: FieldProps) => (
                      <FormControl
                        className={classes.inputLabelPackage}
                        error={meta.touched && !!meta.error && value !== 0}
                      >
                        <InputLabel
                          shrink={false}
                          htmlFor={`dimensions.height`}
                        >
                          {t('app.height')}
                        </InputLabel>
                        <Input
                          disableUnderline
                          id={`dimensions.height`}
                          {...field}
                          inputProps={{
                            type: 'number',
                            min: 0,
                            step: 0.01,
                          }}
                          startAdornment={
                            <InputAdornment position='end'>
                              {t('app.cm')}
                            </InputAdornment>
                          }
                          defaultValue={value}
                          onChange={(e) => {
                            setFieldValue('dimensions.height', +e.target.value);
                          }}
                          value={value}
                        />
                        {meta.touched && !!meta.error && value !== 0 && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </FastField>
                </Grid>
              </Grid>
              <Typography className={classes.contactFieldsTitle} variant='h3'>
                {t('app.additionalServices')}
              </Typography>
              <FastField name='additionalInsurance'>
                {() => (
                  <FormControlLabel
                    className={classes.labelRow}
                    control={
                      <Checkbox
                        defaultChecked={values?.additionalInsurance as boolean}
                        className={classes.radio}
                        onChange={(e) =>
                          setFieldValue('additionalInsurance', e.target.checked)
                        }
                        checked={!!values?.additionalInsurance}
                        color='primary'
                      />
                    }
                    label={
                      <>
                        <span className={classes.labelRowText}>
                          {t('app.additionalInsurance')}
                        </span>
                        <Tooltip
                          className={classes.tooltipIconButton}
                          placement='top'
                          title={t('app.serviceIsChargeable')}
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />
                )}
              </FastField>
              {!!values.additionalInsurance && (
                <Box maxWidth={280}>
                  <FastField name='insuranceAmount'>
                    {({ field: { value }, meta }: FieldProps) => {
                      return (
                        <FormControl error={!!(meta.touched && meta.error)}>
                          <InputLabel
                            shrink={false}
                            htmlFor='insuranceAmount'
                            style={{ marginTop: '24px' }}
                          >
                            {t('app.specifyTheCost')}
                          </InputLabel>
                          <Input
                            disableUnderline
                            id='insuranceAmount'
                            defaultValue={value}
                            startAdornment={
                              <InputAdornment
                                style={{ paddingLeft: '10px' }}
                                position='start'
                              >
                                $
                              </InputAdornment>
                            }
                            inputProps={{
                              type: 'number',
                              min: 0,
                            }}
                            onBlur={(e) =>
                              setFieldValue('insuranceAmount', +e.target.value)
                            }
                          />
                          {meta.touched && meta.error && (
                            <FormHelperText>{meta.error}</FormHelperText>
                          )}
                        </FormControl>
                      );
                    }}
                  </FastField>
                </Box>
              )}
              <FastField name='signature'>
                {() => (
                  <FormControlLabel
                    className={classes.labelRow}
                    style={{ marginTop: '20px' }}
                    control={
                      <Checkbox
                        defaultChecked={values?.signature as boolean}
                        className={classes.radio}
                        checked={!!values?.signature}
                        color='primary'
                        onChange={(e) =>
                          setFieldValue('signature', e.target.checked)
                        }
                      />
                    }
                    label={
                      <>
                        <span className={classes.labelRowText}>
                          {t('app.recipientsSignature')}
                        </span>
                        <Tooltip
                          className={classes.tooltipIconButton}
                          placement='top'
                          title={t('app.serviceIsChargeable')}
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />
                )}
              </FastField>
            </>
          ) : null}
          <FormControlLabel
            className={classes.labelRow}
            style={{ marginTop: '20px' }}
            control={
              <Checkbox
                defaultChecked={isMessage}
                className={classes.radio}
                checked={isMessage}
                color='primary'
                onChange={(e) => {
                  setIsMessage(e.target.checked);
                }}
              />
            }
            label={
              <>
                <span className={classes.labelRowText}>
                  {t('app.giftMessage')}
                </span>
                <Tooltip
                  className={classes.tooltipIconButton}
                  placement='top'
                  title={t('app.serviceIsChargeable')}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
          />
          <FastField name='giftWrap'>
            {() => (
              <FormControlLabel
                className={classes.labelRow}
                style={{ marginTop: '20px' }}
                control={
                  <Checkbox
                    defaultChecked={values?.giftWrap as boolean}
                    className={classes.radio}
                    checked={!!values?.giftWrap}
                    color='primary'
                    onChange={(e) =>
                      setFieldValue('giftWrap', e.target.checked)
                    }
                  />
                }
                label={
                  <>
                    <span className={classes.labelRowText}>
                      {t('app.giftPackaging')}
                    </span>
                    <Tooltip
                      className={classes.tooltipIconButton}
                      placement='top'
                      title={t('app.serviceIsChargeable')}
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            )}
          </FastField>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.packaging')}
          </Typography>
          {values?.wrap && (
            <Select
              displayEmpty
              disableUnderline
              value={values?.wrap}
              onChange={(e) => {
                setFieldValue('wrap', e.target.value);
              }}
            >
              {wrapTypes?.map((wrap: IWrapTypes) => {
                return (
                  <MenuItem
                    value={wrap.name}
                  >{`${wrap.name} - ${wrap.cost} $`}</MenuItem>
                );
              })}
            </Select>
          )}
          {isWithLabel ? (
            <>
              <Typography className={classes.contactFieldsTitle} variant='h3'>
                {t('app.deliveryOption')}
              </Typography>
              <Typography style={{ marginBottom: '17px' }}>{`${t(
                'app.userSelected',
              )}: ${defaultDeliveryOption.name} - ${
                defaultDeliveryOption.lastMileCost
              } ${defaultDeliveryOption.orderCostCurrency}`}</Typography>
              <Box display={'flex'} flexDirection={'row'}>
                <Select
                  displayEmpty
                  disableUnderline
                  defaultValue={-1}
                  onChange={(e: any) => {
                    setFieldValue(
                      'carrierService',
                      deliveryOptions[e.target.value]?.name,
                    );
                    setFieldValue(
                      'shipmentId',
                      deliveryOptions[e.target.value]?.shipmentId,
                    );
                    setFieldValue(
                      'rateId',
                      deliveryOptions[e.target.value]?.rateId,
                    );
                    setFieldValue(
                      'typeDeliveryId',
                      deliveryOptions[e.target.value]?.typeDelivery,
                    );
                  }}
                >
                  {isDefaultOption ? (
                    <MenuItem
                      value={-1}
                    >{`${defaultDeliveryOption.name} - ${defaultDeliveryOption.lastMileCost} ${defaultDeliveryOption.orderCostCurrency}`}</MenuItem>
                  ) : (
                    <MenuItem style={{ display: 'none' }} value={-1}>
                      {t('app.selectShipmentOption')}
                    </MenuItem>
                  )}
                  {deliveryOptions.map((option, index) => (
                    <MenuItem
                      value={index}
                    >{`${option.name} - ${option.summary_amount} ${option.priceCurrency}`}</MenuItem>
                  ))}
                </Select>
                <Button
                  style={{ marginLeft: '20px' }}
                  variant='contained'
                  onClick={() => {
                    handlerGetWarehouseDeliveryOptionsLazyQuery();
                    setisDefaultOption(false);
                  }}
                >
                  {deliveryOptionsLoading ? (
                    <CircularProgress size={22} />
                  ) : (
                    <ExposureIcon />
                  )}{' '}
                  {t('app.recalculateCost')}
                </Button>
              </Box>
            </>
          ) : null}

          <div className={classes.boxModalButtons}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setOpenSendParcel(false)}
            >
              {t('app.cancel')}
            </Button>
            <Button
              variant='contained'
              type='submit'
              disabled={isSubmitting || deliveryOptionsLoading}
            >
              {t('app.send')}
            </Button>
          </div>
        </>
      ) : (
        <ShowLoadingText name={t('app.shipment')} />
      )}
    </Form>
  );
};

export default SendWarehouseShipmentForm;
