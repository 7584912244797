import { makeStyles, createStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../utils/constants';

export const styles = () =>
  createStyles({
    inputLabel: {
      marginTop: '30px',
    },
    boxModalButtons: {
      margin: '30px 0 0',
      display: 'flex',
      justifyContent: 'space-around',
      gap: '24px',
    },
    packageIconsWrapper: {
      display: 'flex',
      gap: '24px',
      marginBottom: 24,
    },
    packageIconWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      border: `1px solid ${COLORS.BLACK}`,
      padding: '10px 20px 10px 20px',
    },
    packageIcon: { width: '58px', height: '58px' },
    selectContacts: {
      maxWidth: 204,
      width: '100%',

      '& .MuiSelect-select': {
        height: '41px',
        paddingRight: 45,
        boxSizing: 'border-box',
      },

      '& .MuiSelect-icon': {
        right: 14,
      },
    },
    checkBox: { paddingLeft: 0 },
    radio: {
      padding: 0,
      '&.Mui-checked': {
        color: COLORS.BLACK,
      },
    },
    table: {
      '& .MuiTableCell-head': {
        fontSize: '16px',
        padding: '24px 4px',
        lineHeight: '24px',
        marginBottom: 12,
        fontWeight: 500,
        color: '#000000',
        border: 'none',
        background: COLORS.WHITE,
      },
      '& .MuiTableCell-body': {
        fontSize: '16px',
        padding: '12px 4px',
        lineHeight: '24px',
        marginBottom: 12,
        fontWeight: 500,
        color: '#000000',
        border: 'none',
        background: COLORS.WHITE,
      },
    },
    inputsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      '& .MuiInput-input': {
        width: '66px',
      },
      '& > :last-child': {
        display: 'none',
      },
    },
    errorsMessage: {
      color: '#f44336',
    },
  });

export default makeStyles(styles);
